import React, { useEffect, useRef } from "react";
import style from "../style.module.scss";
import { Carousel } from "antd";
import { GrNext, GrPrevious } from "react-icons/gr";

const CarouselContainer = ({ setIsVisible, images, index, setIndex }) => {
  const slider = useRef();

  useEffect(() => {
    slider.current.goTo(index);
  }, [index]);

  const handleScroll = (e) => {
    if (e.nativeEvent.wheelDelta < 0) {
      slider.current.goTo(index + 1);
    } else {
      slider.current.goTo(index - 1);
    }
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.keyCode === 39 || e.which === 39) {
        slider.current.next();
      }
      if (e.keyCode === 37 || e.which === 37) {
        slider.current.prev();
      }
    };
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <div
      className={style.carouselContainer}
      onClick={() => setIsVisible(true)}
      onWheel={handleScroll}>
      <Carousel
        adaptiveHeight={true}
        dots={false}
        draggable={true}
        infinite={false}
        slidesToShow={3}
        centerMode={true}
        speed={400}
        accessibility={true}
        afterChange={(current) => setIndex(current)}
        ref={(ref) => {
          slider.current = ref;
        }}>
        {images.map((image, i) => (
          <div key={i}>
            <div
              onClick={() => slider.current.goTo(i)}
              className={style.carouselItem}
              style={{
                backgroundImage: `url(${image.src})`,
              }}></div>
          </div>
        ))}
        <div></div>
        <div></div>
      </Carousel>
      <div className={style.iconsWrapper}>
        <GrPrevious
          onClick={() => {
            slider.current.goTo(index - 1);
          }}
          size={50}
        />
        <h1>
          {index + 1}/{images.length}
        </h1>
        <GrNext
          onClick={() => {
            slider.current.goTo(index + 1);
          }}
          size={50}
        />
      </div>
    </div>
  );
};

export default CarouselContainer;
