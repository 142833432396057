import React from "react";
import Icon from "@ant-design/icons";

const TrashSvg = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.1474 3.7218H21.7856C21.7406 3.7068 21.6939 3.697 21.6465 3.69258H18.067V0.82382C18.067 0.608333 17.9797 0.401404 17.8237 0.247402C17.6677 0.0933997 17.4555 0.00458483 17.2326 0H8.76744C8.54133 0 8.32448 0.0867951 8.16459 0.241291C8.00471 0.395788 7.91488 0.60533 7.91488 0.82382V3.69258H4.35349C4.3061 3.697 4.25942 3.7068 4.21442 3.7218H0.852558C0.626446 3.7218 0.409594 3.80859 0.249708 3.96309C0.0898228 4.11759 0 4.32713 0 4.54562C0 4.76411 0.0898228 4.97365 0.249708 5.12815C0.409594 5.28264 0.626446 5.36944 0.852558 5.36944H3.59163L5.80465 25.2638C5.82841 25.4663 5.92844 25.6532 6.08567 25.789C6.24291 25.9247 6.44634 25.9999 6.65721 26H19.3549C19.5637 25.997 19.7642 25.9206 19.9188 25.7851C20.0735 25.6496 20.1718 25.4643 20.1953 25.2638L22.4084 5.39865H25.1474C25.3736 5.39865 25.5904 5.31186 25.7503 5.15736C25.9102 5.00286 26 4.79332 26 4.57483C26 4.35634 25.9102 4.1468 25.7503 3.9923C25.5904 3.83781 25.3736 3.75101 25.1474 3.75101V3.7218ZM9.63814 1.64764H16.3619V3.69258H9.63814V1.64764ZM18.5809 24.3407H7.41907L5.30279 5.39865H20.6972L18.5809 24.3407Z"
      fill="#156DC6"
    />
    <path
      d="M12.999 22.4126C13.2252 22.4126 13.442 22.3258 13.6019 22.1713C13.7618 22.0168 13.8516 21.8072 13.8516 21.5887V8.15054C13.8516 7.93205 13.7618 7.72251 13.6019 7.56801C13.442 7.41352 13.2252 7.32672 12.999 7.32672C12.7729 7.32672 12.5561 7.41352 12.3962 7.56801C12.2363 7.72251 12.1465 7.93205 12.1465 8.15054V21.5887C12.1465 21.8072 12.2363 22.0168 12.3962 22.1713C12.5561 22.3258 12.7729 22.4126 12.999 22.4126Z"
      fill="#156DC6"
    />
    <path
      d="M9.64442 22.4067H9.6807C9.90579 22.3976 10.118 22.3028 10.271 22.1429C10.4239 21.9831 10.5052 21.7713 10.497 21.5537L9.98302 8.1155C9.98081 8.00653 9.95576 7.89912 9.90938 7.79976C9.863 7.70041 9.79625 7.61117 9.71317 7.53745C9.63009 7.46372 9.5324 7.40704 9.42602 7.37082C9.31963 7.33461 9.20674 7.31961 9.09419 7.32674C8.86909 7.33586 8.65688 7.4307 8.50393 7.59054C8.35098 7.75038 8.26972 7.96222 8.27791 8.17977L8.79186 21.618C8.80268 21.8298 8.89707 22.0297 9.05564 22.1764C9.2142 22.3231 9.4249 22.4055 9.64442 22.4067Z"
      fill="#156DC6"
    />
    <path
      d="M16.3254 22.4067H16.3556C16.5803 22.4115 16.7979 22.331 16.9619 22.1824C17.1258 22.0338 17.2229 21.8291 17.2323 21.6121L17.7463 8.17391C17.7545 7.95636 17.6732 7.74451 17.5203 7.58468C17.3673 7.42484 17.1551 7.33 16.93 7.32088C16.8161 7.30948 16.7011 7.32119 16.5921 7.35525C16.4832 7.38931 16.3829 7.44497 16.2975 7.51867C16.2121 7.59237 16.1436 7.68248 16.0963 7.78324C16.0491 7.884 16.0241 7.99318 16.023 8.1038L15.503 21.542C15.4973 21.6512 15.5142 21.7603 15.5526 21.8631C15.591 21.9659 15.6502 22.0603 15.7267 22.1408C15.8033 22.2213 15.8956 22.2862 15.9984 22.3319C16.1012 22.3775 16.2123 22.403 16.3254 22.4067Z"
      fill="#156DC6"
    />
  </svg>
);

const TrashIcon = (props) => <Icon component={TrashSvg} {...props} />;

export default TrashIcon;
