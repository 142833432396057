import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import BodyTypesWrapper from './BodyTypesWrapper';

const BodyTypesContainer = ({ control, setValue, errors }) => {
  const history = useHistory(); //redirect to cars page
  let path = history.location.pathname.slice(1, 5);
  const bodyTypes = useSelector((state) => (path !== 'sell' ? state.search[0] : state.sell[0]), shallowEqual);
  useEffect(() => {
    setValue('bodyType', bodyTypes.defaultValue);
  }, [setValue, bodyTypes.defaultValue]);

  return (
    <Controller
      as={<BodyTypesWrapper path={path} errors={errors} />}
      name='bodyType'
      control={control}
      defaultValue={bodyTypes.defaultValue}
      rules={{
        validate: (value) => {
          ////console.log(path, value);
          if (value === 0 && path === 'sell') {
            return false;
          }
          return true;
        },
      }}
    />
  );
};

export default BodyTypesContainer;
