import React from "react";
import Icon from "@ant-design/icons";

const EditSvg = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.8177 18.4737H22.9517V24.134H1.86599V3.04782H7.52618V1.18179H0V26H24.8177V18.4737Z"
      fill="#156DC6"
    />
    <path
      d="M8.77029 12.4402L6.9043 19.0957L13.5597 17.2297L25.9996 4.82058L21.1792 0L8.77029 12.4402ZM12.5956 15.5813L9.57889 16.4211L10.4186 13.4043L17.9137 5.90909L20.0907 8.08613L12.5956 15.5813ZM21.3969 6.77991L19.2199 4.60287L21.1792 2.64354L23.3561 4.82058L21.3969 6.77991Z"
      fill="#156DC6"
    />
  </svg>
);

const EditIcon = (props) => <Icon component={EditSvg} {...props} />;

export default EditIcon;
