import React from "react";
import styles from "./modal.module.scss";
import { IoMdClose } from "react-icons/io";

const Modal = ({ show, onClose, children }) => {
  if (!show) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <button className={styles.modalClose} onClick={onClose}>
          <IoMdClose size={20} />
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
