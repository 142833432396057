import { SET_PAGE, SET_QUERY } from "../Actions/paginationAction";

const urlParams = new URLSearchParams(window.location.search);
const page = urlParams.get('currentPage');

const initialState = {
    currentPage: page || 1,
  };

export const paginationReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_PAGE:
        return {
          ...state,
          currentPage: action.payload
        };
        case SET_QUERY:
        return {
          ...state,
          query: action.payload
        };
      default:
        return state;
    }
  };