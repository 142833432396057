import React from 'react';
import style from './style.module.scss';

const Loader = ({ isVisible }) => {
  return (
    <div className={isVisible ? style.loaderWrapper : style.hidden}>
      <div className={style.lds_ripple}>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Loader;
