import { Route, Switch } from 'react-router-dom';
import 'antd/dist/antd.min.css';
import './App.scss';
import Home from './Pages/Home/Home';
import Footer from './components/Footer/Footer';
import CarsPage from './Pages/CarsPage/CarsPage';
import SingleCar from './Pages/SingleCar/SingleCar';
import SellPage from './Pages/SellPage/SellPage';
import ProfilePage from './Pages/ProfilePage/ProfilePage';
import ComparePage from './Pages/ComparePage/ComparePage';
import Partners from './Pages/Partners/Partners';
import Login from './Pages/LoginSignup/Login';
import PrivateRoute from './components/privateRoute/privateRoute';
import Maintenance from './Pages/Maintenance/Maintenance';
import React, { useState } from 'react';
import NavbarWrapper from './components/Navbar/NavbarWrapper';
import { ConnectedRouter } from 'connected-react-router';
import { history } from './redux/store';
import UserAnnouncements from './Pages/UserAnnouncements/UserAnnouncements';
import Terms from './Pages/Terms/Terms';
import Activate from './components/Activate/Activate';
import { shallowEqual, useSelector } from 'react-redux';
import DealerCar from './Pages/DealerCar/DealerCar';
import Calculator from './Pages/Calculator/Calculator';
import LiveAuction from './Pages/LiveAuction/LiveAuction';
import Privacy from "./Pages/Privacy/Privacy";
import AboutUs from "./Pages/AboutUs/AboutUs";
import AcceptCookies from "./Pages/AcceptCookies";

const App = () => {
  const [maintenance] = useState(false);
  const user = useSelector((state) => (state.user.info ? state.user.info : ''), shallowEqual);

  if (!maintenance)
    return (
      <div className='App'>
         <AcceptCookies/>
        <ConnectedRouter history={history}>
          <NavbarWrapper />
          <Switch>
            <Route exact path='/'>
              <Home />
            </Route>
            <Route path='/cars'>
              <CarsPage />
            </Route>
            <Route path='/car/:id' render={(props) => <SingleCar {...props} />} />
            <Route path='/dealer/car/:id' render={(props) => <DealerCar {...props} />} />

            <Route path='/sell/:id' render={(props) => <SellPage {...props} />} />
            <PrivateRoute exact path='/profile'>
              <ProfilePage />
            </PrivateRoute>
            <PrivateRoute exact path='/live_auction'>
              <LiveAuction />
            </PrivateRoute>
            <Route exact path='/compare'>
              <ComparePage />
            </Route>
            <Route exact path='/partners'>
              <Partners />
            </Route>
            <Route exact path='/calculator'>
              <Calculator />
            </Route>
            <Route exact path={['/login', '/signup']}>
              <Login />
            </Route>
            <Route path='/reset/password/login/:otl' render={(props) => <Login {...props} />} />
            <Route path='/announcements/:id' render={(props) => <UserAnnouncements {...props} />} />
            <Route exact path='/terms'>
              <Terms />
            </Route>
            <Route exact path='/privacy'>
              <Privacy />
            </Route>
            <Route exact path='/about-us'>
              <AboutUs />
            </Route>
            <Route path='/activate/email/:code' render={(props) => <Activate {...props} />} />
          </Switch>
          <Footer />
        </ConnectedRouter>
        {user.auctionToken && <input type='hidden' id='auctionToken' value={user.auctionToken} />}
      </div>
    );
  return <Maintenance />;
};

export default App;
