import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router';
// import LoginSignup from '../Common/Modals/LoginSignup/LoginSignup';

const PrivateRoute = ({ children, ...rest }) => {
  const user = useSelector((state) => state.user);
  // const [modalState, setModalState] = useState({ isVisible: true, initialForm: 'signin' }); //state for login/signup modal

  return (
    <Route
      {...rest}
      render={() => {
        return user.info ? children : <Redirect to='/' />;
      }}
    />
  );
};

export default PrivateRoute;
