import React from "react";
import classnames from 'classnames';

import style from "./style.module.scss";

const ButtonSmall = ({ innerText, handleClick, className }) => {
  var liClasses = classnames({
    [style.btnSmall]: true,
    [className]: !!className
  });

  return (
    <button className={liClasses} onClick={handleClick}>
      {innerText}
    </button>
  );
};

export default ButtonSmall;
