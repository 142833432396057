import { getCarDetails, getRelatedCars } from '../../api/requests';

const initialState = {
  bodyType: 0,
  carBodyType: {},
  carExtColor: null,
  carGearBox: {},
  carIntColor: null,
  carMake: {},
  carMedia: [],
  carModel: {},
  carTrim: {},
  cleanCar: 0,
  country: 0,
  currency: 0,
  customText: '',
  engine: 0,
  exchange: 0,
  extColor: 0,
  gearBox: 0,
  id: 0,
  intColor: 0,
  make: 0,
  mileage: 0,
  mileageType: 0,
  model: 0,
  partSelling: 0,
  price: 0,
  shopStatus: 1,
  steeringWheel: 1,
  trim: 0,
  userId: 0,
  year: 0,
  owner: { id: 0, userDetails: {} },
  relatedCars: [],
};

const singleCarReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CAR_DETAILS': {
      return { ...state, ...action.car };
    }
    case 'SET_RELATED': {
      return { ...state, relatedCars: [...(action?.related || [])] };
    }
    case 'SET_SHOP_STATUS_0': {
      return { ...state, shopStatus: 0 };
    }
    default:
      return state;
  }
};

//action
export const setCarDetails = (carId, setIsVisible) => {
  return (dispatch) => {
    setIsVisible(true);
    getCarDetails(carId).then((res) => {
      ////console.log(res);
      if (res.status === 200) {
        const car = res.data.data;
        const carId = car.id;
        const makeId = car.carMake.id;
        const modelId = car.carModel.id;
        setIsVisible(false);
        ////console.log(car, makeId, modelId);
        dispatch({ type: 'SET_CAR_DETAILS', car });
        getRelatedCars(carId, makeId, modelId).then((res) => {
          ////console.log(res);
          if (res.status === 200) {
            const related = res.data.cars;
            dispatch({ type: 'SET_RELATED', related });
          }
        });
      } else {
        dispatch({ type: 'SET_SHOP_STATUS_0' });
      }
      setIsVisible(false);
    });
  };
};
export default singleCarReducer;
