import { Input } from 'antd';
import React from 'react';
import FloatLabel from '../../FloatLabel/FloatLabel';

const InputWrapper = React.forwardRef(({ type, required, label, name, onChange, value }, ref) => {
  const handleChange = (e) => {
    onChange(e.target.value);
  };
  return (
    <FloatLabel label={label} name={name} value={value}>
      <Input ref={ref} name={name} type={type} required={required ? true : false} onChange={handleChange} />
    </FloatLabel>
  );
});

export default InputWrapper;
