import React, { useState } from 'react';
import Button from '../../Buttons/Button';
import { Controller } from 'react-hook-form';
import { useHistory } from 'react-router';
// import SocialButton from "../../Buttons/SocialButton";
import style from './style.module.scss';
import { registerUser } from '../../../../api/requests';
import InputWrapper from './InputWrapper';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const SignupForm = ({ handleSubmit, setModalState, modalState, control, alertState, setAlertState }) => {
  const [isChecked, setIsChecked] = useState(false); //state for checkbox
  const history = useHistory();
  const path = history.location.pathname.substring(1);
  const lang = useSelector((state) => state.user.lang[0], shallowEqual);

  const { t } = useTranslation();

  const onSubmit = (data) => {
    //make api call here then dispatch result
    if (data.password === data.passwordRpt) {
      const { passwordRpt, ...formData } = data;
      const fd = { ...formData, lang: lang.value };
      ////console.log(fd);

      if (isChecked) {
        registerUser(fd).then((res) => {
          ////console.log(res);
          if (res && res.status === 200 && res.data) {
            setModalState !== undefined && setModalState({ ...modalState, initialForm: 'signin', newUser: true });
            path === 'signup' && history.push('/');
          } else if (res && (res.status === 409 || res.status === 400)) {
            setAlertState({ visible: true, type: 'error', description: t(`${res.data.message}`) });
          }else {
            setAlertState({ visible: true, type: 'error', description: 'unknown' });
          }
        });
      } else {
        setAlertState({ visible: true, description: `${t('agree_terms_and_privacy')}`, status: 1 });
      }
    } else {
      setAlertState({ visible: true, description: `${t('pass_dont_match')}` });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete='off' className={style.signUpForm}>
      <Controller
        as={<InputWrapper type={'text'} required={true} label={t('placeholder_name')} name={'name'} />}
        name='name'
        defaultValue=''
        control={control}
      />
      <Controller
        as={<InputWrapper type={'email'} required={true} label={t('placeholder_email')} name={'email'} />}
        name='email'
        defaultValue=''
        control={control}
      />
      <Controller
        as={<InputWrapper type={'password'} required={true} label={t('placeholder_pass')} name='password' autocomplete="current-password"/>}
        name='password'
        defaultValue=''
        control={control}
      />
      <Controller
        as={<InputWrapper type={'password'} required={true} label={t('placeholder_pass_repeat')} name='passwordRpt' autocomplete="current-password" />}
        name='passwordRpt'
        defaultValue=''
        control={control}
      />
      <span className={style.span} style={{ borderBottom: alertState.status === 1 && !isChecked && '1px solid red' }}>
        <input
          type='checkbox'
          defaultChecked={isChecked}
          onChange={() => {
            setIsChecked(!isChecked);
            alertState.status === 1 && setAlertState({ visible: false, description: '', status: 0 });
          }}
        />
        <Link
          to={{ pathname: '/terms' }}
          target='_blank'
          onClick={() => localStorage.setItem('lang', JSON.stringify(lang))}
        >
          {t('terms_and_privacy')}
        </Link>
      </span>
      <Button innerText={t('signup')} type='submit' />
      {/* <p className={style.p}>OR</p>
        <div>
          <SocialButton belongsTo="signup" innerText="Google" />
          <SocialButton belongsTo="signup" innerText="Facebook" />
        </div> */}
    </form>
  );
};

export default SignupForm;
