import { Input } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const PhoneWrapper = React.forwardRef(({ value, onChange }, ref) => {
  const { t } = useTranslation();

  const handlePhoneChange = (e) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    let regex = /[^0-9.,]+/;
    if (regex.test(keyValue)) {
      e.preventDefault();
    }
  };

  return (
    <Input
      ref={ref}
      required
      type='text'
      bordered={false}
      defaultValue={value}
      placeholder={`${t('mobile_btn')}`}
      maxLength={9}
      minLength={8}
      onKeyPress={handlePhoneChange}
      onChange={onChange}
    />
  );
});

export default PhoneWrapper;
