import { Button} from "antd";
import React from "react";
import style from "./style.module.scss";
import FbIcon from "../Common/Icons/fb";
import InstaIcon from "../Common/Icons/insta";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const Footer = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  let path = location.pathname.substring(1);
  const navigateToTerms = () => {
    history.push(`/terms`);
  };
  const navigateToPrivacy = () => {
    history.push(`/privacy`);
  };

  const navigateToAboutUs = () => {
    history.push(`/about-us`);
  };

  const navigateToPartners = () => {
    history.push(`/partners`);
  };

  if (
    path !== "profile" &&
    path !== "partners" &&
    path !== "login" &&
    path !== "signup" &&
    path !== "live_auction"
  )
    return (
      <footer className={style.footerContainer}>
        <div className={style.innerContainer}>
          <div className={style.footerCategories}>
            <Button type={"link"} key={"about_us"} onClick={navigateToAboutUs}>
              {t("about_us")}
            </Button>
            <Button type={"link"} key={"faq"}>
              {t("faq")}
            </Button>
            <Button
              type={"link"}
              key={"how_become_partner"}
              onClick={navigateToPartners}>
              {t("how_become_partner")}
            </Button>
            <Button
              type={"link"}
              key={"terms_conditions"}
              onClick={navigateToTerms}>
              {t("terms_conditions")}
            </Button>

            <Button
              type={"link"}
              key={"privacy_policy"}
              onClick={navigateToPrivacy}>
              {t("privacy_policy")}
            </Button>
          </div>
          <div className={style.contacts}>
            <div>
              <div className={style.phoneWrapper}>
                <p>
                  +374 60 670000 <span>+374 77 006045</span>
                </p>
              </div>
              <div className={style.address}>
                <p> {t("address")}</p>
              </div>
              <div className={style.email}>
                <p>sales@autobuy.am</p>
              </div>
            </div>
          </div>
          <div className={style.iconsWrapper}>
            <div className={style.iconsContainer}>
              <h1>Find us on:</h1>
              <a
                href="https://www.facebook.com/autobuy.am/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Facebook">
                <FbIcon style={{ fontSize: "34px", margin: "0 10px" }} />
              </a>
              <a
                href="https://www.instagram.com/autobuy.am/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Instagram">
                <InstaIcon style={{ fontSize: "34px", margin: "0 10px" }} />
              </a>
            </div>
          </div>
        </div>
      </footer>
    );
  else {
    return <footer style={{ width: "1440px" }}></footer>;
  }
};

export default Footer;
