import React from "react";
import { MdNavigateNext } from "react-icons/md";

const NextBtn = (props) => {
  const { className, onClick, slideCount, currentSlide } = props;

  return (
    <>
      {currentSlide !== slideCount - props.slidesToShow && (
        <div className={className} onClick={onClick}>
          <MdNavigateNext size={20} />
        </div>
      )}
    </>
  );
};

export default NextBtn;
