import { Carousel } from 'antd';
import React, { useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import SliderNextIcon from '../../../../components/Common/Icons/sliderNext';
import SliderNextIconMobile from '../../../../components/Common/Icons/sliderNextMobile';
import SliderPrevIcon from '../../../../components/Common/Icons/sliderPrev';
import SliderPrevIconMobile from '../../../../components/Common/Icons/sliderPrevMobile';
import SliderModal from '../../../../components/Common/Modals/SliderModal/SliderModal';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import style from '../style.module.scss';
const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:4000';

const CarouselContainer = () => {
  const { width } = useWindowDimensions();
  const [isVisible, setIsVisible] = useState(false);
  const [startingNumber, setStartingNumber] = useState(0); //to determine starting position in modal slider
  const slider = useRef();
  const carDetails = useSelector((state) => state.dealerCars.dealerCarDetails, shallowEqual);

  const images = carDetails.carMedia.map((media) => {
    return { src: `${BASE_URL}${media.carImage.location}/${media.carImage.name}medium.jpeg` };
  });
  const largeImages = carDetails.carMedia.map((media) => {
    return { src: `${BASE_URL}${media.carImage.location}/${media.carImage.name}large.jpeg` };
  });

  const handleClick = (e) => {
    setStartingNumber(e.target.tabIndex);
    width > 768 && setIsVisible(true);
  };

  return (
    <div className={style.carouselContainer}>
      <div className={style.bigPic}>
        <Carousel
          adaptiveHeight={true}
          dots={false}
          infinite={false}
          ref={(ref) => {
            slider.current = ref;
          }}
        >
          {images.map((image, i) => (
            <div
              key={i}
              onClick={() => {
                setStartingNumber(i);
                width > 768 && setIsVisible(true);
              }}
            >
              <div
                className={style.carouselItem}
                style={{
                  backgroundImage: `url(${image.src})`,
                }}
              ></div>
            </div>
          ))}
        </Carousel>
      </div>
      {width > 768 ? (
        <SliderPrevIcon onClick={() => slider.current.prev()} className={style.icons} />
      ) : (
        <SliderPrevIconMobile onClick={() => slider.current.prev()} className={style.icons} />
      )}
      {width > 768 ? (
        <SliderNextIcon onClick={() => slider.current.next()} className={style.icons} />
      ) : (
        <SliderNextIconMobile onClick={() => slider.current.next()} className={style.icons} />
      )}
      <div className={style.smallPicsWrapper}>
        {images.map((image, i) => (
          <div
            tabIndex={i}
            onClick={handleClick}
            key={i}
            style={{
              backgroundImage: `url(${image.src})`,
            }}
          >
            {images.length > 3 && i === 2 ? (
              <div className={style.third}>
                <h1>+{images.length - 3}</h1>
              </div>
            ) : null}
          </div>
        ))}
      </div>
      <SliderModal
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        images={largeImages}
        startingNumberProp={startingNumber}
        setStartingNumber={setStartingNumber}
      />
    </div>
  );
};

export default CarouselContainer;
