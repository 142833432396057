import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import searchReducer from './Reducers/searchReducer';
import sellReducer from './Reducers/sellReducer';
import carsReducer from './Reducers/carsReducer';
import userReducer from './Reducers/userReducer';
import { paginationReducer } from './Reducers/paginationReducer';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import singleCarReducer from './Reducers/singleCarReducer';
import dealerCarsReducer from './Reducers/dealerCarsReducer';
export const history = createBrowserHistory();

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    user: userReducer,
    search: searchReducer,
    sell: sellReducer,
    cars: carsReducer,
    carDetails: singleCarReducer,
    dealerCars: dealerCarsReducer,
    pagination: paginationReducer
  });

//sync state with session storage
const loadState = () => {
  try {
    const localState = localStorage.getItem('state');
    if (!localState) {
      return undefined;
    } else {
      return JSON.parse(localState);
    }
  } catch (err) {
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const localState = JSON.stringify(state);
    localStorage.setItem('state', localState);
  } catch (err) {
    ////console.log(err);
  }
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducers(history),
  loadState(),
  composeEnhancers(applyMiddleware(routerMiddleware(history), thunk))
);

store.subscribe(() => {
  let state = store.getState();
  let stateToSave = { user: state.user };
  saveState(stateToSave);
});

export default store;
