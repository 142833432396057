export const CarsProductsCardList = [
  {
    name: "2013 Mercedes-Benz E-Class",
    imageUrl:
      "https://autobuy.am/api/store/cars/2T2HZMDA9MC300534//1716807935117_0_medium.webp",
    originalPrice: 24000.0,
  },
  {
    name: "2013 Mercedes-Benz E-Class",
    imageUrl:
      "https://autobuy.am/api/store/cars/2T2HZMDA9MC300534//1716807935117_0_medium.webp",
    originalPrice: 27000.0,
  },
  {
    name: "2013 Mercedes-Benz E-Class",
    imageUrl:
      "https://autobuy.am/api/store/cars/2T2HZMDA9MC300534//1716807935117_0_medium.webp",
    originalPrice: 89900.0,
  },
  {
    name: "2013 Mercedes-Benz E-Class",
    imageUrl:
      "https://autobuy.am/api/store/cars/2T2HZMDA9MC300534//1716807935117_0_medium.webp",
    originalPrice: 35500.0,
  },
];
export const servicesData = [
  {
    id: 1,
    title: "service3",
    price: "callForPrice",
  },
  {
    id: 2,
    title: "service4",
    price: "callForPrice",
  },
  {
    id: 3,
    title: "service5",
    price: "callForPrice",
  },
  {
    id: 4,
    title: "service2",
    price: "callForPrice",
  },
  {
    id: 5,
    title: "service1",
    price: "callForPrice",
  },
];
export const servicesLogistic = [
  {
    id: 1,
    title: "auction",
  },
  {
    id: 2,
    title: "localTransportation",
  },
  {
    id: 3,
    title: "internationalTransportation",
  },
];
export const offers = [
  {
    image:
      "https://autobuy.am/api/store/cars/2T2HZMDA9MC300534//1716807935117_0_medium.webp",
    year: 2013,
    model: "Mercedes-Benz E-Class",
    price: "51.000",
    hot: true,
  },
  {
    image:
      "https://i.pinimg.com/564x/d2/6d/2a/d26d2aab2ff63cb48b3a91b0e8e0aa55.jpg",
    year: 2013,
    model: "Mercedes-Benz E-Class",
    price: "51.000",
    hot: true,
  },
  {
    image:
      "https://i.pinimg.com/564x/d2/6d/2a/d26d2aab2ff63cb48b3a91b0e8e0aa55.jpg",
    year: 2013,
    model: "Mercedes-Benz E-Class",
    price: "51.000",
    hot: true,
  },
];
export const mockCars = [
  {
    year: "2013",
    carBodyType: { name: "Mercedes-Benz E-Class" },
    price: "51.000$",
    image:
      "https://i.pinimg.com/564x/58/e7/4b/58e74b488fa00a4c8789d3adf9beeb1a.jpg",
    hot: false,
  },
  {
    year: "2015",
    carBodyType: { name: "Mercedes-Benz E-Class" },
    price: "45.000$",
    image:
      "https://i.pinimg.com/564x/58/e7/4b/58e74b488fa00a4c8789d3adf9beeb1a.jpg",
    hot: false,
  },
  {
    year: "2017",
    carBodyType: { name: "Mercedes-Benz E-Class" },
    price: "55.000$",
    image:
      "https://i.pinimg.com/564x/58/e7/4b/58e74b488fa00a4c8789d3adf9beeb1a.jpg",
    hot: false,
  },
];
export const days = Array.from({ length: 31 }, (_, i) => ({
  name: (i + 1).toString(),
  id: i + 1,
}));

export const months = [
  { name: "January", id: 1 },
  { name: "February", id: 2 },
  { name: "March", id: 3 },
  { name: "April", id: 1 },
  { name: "May", id: 1 },
  { name: "June", id: 1 },
  { name: "July", id: 1 },
  { name: "August", id: 1 },
  { name: "September", id: 1 },
  { name: "October", id: 1 },
  { name: "November", id: 1 },
  { name: "December", id: 1 },
];

const currentYear = new Date().getFullYear();
export const years = Array.from({ length: 100 }, (_, i) => ({
  name: (currentYear - i).toString(),
  id: i,
}));
export const vehicleTypes = [
  { type: "sedan", name: "Passenger Car" },
  { type: "truck", name: "Truck" },
  { type: "quadBike", name: "Quad Bike" },
  { type: "motorcycle", name: "Motorcycle" },
  { type: "snowmobile", name: "Snowmobile" },
];
export const truckOptions = [
  { type: 5, name: "till5tonn" },
  { type: 20, name: "from5till20tonn" },
  { type: 30, name: "moreThan20tonn" },
];
export const engine = [
  {
    id: 1,
    name: "Gasoline",
  },
  {
    id: 2,
    name: "Diesel",
  },
  {
    id: 3,
    name: "Propane",
  },
  {
    id: 4,
    name: "Electric",
  },
  {
    id: 5,
    name: "Hybrid",
  },
];
