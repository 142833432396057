import React from 'react';
import Icon from '@ant-design/icons';

const UploadSvg = () => (
  <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M11.5794 1.59933V19.9495H12.4211V1.59933L19.7023 8.88048L20.2915 8.29125L12.0002 0L3.70898 8.29125L4.29821 8.88048L11.5794 1.59933Z'
      fill='#FF8D4E'
    />
    <path d='M23.364 16.1616H22.5223V24.1583H1.47847V16.1616H0.636719V25H23.364V16.1616Z' fill='#FF8D4E' />
  </svg>
);

const UploadMobileIcon = (props) => <Icon component={UploadSvg} {...props} />;

export default UploadMobileIcon;
