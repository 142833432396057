import {DatePicker, Divider, Spin} from 'antd';
import React, {useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
//import SliderNextIcon from '../../../../components/Common/Icons/sliderNext';
import {
    setTAccount, setTDept,
} from '../../../../redux/Reducers/dealerCarsReducer';
import style from './style.module.scss';
import {useTranslation} from "react-i18next";
import moment from "moment";
import Empty from "antd/es/empty/empty";

const TAccount = () => {
    /* const history = useHistory();*/
    /*const [processingMarket, setProcessingMarket] = useState({});*/
    //
    const tReport = useSelector(
        (state) => state.dealerCars.report,
        shallowEqual,
    );
    const tDept = useSelector(
        (state) => state.dealerCars.tDept,
        shallowEqual,
    );
    const {t} = useTranslation();
    const loading = useSelector(
      (state) => state.dealerCars.loading,
      shallowEqual,
    );
    // const filters = useSelector(
    //   (state) => state.dealerCars.chartFilters,
    //   shallowEqual,
    // );
    const [searchItems, setSearchItems] = useState({
        date: moment().subtract('1 month').startOf('month').format('YYYY-MM-DD'),
    });

    const dispatch = useDispatch();
    // const locationRegex = /\(.*\)/g;

    const yearSearch = (date) => {
        if (date) {
            if (searchItems.date === date) return;
            setSearchItems(prevState => {
                // Object.assign would also work
                prevState.date = date;
                return {...prevState};
            });
        }
    };
    const formatMoney = (sum) => {
        if (sum) {
            // use money Intl without currency sign
            return new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(sum).replace('$', '');
        }
        return '';
    }


    useEffect(() => {
        setTAccount(searchItems.date)(dispatch);
        setTDept()(dispatch);
    }, [dispatch,searchItems.date]);


    return (
        <div className={style.wrapper}>
            {loading && <div className={style.loading}><Spin/></div>}
            {tReport && !tReport.rows && <Empty/>}
            {tReport && tReport.rows &&
                <div>
                    <div className={style.dealerCarFilters}>
                        <DatePicker onChange={yearSearch} value={moment(searchItems.date)}/>
                    </div>
                    <Divider orientation="left">{t('tAccount')}</Divider>
                    <div className="table-responsive">
                        <table className={style.table} style={{width: "100%"}}>
                            <thead>
                            <tr>
                                <th>
                                    <span>{t('date')} {moment(tReport.startDate).format('YYYY-MM-DD')}</span>
                                </th>
                                <th style={{borderRight: "2px solid"}}>
                                    <span>{t('accrued')} USD</span>
                                </th>
                                <th>
                                    <span>{t('paid')} USD</span>
                                </th>
                                <th>
                                    <span>{t('item')}</span>
                                </th>
                                <th>
                                    <span>{t('notes')}</span>
                                </th>
                            </tr>
                            <tr>

                                <td className={style.textRight}>
                                    <strong>{t('balance_before')}</strong>
                                </td>
                                <td style={{borderRight: "2px solid"}}>
                                    <strong>{formatMoney(tReport.sumInvoicesBefore)}</strong>
                                </td>
                                <td>
                                    <strong>{formatMoney(tReport.sumBefore)}</strong>
                                </td>
                                <td>

                                </td>
                                <td>

                                </td>
                            </tr>
                            </thead>
                            <tbody className={style.tableStriped}>
                            {(tReport && tReport.rows && tReport.rows.length &&
                                tReport.rows.map((data, index) => {
                                    return (<tr key={index}>
                                        <td>
                                            {/*<div>{formatDate(data.date)}</div>*/}
                                        </td>
                                        <td style={{borderRight: "2px solid"}}>
                                            <div>{formatMoney(data.creditAmount)}</div>
                                        </td>
                                        <td>
                                            <div>{formatMoney(data.debitAmount)}</div>
                                        </td>
                                        <td>
                                            {data.product || ''}
                                        </td>
                                        <td>
                                            {data.itemNote || ''}
                                        </td>
                                    </tr>)
                                })) || null}
                            </tbody>
                            <tfoot>
                            <tr>
                                <td className={style.textRight}>
                                    <strong>{t('turnover')}</strong>
                                </td>
                                <td style={{borderRight: "2px solid"}}>
                                    <strong>{tReport && tReport.totals && formatMoney(tReport.totals.credit)}</strong>
                                </td>
                                <td>
                                    <strong>{tReport && tReport.totals && formatMoney(tReport.totals.debit)}</strong>
                                </td>
                                <td>

                                </td>
                                <td>

                                </td>
                            </tr>
                            <tr>
                                <td className={style.textRight}>
                                    <strong>{t('end_period')}</strong>
                                </td>
                                <td style={{borderRight: "2px solid"}}>
                                    {tReport && tReport.totals && (tReport.totals.credit + tReport.sumInvoicesBefore) - (tReport.totals.debit + tReport.sumBefore) > 0 &&
                                        // <a href={'/dept'} target="_blank">
                                        <strong>{formatMoney(Math.abs((tReport.totals.credit + tReport.sumInvoicesBefore) - (tReport.totals.debit + tReport.sumBefore)))}</strong>
                                        // </a>
                                    }
                                </td>
                                <td>
                                    {tReport && tReport.totals && (tReport.totals.credit + tReport.sumInvoicesBefore) - (tReport.totals.debit + tReport.sumBefore) < 0 &&
                                        <strong>{formatMoney(Math.abs((tReport.totals.credit + tReport.sumInvoicesBefore) - (tReport.totals.debit + tReport.sumBefore)))}
                                        </strong>
                                    }
                                </td>
                                <td>
                                </td>
                                <td>
                                </td>
                            </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            }
            {tDept && <div>
                <Divider orientation="left">{t('balance')}</Divider>
                <div className="table-responsive">
                    <table className={style.table} style={{width: "100%"}}>
                        <thead>
                        <tr>
                            <th style={{borderRight: "2px solid"}}>
                                <span>{t('vin')}</span>
                            </th>
                            <th>
                                <span>USD</span>
                            </th>
                        </tr>
                        </thead>
                        <tbody className={style.tableStriped}>
                        {tDept && tDept.result && Object.values(tDept.result).map((data, index) => {
                            return (<tr key={index}>
                                <td style={{borderRight: "2px solid"}}>
                                    <div>{data.noteValue}</div>
                                </td>
                                <td>
                                    <div>{formatMoney(data.amount)}</div>
                                </td>
                            </tr>)
                        })}
                        </tbody>
                        <tfoot>
                        {tDept && tDept.result && tDept.total && <tr>
                            <td className={style.textRight} style={{borderRight: "2px solid"}}>
                                <strong>{t('total')}</strong>
                            </td>
                            <td>
                                <strong>{formatMoney(tDept.total)}</strong>
                            </td>
                        </tr>}
                        </tfoot>
                    </table>
                </div>
                <br/>
                <br/>
                <br/>
            </div>}
        </div>
    );
};

export default TAccount;
