import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
import { Pagination } from "antd";
import { VscSettings } from "react-icons/vsc";

import style from "./style.module.scss";
import FilterForm from "./CarNewPage/FilterFrom";
import HotOffers from "./CarNewPage/HotOffers";
import Modal from "./CarNewPage/FilterFormMoblieModal";

import { setFoundCarsByPage } from "../../redux/Reducers/carsReducer";
import { findFilterCars, getOptions } from "../../api/requests";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { buildHttpQuery } from "../../general-functions";
import { setPage } from "../../redux/Actions/paginationAction";

const CarsPage = () => {
  const { width } = useWindowDimensions();
  const [queryOptions, setQueryOptions] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showHotOffers, setShowHotOffers] = useState(true);
  const [hotCars, setHotCars] = useState([]);

  const dispatch = useDispatch();
  const history = useHistory();

  const getActivePage = useCallback(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const page = urlParams.get('currentPage') || 0;
    return page;
  }, []);

  const activePage = getActivePage();

  const query = useSelector(
    (state) => state.router.location.query,
    shallowEqual
  );

  const cars = useSelector((state) => state.cars, shallowEqual);

  const fetchOptions = useCallback(async () => {
    try {
      const result = await getOptions();
      setQueryOptions(result?.data);

      const params = buildHttpQuery({
        hotCar: 1,
      });
      const hotOfferData = await findFilterCars(params);
      setHotCars(hotOfferData.data.data.rows);
    } catch (err) {
      console.error("Error fetching options:", err);
    }
  }, []);

  const handlePageChange = useCallback((page) => {
    dispatch(setPage(page));
    dispatch(setFoundCarsByPage(query, true, setShowHotOffers, page, history));
    
  }, [query, dispatch, history]);

  useEffect(() => {
    let hot = () => {};
    if (Object.entries(query).length > 0) {
      hot = setShowHotOffers;
    }
    dispatch(setFoundCarsByPage(query, true, hot, activePage));
    fetchOptions();
  }, []);

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action === 'POP') {
        const activePage = getActivePage();

        if(activePage) {
          handlePageChange(activePage);  
          if (history.length === 1) fetchOptions();
        }
      }
    });
  
    return unlisten;

  }, []);
  
  return (
    <div className={style.pageContainer}>
      <div className={style.screenContanier}>
        {width > 1199.98 && (
          <FilterForm
            data={queryOptions}
            setShowHotOffers={setShowHotOffers}
          />
        )}
        {width < 1200 && (
          <div className={style.filterWrapper}>
            <h1>Կատալոգ</h1>
            <VscSettings size={30} onClick={() => setShowModal(true)} />
          </div>
        )}
        <div className={style.list}>
          {showHotOffers && hotCars.length > 0 && (
            <HotOffers
              offers={hotCars}
              className={style.hotOffers}
            />
          )}
          {showHotOffers && hotCars.length > 0 && (
            <div className={style.lineStyle} />
          )}

          <HotOffers
            offers={cars.cars}
            className={style.hotOffers}
          />

          {cars.totalItems > 9 && (
            <Pagination
              showTitle={false}
              className={style.pagination}
              current={Number(activePage) || 1}
              pageSize={9}
              onChange={handlePageChange}
              total={cars.totalItems}
              showSizeChanger={false}
            />
          )}
        </div>
      </div>
      <Modal show={showModal} onClose={() => setShowModal(false)}>
        <FilterForm data={queryOptions} />
      </Modal>
    </div>
  );
};

export default CarsPage;
