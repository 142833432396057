import { Pagination } from 'antd';
import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import CarCard from '../../components/Common/Cards/CarCard';
import { setUserPublicCars } from '../../redux/Reducers/carsReducer';
import style from './style.module.scss';
const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:4000';

const UserAnnouncements = (props) => {
  const cars = useSelector((state) => state.cars.cars, shallowEqual);
  const owner = useSelector((state) => state.cars.owner, shallowEqual);
  const userId = props.match.params.id;
  const name = owner.userDetails ? owner.userDetails.name : 'N/A';
  const surname = owner.userDetails ? owner.userDetails.surname : 'N/A';
  const { mobile } = owner;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setUserPublicCars(userId));
  }, [dispatch, userId]);

  return (
    <div className={style.pageWrapper}>
      <div className={style.userInfo}>
        <h1>
          {name ? `${name}` : ''} {surname ? `${surname.slice(0, 1)}` : ''}
        </h1>
        <hr />
        <h2>{mobile ? `${mobile}` : ''}</h2>
      </div>
      <div className={style.carsContainer}>
        {cars.map((car) => {
          ////console.log(car);
          const firstMedia =
            BASE_URL + car.carMedia[0].carImage.location + '/' + car.carMedia[0].carImage.name + 'small.jpeg';
          return (
            <CarCard
              key={car.id}
              id={car.id}
              make={car.carMake}
              model={car.carModel}
              year={car.year}
              image={firstMedia}
              price={car.price}
              shopStatus={car.shopStatus}
              currency={car.currency}
            />
          );
        })}
      </div>
      <Pagination
        showTitle={false}
        className={style.pagination}
        defaultCurrent={1}
        pageSize={12}
        total={cars.length} // receives total number of items on page
        showSizeChanger={false}
      />
    </div>
  );
};

export default UserAnnouncements;
