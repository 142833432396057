import React from "react";
import Icon from "@ant-design/icons";

const UpSvg = () => (
  <svg
    width="18"
    height="15"
    viewBox="0 0 18 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9 0L17.6603 15H0.339746L9 0Z" fill="#FF8D4E" />
  </svg>
);

const UpIcon = (props) => <Icon component={UpSvg} {...props} />;

export default UpIcon;
