import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import CloseIcon from '../../components/Common/Icons/close';
import ScalesIconBig from '../../components/Common/Icons/scalesBig';
import { removeFromCompare, setActiveCompareIndex } from '../../redux/Reducers/userReducer';
import style from './style.module.scss';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:4000';

const ComparePage = () => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const compare = useSelector((state) => state.user.compare, shallowEqual);
  const compareMobile = useSelector(
    (state) => (state.user.compare.length > 0 ? [state.user.compare[state.user.activeCompareIndex]] : []),
    shallowEqual
  );
  const activeIndex = useSelector((state) => state.user.activeCompareIndex, shallowEqual);
  const dispatch = useDispatch();
  const categories =
    width > 480
      ? [
          t('placeholder_model'),
          t('year'),
          t('body_type'),
          t('placeholder_mileage'),
          t('placeholder_steeringWheel'),
          t('placeholder_engine'),
          t('placeholder_engineCap'),
          t('placeholder_gearBox'),
          t('color'),
          t('placeholder_int_color'),
          t('placeholder_price'),
        ]
      : [
          t('year'),
          t('body_type'),
          t('placeholder_mileage'),
          t('placeholder_steeringWheel'),
          t('placeholder_engine'),
          t('placeholder_engineCap'),
          t('placeholder_gearBox'),
          t('color'),
          t('placeholder_int_color'),
          t('placeholder_price'),
        ];
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    return () => {
      dispatch(setActiveCompareIndex(0));
    };
  }, [dispatch]);

  const handleDelete = (id) => {
    dispatch(removeFromCompare(id));
  };

  const handleActiveIndex = (index) => {
    dispatch(setActiveCompareIndex(index));
  };

  return (
    <div className={style.pageContainer}>
      <h1>{t('compare_txt')}</h1>
      <div className={style.compareContainer}>
        <div className={style.compareImgs}>
          <div>
            <ScalesIconBig />
          </div>
          {compare.map((car, i) => {
            const firstMedia =
              BASE_URL + car.carMedia[0].carImage.location + '/' + car.carMedia[0].carImage.name + 'small.jpeg';
            return (
              <div
                key={car.id}
                className={style.carPic}
                onClick={(e) => {
                  e.stopPropagation();
                  handleActiveIndex(i);
                }}
              >
                <CloseIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDelete(car.id);
                  }}
                />
                <img src={firstMedia} alt='' />
                {width <= 480 && <h1 style={{ color: activeIndex === i && '#156DC6' }}>{car.carMake.name}</h1>}
                {width <= 480 && <h2 style={{ color: activeIndex === i && '#156DC6' }}>{car.carModel.name}</h2>}
              </div>
            );
          })}
        </div>
        {categories.map((value, i) => (
          <div className={style.compareLines} key={value}>
            <div>
              <h1>{value}</h1>
            </div>
            {width > 480
              ? compare.length > 0 &&
                compare.map((car) => {
                  return (
                    <div key={car.id}>
                      <h2>
                        {(i === 0 && `${car.carMake.name} ${car.carModel.name}`) ||
                          (i === 1 && car.year) ||
                          (i === 2 && car.carBodyType && `${t(`bodyType_${car.carBodyType.name}`)}`) ||
                          (i === 2 && car.bodyType === 8 && `${t('Other')}`) ||
                          (i === 2 && !car.carBodyType && '-') ||
                          (i === 3 && car.mileageType === 0 && `${car.mileage} ${t('km')}`) ||
                          (i === 3 && car.mileageType === 1 && `${car.mileage} ${t('miles')}`) ||
                          (i === 4 && car.steeringWheel === 1 && `${t('left')}`) ||
                          (i === 4 && car.steeringWheel === 2 && `${t('right')}`) ||
                          (i === 5 && car.engine === 1 && `${t('gasoline')}`) ||
                          (i === 5 && car.engine === 2 && `${t('diesel')}`) ||
                          (i === 5 && car.engine === 3 && `${t('gas')}`) ||
                          (i === 5 && car.engine === 4 && `${t('electric')}`) ||
                          (i === 6 && car.carTrim.name) ||
                          (i === 7 && car.carGearBox && `${t(`gearBox_${car.carGearBox.name}`)}`) ||
                          (i === 8 && `${t(`${car.carExtColor.name}`)}`) ||
                          (i === 9 && `${t(`${car.carIntColor.name}`)}`) ||
                          (i === 10 && car.currency === 0 && `USD ${car.price}`) ||
                          (i === 10 && car.currency === 1 && `AMD ${car.price}`) ||
                          (i === 10 && car.currency === 2 && `RUB ${car.price}`)}
                      </h2>
                    </div>
                  );
                })
              : compareMobile.length > 0 &&
                compareMobile.map((car) => {
                  return (
                    <div key={car.id}>
                      <h2>
                        {(i === 0 && car.year) ||
                          (i === 1 && car.carBodyType && car.carBodyType.name) ||
                          (i === 2 && car.mileageType === 0 && `${car.mileage} ${t('km')}`) ||
                          (i === 2 && car.mileageType === 1 && `${car.mileage} ${t('miles')}`) ||
                          (i === 3 && car.steeringWheel === 1 && `${t('left')}`) ||
                          (i === 3 && car.steeringWheel === 2 && `${t('right')}`) ||
                          (i === 4 && car.engine === 1 && `${t('gasoline')}`) ||
                          (i === 4 && car.engine === 2 && `${t('diesel')}`) ||
                          (i === 4 && car.engine === 3 && `${t('gas')}`) ||
                          (i === 4 && car.engine === 4 && `${t('electric')}`) ||
                          (i === 5 && car.carTrim.name) ||
                          (i === 6 && car.gearBox === 1 && `${t('manual')}`) ||
                          (i === 6 && car.gearBox === 2 && `${t('automatic')}`) ||
                          (i === 6 && car.gearBox === 3 && `${t('semi_aut')}`) ||
                          (i === 6 && car.gearBox === 4 && `${t('variable')}`) ||
                          (i === 7 && car.carExtColor.name) ||
                          (i === 8 && car.carIntColor.name) }
                      </h2>
                    </div>
                  );
                })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ComparePage;
