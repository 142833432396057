import React from 'react';
import CalcArrow from '../../../components/Common/Icons/calcArrow';
import CalcIcon from '../../../components/Common/Icons/CalcIcon';
import style from './style.module.scss';

const IconContainer = ({ prices, setPrices }) => {
  const hideIcon = () => {
    setPrices({ ...prices, ready: false });
  };

  return (
    <div
      className={!prices.ready ? style.iconContainer : `${style.iconContainer} ${style.showIcon}`}
      onClick={hideIcon}
    >
      <CalcIcon />
      <CalcArrow />
    </div>
  );
};

export default IconContainer;
