import React from "react";
import Icon from "@ant-design/icons";

const ProfileSvg = () => (
  <svg
    width="19"
    height="21"
    viewBox="0 0 19 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.49983 10.7803C12.5176 10.7803 14.9727 8.36216 14.9727 5.3903C14.9727 2.4181 12.5176 0 9.49983 0C6.4821 0 4.02696 2.4181 4.02696 5.3903C4.02696 8.36216 6.4821 10.7803 9.49983 10.7803ZM9.49983 1.35146C11.7612 1.35146 13.6005 3.16309 13.6005 5.3903C13.6005 7.61717 11.7612 9.4288 9.49983 9.4288C7.2385 9.4288 5.39912 7.61717 5.39912 5.3903C5.39912 3.16343 7.2385 1.35146 9.49983 1.35146ZM18.7997 20.1685C17.7205 15.6857 13.8959 12.5547 9.49983 12.5547C5.10376 12.5547 1.2792 15.6857 0.199993 20.1685L0 21H19L18.7997 20.1685ZM1.77695 19.6489C2.94981 16.228 6.01796 13.9062 9.49983 13.9062C12.9817 13.9062 16.0499 16.228 17.2227 19.6489H1.77695Z"
      fill="white"
    />
  </svg>
);

const ProfileIcon = (props) => <Icon component={ProfileSvg} {...props} />;

export default ProfileIcon;
