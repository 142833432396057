import React from "react";
// import { useTranslation } from "react-i18next";
// import { shallowEqual, useSelector } from "react-redux";
// import { Link } from "react-router-dom";
import style from "../style.module.scss";

const Description = ({ text }) => {
  if (!text) return null;
  return (
    <div className={style.description}>
      <p>{text}</p>
    </div>
  );
};

export default Description;
