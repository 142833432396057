import React from 'react';
import { useTranslation } from 'react-i18next';
import style from '../../../style.module.scss';

const PriceLabels = () => {
  const { t } = useTranslation();

  return (
    <div className={style.priceLabels}>
      <h1>labels</h1>
      <div>
        <p>{t('placeholder_price')}</p>
      </div>
      <div>
        <p>{t('t_price')}</p>
      </div>
      <div>
        <p>{t('insurance')}</p>
      </div>
      <div>
        <p>{t('storage')}</p>
      </div>
      <div>
        <p>{t('dealer_fee')}</p>
      </div>
      <div>
        <p>{t('late_fee')}</p>
      </div>
      <div>
        <p>{t('document_fee')}</p>
      </div>
      <div>
        <p>{t('custom_fee')}</p>
      </div>
      <div>
        <p>{t('sub_lot')}</p>
      </div>
      <div>
        <p>{t('total')}</p>
      </div>
    </div>
  );
};

export default PriceLabels;
