import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './style.module.scss';

const StatusLabel = ({ status }) => {
  const { t } = useTranslation();
  return (
    <div
      className={style.shopStatus}
      style={{ background: (status === 0 && 'orange') || (status === 1 && 'green') || (status === 2 && 'red') }}
    >
      {(status === 0 && t('moderation')) || (status === 1 && t('market')) || (status === 2 && t('removed'))}
    </div>
  );
};

export default StatusLabel;
