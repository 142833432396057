import React, { useState } from "react";
import Slider from "react-slick";
import "./style.scss";
import PreviousBtn from "./Carousel_Item/PrevBtn";
import NextBtn from "./Carousel_Item/NextBtn";
import CarouselItem from "./Carousel_Item";
import { shallowEqual, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const ProductCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const cars = useSelector((state) => state.cars, shallowEqual);
  const { t } = useTranslation();

  const settings = {
    lazyLoad: "ondemand",
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextBtn />,
    prevArrow: <PreviousBtn />,
    infinite: true,
    beforeChange: (next) => setActiveIndex(next),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="product-carousel">
      <div className="product-carousel-title">{t('find_car')}</div>
      <Slider {...settings}>
        {cars.cars?.map((product, index) => (
          <CarouselItem product={product} key={index} />
        ))}
      </Slider>
      <div className="indicators">
        {[...Array(3)].map((_, idx) => (
          <div
            key={idx}
            className={`indicator ${
              activeIndex % 3 === idx ? "active" : ""
            }`}></div>
        ))}
      </div>
      <button className="product-carousel-read-more">
        <a href={"/cars"}> {t("showMore")}</a>
      </button>
    </div>
  );
};

export default ProductCarousel;
