import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL || "http://localhost:4000";
const origin = window.location.origin;

const allowList = [
  "https://www.autobuy.am",
  "https://autobuy.am",
  "http://www.autobuy.am",
  "http://autobuy.am",
  process.env.REACT_APP_ORIGIN_URL,
];

const AXIOS = axios.create({
  baseURL: allowList.includes(origin) ? origin + "/api" : BASE_URL,
  withCredentials: true,
  headers: {
    "Access-Control-Allow-Origin": allowList.includes(origin)
      ? origin
      : "http://localhost:3000",
  },
});

export const registerUser = async (data) => {
  const res = await AXIOS.post("/registration", data).catch((err) => {
    if (err.response) {
      return err.response;
    }
  });
  return res;
};

export const loginUser = async (data) => {
  const res = await AXIOS.post("/login", data).catch((err) => {
    if (err.response) {
      return err.response;
    }
  });
  return res;
};

export const loginOTL = async (data) => {
  const res = await AXIOS.get("/reset/password/login/?otl=" + data).catch(
    (err) => {
      if (err.response) {
        return err.response;
      }
    }
  );
  return res;
};
export const resetPassword = async (data) => {
  const res = await AXIOS.post("/user/recover", data).catch((err) => {
    if (err.response) {
      return err.response;
    }
  });
  return res;
};
export const addCar = async (data) => {
  const res = await AXIOS.post("/car/add", data).catch((err) => {
    if (err.response) {
      return err.response;
    }
  });
  return res;
};

export const editCarReq = async (data, id) => {
  const res = await AXIOS.patch(`/car/edit/${id}`, {
    params: { ...data },
  }).catch((err) => {
    if (err.response) {
      return err.response;
    }
  });
  return res;
};

export const deleteCar = async (id) => {
  const res = await AXIOS.delete("/car/delete", { data: { id } }).catch(
    (err) => {
      if (err.response) {
        return err.response;
      }
    }
  );
  return res;
};

export const addToFavsReq = async (carId) => {
  const res = await AXIOS.post("/user/favourites", { carId }).catch((err) => {
    if (err.response) {
      return err.response;
    }
  });
  return res;
};

export const deleteFavorite = async (carId) => {
  const res = await AXIOS.delete("/user/removefavourites", {
    data: { carId },
  }).catch((err) => {
    if (err.response) {
      return err.response;
    }
  });
  return res;
};

export const findCars = async (data) => {
  const res = await AXIOS.get(
    "/cars",
    { params: { ...data } },
    { headers: { "Content-Type": "application/json" } }
  ).catch((err) => {
    if (err.response) {
      return err.response;
    }
  });
  return res;
};

export const findFilterCars = async (data, page) => {
  const correctPage = page > 0 ? page - 1 : 0;
  const res = await AXIOS.get(`/cars/page/${correctPage}/9?${data}`, {
    headers: { "Content-Type": "application/json" },
  }).catch((err) => {
    if (err.response) {
      return err.response;
    }
  });
  return res;
};

//pagination
export const findCarsByPage = async (data, page) => {
  const correctPage = page > 0 ? page - 1 : 0;
  const res = await AXIOS.get(
    `/cars/page/${correctPage}/9?${data}`,
    { headers: { "Content-Type": "application/json" } }
  ).catch((err) => {
    if (err.response) {
      return err.response;
    }
  });
  return res;
};

export const findUserCars = async (userId) => {
  const res = await AXIOS.get("/cars/userCars", {
    params: { userId },
    headers: { "Content-Type": "application/json" },
  }).catch((err) => {
    if (err.response) {
      return err.response;
    }
  });
  return res;
};

export const findUserFavs = async (userId) => {
  const res = await AXIOS.get("/user/getFavourites", {
    params: { userId },
    headers: { "Content-Type": "application/json" },
  }).catch((err) => {
    if (err.response) {
      return err.response;
    }
  });
  return res;
};

export const findUserPublicCars = async (userId) => {
  const res = await AXIOS.get("/cars/user/public", {
    params: { userId },
    headers: { "Content-Type": "application/json" },
  }).catch((err) => {
    if (err.response) {
      return err.response;
    }
  });
  return res;
};

export const getUserInfo = async () => {
  const res = await AXIOS.get(`/user/info/`, {
    headers: { "Content-Type": "application/json" },
  }).catch((err) => {
    if (err.response) {
      return err.response;
    }
  });
  return res;
};

export const getCarDetails = async (carId) => {
  const res = await AXIOS.get(`/car/${carId}`, {
    headers: { "Content-Type": "application/json" },
  }).catch((err) => {
    if (err.response) {
      return err.response;
    }
  });
  return res;
};

export const getRelatedCars = async (carId, makeId, modelId) => {
  const res = await AXIOS.get(
    `/cars/related`,
    { params: { carId, makeId, modelId } },
    {
      headers: { "Content-Type": "application/json" },
    }
  ).catch((err) => {
    if (err.response) {
      return err.response;
    }
  });
  return res;
};
//options

export const getOptions = async () => {
  const res = await AXIOS.get("/options").catch((err) => {
    if (err) {
      return err.response;
    }
  });
  return res;
};

export const getModels = async (makeId) => {
  const res = await AXIOS.get(
    "/options/model",
    { params: makeId },
    { headers: { "Content-Type": "application/json" } }
  ).catch((err) => {
    if (err) {
      return err.response;
    }
  });
  return res;
};

export const getTrim = async (modelId) => {
  const res = await AXIOS.get(
    "/options/trim",
    { params: modelId },
    { headers: { "Content-Type": "application/json" } }
  ).catch((err) => {
    if (err) {
      return err.response;
    }
  });
  return res;
};

//user details

export const editUserDetails = async (details) => {
  const res = await AXIOS.post("/user/settings", details).catch((err) => {
    if (err.response) {
      return err.response;
    }
  });
  return res;
};

export const getEmailActivationCode = async () => {
  const res = await AXIOS.get(
    "/email/send-activation",
    // { params: email },
    { headers: { "Content-Type": "application/json" } }
  ).catch((err) => {
    if (err) {
      return err.response;
    }
  });
  return res;
};

export const activateByEmail = async (activationCode) => {
  const res = await AXIOS.post("/email/activate", activationCode).catch(
    (err) => {
      if (err.response) {
        return err.response;
      }
    }
  );
  return res;
};

export const getMobileActivationCode = async () => {
  const res = await AXIOS.get(
    "/mobile/send-activation",
    // { params: mobile },
    { headers: { "Content-Type": "application/json" } }
  ).catch((err) => {
    if (err) {
      return err.response;
    }
  });
  return res;
};

export const activateByMobile = async (activationCode) => {
  const res = await AXIOS.post("/mobile/activate", activationCode).catch(
    (err) => {
      if (err.response) {
        return err.response;
      }
    }
  );
  return res;
};

export const deactivateAccount = async (password) => {
  const res = await AXIOS.post("/user/deactivate", password).catch((err) => {
    if (err.response) {
      return err.response;
    }
  });
  return res;
};

export const logOut = async () => {
  const res = await AXIOS.get(
    "/log-out",
    // { params: mobile },
    { headers: { "Content-Type": "application/json" } }
  ).catch((err) => {
    if (err) {
      return err.response;
    }
  });
  return res;
};

//dealers

export const getDealerCars = async (
  pagination,
  filters,
  sorter,
  searchElements = {}
) => {
  delete filters.currentDataSource;
  delete searchElements.currentDataSource;
  delete pagination.showTotal;
  delete pagination.showSizeChanger;
  return await AXIOS.get("/dealer/cars", {
    params: {
      ...pagination,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
      ...searchElements,
    },
    headers: {"Content-Type": "application/json"},
  }).catch((err) => {
    if (err.response) {
      return err.response;
    }
  });
};

export const getSubDealers = async () => {
  return await AXIOS.get("/dealer/sub", {
    headers: {"Content-Type": "application/json"},
  }).catch((err) => {
    if (err.response) {
      return err.response;
    }
  });
};

export const getChartData = async (year) => {
  return await AXIOS.get(`/dealer/chart/${year}`, {
    headers: { "Content-Type": "application/json" },
  }).catch((err) => {
    if (err.response) {
      return err.response;
    }
  });
};
export const getTAccount = async (startDate, endDate) => {
  return await AXIOS.post(
    `/dealer/t-account`,
    { startDate, endDate },
    {
      headers: { "Content-Type": "application/json" },
    }
  ).catch((err) => {
    if (err.response) {
      return err.response;
    }
  });
};
export const getTDept = async () => {
  return await AXIOS.get(`/dealer/t-dept`, {
    headers: { "Content-Type": "application/json" },
  }).catch((err) => {
    if (err.response) {
      return err.response;
    }
  });
};

export const getDealerCarDetails = async (id) => {
  const res = await AXIOS.get(`/dealer/car/${id}`, {
    headers: { "Content-Type": "application/json" },
  }).catch((err) => {
    if (err.response) {
      return err.response;
    }
  });
  return res;
};
export const getDealerCarImages = async (id) => {
  const res = await AXIOS.post(
    `/dealer/car/images/${id}`,
    {
      1: "1",
    },
    {
      headers: { "Content-Type": "application/json" },
      responseType: "blob",
    }
  ).catch((err) => {
    if (err.response) {
      return err.response;
    }
  });
  return res;
};
export const putCarToMarket = async (id) => {
  const res = await AXIOS.patch(
    `/car/to/market/${id}`,
    {
      1: "1",
    },
    {
      headers: { "Content-Type": "application/json" },
    }
  ).catch((err) => {
    if (err.response) {
      return err.response;
    }
  });
  return res;
};
export const loadLocations = async (auction) => {
  const res = await AXIOS.get("/calculator/locations", {
    params: { auction: auction },
    headers: { withCredentials: false },
  }).catch((err) => {
    if (err.response) {
      return err.response;
    }
  });
  return res;
};
export const loadFobs = async () => {
  const res = await AXIOS.get("/calculator/fobs", {
    headers: { withCredentials: false },
  }).catch((err) => {
    if (err.response) {
      return err.response;
    }
  });
  return res;
};
export const calculatePrice = async (data) => {
  const res = await AXIOS.post("/calculator", data, {
    headers: { withCredentials: false },
  }).catch((err) => {
    if (err.response) {
      return err.response;
    }
  });
  return res;
};

export const getTransportPrice = async (data) => {
  const res = await AXIOS.get("/calculator/transportprice", {
    params: { auction: data.auction, location: data.location },
    headers: { withCredentials: false },
  }).catch((err) => {
    if (err.response) {
      return err.response;
    }
  });
  return res;
};
export const getCounts = async () => {
  const res = await axios
    .get("https://autobuy.am/api/public/data/counts")
    .catch((err) => {
      if (err.response) {
        return err.response;
      }
    });
  return res.data;
};
