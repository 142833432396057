import React, { useEffect } from 'react';
import style from './style.module.scss';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import UserTabs from './userTabs/UserTabs';
import DealerTabs from './dealerTabs/DealerTabs';
import { setUserCars, setUserFavs } from '../../redux/Reducers/userReducer';
import Activation from './activateSection/Activation';
import Button from '../../components/Common/Buttons/Button';

const ProfilePage = () => {
  const user = useSelector((state) => (state.user.info ? state.user : { info: { userDetails: {} } }), shallowEqual);
  const { userType, mobile, emailConfirmed, mobileConfirmed } = user.info;
  const name = user.info.userDetails ? user.info.userDetails.name : 'N/A';
  const surname = user.info.userDetails ? user.info.userDetails.surname : 'N/A';
  const dispatch = useDispatch();
  useEffect(() => {
    if (user.activeTab === '1') {
      dispatch(setUserCars(user.info.id));
    }
    if (user.activeTab === '2') {
      dispatch(setUserFavs(user.info.id));
    }
  }, [user.info.id, user.activeTab, dispatch]);

  const goToLiveAuction = () => {
    window.open('/live_auction', '_blank');
    console.log('go to live auction');
  };

  return (
    <div className={style.pageContainer}>
      <div className={style.userInfoWrapper}>
        <div className={style.userInfo}>
          <h1>
            {name ? `${name} ` : ''}
            {surname ? `${surname} ` : ''}
          </h1>
          <hr />
          <h2> {mobile ? mobile : ''}</h2>
          {userType === 5 && <Button innerText={'Live Auction'} handleClick={goToLiveAuction} /> && false}
        </div>

        {(emailConfirmed === 0 || mobileConfirmed === 0) && <Activation />}
        {userType === 5 ? <DealerTabs /> : <UserTabs />}
      </div>
    </div>
  );
};

export default ProfilePage;
