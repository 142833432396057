import React from 'react';
import RadioGroup from './RadioGroup';
import style from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';

const Questions = ({ control, setValue }) => {
  const { t } = useTranslation();
  const questions = useSelector((state) => state.sell.slice(17, 20), shallowEqual);
  return (
    <div className={style.questions}>
      <h1>
        {t('answer_questions')}
        <span className={style.asterisk}>*</span>
      </h1>
      {questions.map((question, i) => {
        return (
          <React.Fragment key={i}>
            {(i === 0 && <h2>1.{t('part_by_part')}</h2>) ||
              (i === 1 && <h2>2.{t('exchange')}</h2>) ||
              (i === 2 && <h2>3.{t('custom_cleared')}</h2>)}
            <RadioGroup
              control={control}
              name={question.name}
              setValue={setValue}
              defaultValue={question.defaultValue}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Questions;
