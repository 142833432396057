import { Input, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { activateByEmail, getEmailActivationCode } from '../../../../api/requests';
import Button from '../../Buttons/Button';
import style from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const EmailActivationForm = ({ step, setStep, modalState, setModalState }) => {
  const { handleSubmit, control } = useForm();
  const [timeLeft, setTimeLeft] = useState(30);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!timeLeft) return;
    const intervalId =
      step === 2 &&
      setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft, step]);

  const openNotification = (text, type) => {
    notification[type]({
      description: text,
      className: style.notification,
    });
  };

  const onSubmit = (data) => {
    ////console.log(data);
    if (step === 1) {
      getEmailActivationCode(data);

      setStep(2);
    } else {
      activateByEmail(data).then((res) => {
        const { message, ...data } = res.data;
        const text = res.data.message;
        if (res.status === 200) {
          ////console.log(data);
          dispatch({ type: 'SET_EMAIL_CONFIRMED', data });
          setModalState({ ...modalState, isVisible: false });
          openNotification(text, 'success');
        } else {
          openNotification(text, 'error');
        }
      });
      setStep(1);
    }
  };
  const { t } = useTranslation();

  return (
    <form autoComplete='off' onSubmit={handleSubmit(onSubmit)} className={style.activationForm}>
      <h1>{step === 1 ? `${t('mail_activate')}` : `${t('code_received')}`}</h1>
      {step === 1 ? null : ( // ) //   /> //     defaultValue='' //     control={control} //     name='email' //     as={<Input type='email' placeholder={t('email_btn')} required className={style.emailInput} />} //   <Controller // (
        <Controller
          as={
            <Input
              type='text'
              placeholder={t('code')}
              required
              className={style.emailInput}
              maxLength={4}
              minLength={4}
            />
          }
          name='activationCode'
          control={control}
          defaultValue=''
        />
      )}
      {step === 1 ? (
        <Button type='submit' innerText={t('submit_btn')} />
      ) : (
        <div className={style.btnsWrapper}>
          <Button
            handleClick={() => {
              setStep(1);
              setTimeLeft(30);
            }}
            innerText={timeLeft > 0 ? `${t('resend_in')} ${timeLeft}` : `${t('resend')}`}
            disabled={timeLeft > 0 ? true : false}
          />
          <Button type='submit' innerText={t('submit_btn')} />
        </div>
      )}
    </form>
  );
};

export default EmailActivationForm;
