import React from "react";
import style from "./style.module.scss";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";

const ResultsContainer = ({ prices, details }) => {
  const { t } = useTranslation();
  const cleanPrice = JSON.parse(JSON.stringify(prices || []));
  delete cleanPrice.customPrice;
  delete cleanPrice.location;
  const setDate = new Date();
  setDate.setHours(setDate.getHours() + 4);
  const results = Object.entries(cleanPrice)
    .map((price) => {
      return { [price[0]]: price[1], label: price[0] };
    })
    .filter(
      (result) =>
        !result.hasOwnProperty("currencies") && !result.hasOwnProperty("ready")
    );
  const orderLabels = [
    "price",
    "tax",
    "fob",
    "aah",
    "transport",
    "bnph",
    "insurance",
    "commission",
  ];
  const limitFields = [
    "tax",
    "transport",
    "insurance",
    "aah",
    "bnph",
    "commission",
  ];
  const orderMobileLabels = [
    "price",
    "fob",
    "tax",
    "transport",
    "insurance",
    "aah",
    "bnph",
    "commission",
  ];
  const user = useSelector(
    (state) => (state.user.info ? state.user : { info: { userDetails: {} } }),
    shallowEqual
  );
  return (
      <div className={style.resultsContainer}>
        {prices &&
            prices.currencies &&
            prices.currencies.EUR &&
            prices.currencies.USD && (
                <div className={style.currenciesContainer}>
                  <div className={style.currencyContainer}>
                    <div>1 USD</div>
                    <div className={style.currencyContainerValue}>
                      {prices.currencies.USD} AMD
                    </div>
                  </div>
                  <div className={style.currencySeparator}>/</div>
                  <div className={style.currencyContainer}>
                    <div>1 EUR</div>
                    <div className={style.currencyContainerValue}>
                      {prices.currencies.EUR} AMD
                    </div>
                  </div>
                  <div className={style.currencySeparator}>/</div>
                  <div className={style.currencyContainer}>
                    <div>EUR/USD</div>
                    <div className={style.currencyContainerValue}>
                      {(prices.currencies.EUR / prices.currencies.USD).toFixed(4)}
                    </div>
                  </div>
                </div>
            )}
        {details.auction && (
            <div className={style.notesContainer}>
              <div style={{textAlign: "center"}}>
                {details.isJuridical ? t("juridical") : t("physical")}
                &nbsp;<span style={{color: "white"}}>/&nbsp;</span>
                {t(details.carScale)}&nbsp;
                {details.tonnage && details.carScale === "truck" ? (
                    <span style={{color: "white"}}>
                /&nbsp;{t("tonnage")}:&nbsp;
              </span>
                ) : (
                    ""
                )}
                {details.tonnage && details.carScale === "truck" ? (
                    <span>
                {(details.tonnage <= 5 && t("till5tonn")) ||
                    (details.tonnage > 5 &&
                        details.tonnage <= 20 &&
                        t("from5till20tonn")) ||
                    (details.tonnage > 20 && t("moreThan20tonn"))}
              </span>
                ) : (
                    ""
                )}
                {details.nash && details.engine === "hybrid" ? (
                    <span style={{color: "white"}}>/&nbsp;{t("nash")}</span>
                ) : (
                    ""
                )}
                {details.hasR && details.carScale === "quadBike" ? (
                    <span>/&nbsp;{t("hasR")}</span>
                ) : (
                    ""
                )}
              </div>
              {setDate.toISOString().slice(0, 19).replace("T", " ")}
              &nbsp;<span style={{color: "white"}}>/&nbsp;</span>
              {details.auction}
              &nbsp;
              <span style={{color: "white"}}>/&nbsp;{t("auc_loc")}:&nbsp;</span>
              {details.location}
              &nbsp;
              <span style={{color: "white"}}>
            /&nbsp;{t("placeholder_year")}:&nbsp;
          </span>
              {details.year}/
              {details.month
                  ? details.month < 10
                      ? "0" + details.month
                      : details.month
                  : "--"}
              /
              {details.day
                  ? details.day < 10
                      ? "0" + details.day
                      : details.day
                  : "--"}
              &nbsp;
              <span style={{color: "white"}}>
            /&nbsp;{t("placeholder_engine")}:&nbsp;
          </span>
              {t(details.engine)}
              &nbsp;
              <span style={{color: "white"}}>
            /&nbsp;{t("engine_vol")}:&nbsp;
          </span>
              {details.engineVol}&nbsp;
              {details.carType && <span style={{color: "white"}}>/&nbsp;</span>}
              {details.carType && t("ground_clearance")}
            </div>
        )}
        <div className={`${style.resultsTable} ${style.resultsTableDesktop}`}>
          {orderLabels.map((label) => {
            return (
                <span key={label}>
              <span className={style.labelName}>
                {details.isJuridical === 0 && label === "tax"
                    ? t("taxPhysical")
                    : t(
                        `${
                            label === "commission" && details.isJuridical
                                ? "taxes"
                                : label
                        }`
                    )}
              </span>
              <span className={style.separator}>/</span>
              <span
                  className={style.number}
                  style={
                    (!user || !user.info || !user.info.id) &&
                    limitFields.includes(label)
                        ? {filter: "blur(0.25rem)"}
                        : {}
                  }>
                $
                {user && user.info && user.info.id
                    ? Math.round(prices[label])
                    : limitFields.includes(label)
                        ? "partner"
                        : Math.round(prices[label])}
              </span>
            </span>
            );
          })}
        </div>
        <div className={`${style.resultsTable} ${style.resultsTableMobile}`}>
          {orderMobileLabels.map((label) => {
            return (
                <span key={label}>
              <span className={style.labelName}>
                {t(
                    `${
                        label === "commission" && details.isJuridical
                            ? "taxes"
                            : label
                    }`
                )}
              </span>
              <span className={style.separator}>/</span>
              <span
                  className={style.number}
                  style={
                    (!user || !user.info || !user.info.id) &&
                    limitFields.includes(label)
                        ? {filter: "blur(0.25rem)"}
                        : {}
                  }>
                $
                {user && user.info && user.info.id
                    ? Math.round(prices[label])
                    : limitFields.includes(label)
                        ? "partner"
                        : Math.round(prices[label])}
              </span>
            </span>
            );
          })}
        </div>
        {!(user && user.info && user.info.id) && (
            <a
                className={style.noticeText}
                style={{
                  color: "#ff8d4e",
                  fontSize: "1.5rem",
                  textAlign: "center",
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                title={"partner"}
                href={"/partners"}>
              {t("becomePartner")}
            </a>
        )}
        {user && user.info && user.info.id && (
            <div className={style.total}>
              {prices &&
                  prices.transportNotes &&
                  prices.transportNotes === "call" && (
                      <p style={{color: "red"}}>{t("call_for_price")}</p>
                  )}
              <p>{t("total")}</p>
              <p>
                {(prices &&
                    prices.transportNotes &&
                    prices.transportNotes === "call") ||
                !user ||
                !user.info ||
                !user.info.id
                    ? "-"
                    : "$" +
                    Math.round(
                        results.reduce((acc, curr) => {
                          // check if the current value is number
                          if (
                              typeof acc[acc.label] === "number" ||
                              typeof acc === "number"
                          ) {
                            return acc[acc.label]
                                ? Math.round(acc[acc.label]) +
                                Math.round(curr[curr.label])
                                : Math.round(acc) + Math.round(curr[curr.label]);
                          }
                          return 0;
                        })
                    ).toString()}
                {/*{ prices && prices.currencies && prices.currencies.EUR && prices.currencies.USD &&*/}
                {/*  <div className={style.currencyAlert}>*/}
                {/*    */}
                {/*  </div>*/}
                {/*}*/}
              </p>
            </div>
        )}
        <div className={style.noticeTextRedTitle}>{t("important")}</div>
        <div className={style.noticeTextOrange}>{t("importantNotice")}</div>
        <div className={style.noticeText}>{t("calculatorNoticePrice")}</div>
      </div>
  );
};

export default ResultsContainer;
