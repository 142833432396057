import { Input, Popover } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { deactivateAccount } from '../../../../api/requests';
import Button from '../../Buttons/Button';
import style from './style.module.scss';

const DeleteSection = () => {
  const { t } = useTranslation();
  const [popover, setPopover] = useState({
    visible: false,
    message: '',
    password: '',
  });
  const dispatch = useDispatch();

  useEffect(() => {
    setPopover((popover) => {
      return { ...popover, message: t('delete_password') };
    });
  }, [t]);

  const inputChange = (e) => {
    setPopover({ ...popover, password: e.target.value });
  };

  const handleSubmit = () => {
    const password = { password: popover.password };
    if (password.password.length > 0) {
      deactivateAccount(password).then((res) => {
        if (res.status === 200) {
          dispatch({ type: 'LOG_OUT' });
        } else {
          setPopover({ ...popover, message: res.data.message });
        }
      });
    }
  };

  return (
    <Popover
      open={popover.visible}
      overlayClassName={style.popConfirm}
      content={
        <React.Fragment>
          <p>{popover.message}</p>

          <Input type='password' placeholder={t('current_pass')} value={popover.password} onChange={inputChange} />

          <div className={style.buttonWrapper}>
            <Button
              innerText={t('cancel')}
              handleClick={() => {
                setPopover({ ...popover, visible: false });
              }}
            />
            <Button innerText={t('delete')} handleClick={handleSubmit} />
          </div>
        </React.Fragment>
      }
    >
      <span onClick={() => setPopover({ ...popover, visible: true, message: t('delete_password') })}>
        {t('delete_account')}
      </span>
    </Popover>
  );
};

export default DeleteSection;
