import React, { useEffect } from 'react';
import style from '../../../Common/Selects/style.module.scss';
import { Select, Tag } from 'antd';
import { Controller } from 'react-hook-form';
import gradient from '../../../../assets/Images/gradient.png';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { addColor, removeColor } from '../../../../redux/Reducers/searchReducer';

const ColorPicker = ({ control, path, name, setValue, errName }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const colors = useSelector(
    (state) => (path !== 'sell' ? state.search[13] : state.sell.filter((value) => value.name === name)[0]),
    shallowEqual
  );

  useEffect(() => {
    setValue(name, colors.defaultValue);
  }, [setValue, name, colors.defaultValue]);

  const handleSelect = (option) => {

    path !== 'sell' && dispatch(addColor(option, true));
  };
  const handleRemove = (option) => {

    path !== 'sell' && dispatch(removeColor(option, true));
  };

  const { options } = colors; //TODO separate interior and exterior colors
  function tagRender(props) {
    const color = props && props.label[0].props && props.label[0].props.color;
    const label = props && props.label[1] && props.label[1].label;
    return (
      props && (
        <Tag
          closable={false}
          defaultValue={label}
          value={label}
          style={{
            marginRight: 3,
            height: '20px',
            width: '20px',
            cursor: 'default',
            color: 'white',
            backgroundColor: color,
            backgroundImage: color === 'transparent' && `url(${gradient}) `,
            backgroundSize: 'cover',
            borderRadius: '50%',
            border: 'none',
          }}
        ></Tag>
      )
    );
  }

  return (
    <Controller
      as={
        <Select
          onSelect={handleSelect}
          onDeselect={handleRemove}
          mode={path !== 'sell' && 'tags'}
          className={path !== 'sell' ? style.colorPicker : style.sellColorPicker}
          style={{ border: errName && '1px solid red' }}
          popupClassName={style.dropDown}
          showArrow
          tagRender={tagRender}
          placeholder={
            path === 'sell'
              ? name === 'extColor'
                ? t('placeholder_ext_color')
                : t('placeholder_int_color')
              : t('color')
          }
        >
          {options.map((option) => (
            <Select.Option key={option.id} value={option.id.toString()}>
              <Tag
                key={option.id}
                color={option.color}
                style={{
                  marginRight: 5,
                  height: '20px',
                  width: '20px',
                  cursor: 'default',
                  background: option.color,
                  backgroundImage: option.color === 'transparent' && `url(${gradient}) `,
                  backgroundSize: 'cover',
                  borderRadius: '50%',
                }}
              ></Tag>
              {t(`${option.name}`)}
            </Select.Option>
          ))}
        </Select>
      }
      name={path === 'sell' ? `${name}` : 'color'}
      defaultValue={colors.defaultValue}
      control={control}
      rules={
        colors.isRequired && {
          required: true,
        }
      }
    />
  );
};

export default ColorPicker;
