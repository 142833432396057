import React from 'react';
import { useHistory } from 'react-router';
import Button from '../../components/Common/Buttons/Button';
import style from './style.module.scss';

const NotFound = () => {
  const history = useHistory();
  const goBack = () => {
    ////console.log('go back');
    history.goBack();
  };
  return (
    <div className={style.divContainer}>
      <div className={style.txtContainer}>
        <h1>"Sorry we can't find that page!"</h1>
        <h1>"Don't worry though, everything is STILL AWESOME!"</h1>
        <Button innerText={'Go Back'} handleClick={goBack} />
      </div>
      <div className={style.imgContainer}></div>
    </div>
  );
};

export default NotFound;
