import {Input, Select} from 'antd';
import React, {useEffect} from 'react';
import {Controller} from 'react-hook-form';
import style from './style.module.scss';

const {Option} = Select;

const SectionController = ({control, setValue, errors, clearErrors, details, setDetails, fields}) => {

  const handleSelect = (name, option) => {
    setValue(name, option);
    clearErrors(name);
    setDetails({...details, [name]: option})
  };
  useEffect(() => {
    if (details.year && details.month) {
      // calculate days in month
      const year = details.year;
      const month = details.month;
      const days = new Date(year, month, 0).getDate();
      const daysArray = [];
      let dateCheck = String(year) + String(month);
      for (let i = 1; i <= days; i++) {
        daysArray.push({
          value: i,
          name: i < 10 ? `0${i}` : i
        });
      }
      if (dateCheck !== details.dateCheck) {
        setDetails({...details, monthDays: daysArray, dateCheck})
      }
    }
    if (details.carScale !== 'truck' && details.tonnage > 0) {
      setDetails({...details, tonnage: 0})
    }
    if (details.carScale === 'truck' && details.tonnage <= 0) {
      setDetails({...details, tonnage: 5})
    }
    if (details.carScale !== 'sedan' && details.carType === true) {
      setDetails({...details, carType: false})
    }
    if (details.engine === 'electric' && details.engineVol > 0) {
      setDetails({...details, engineVol: 0})
    }
    if (details.engine === 'hybrid' && details.carType === true) {
      setDetails({...details, carType: false})
    }
    if (details.carScale !== 'quadBike' && details.hasR === true) {
      setDetails({...details, hasR: false})
    }
  }, [details, setDetails]);
  const handleInputChange = (name, value) => {
    setDetails({...details, [name]: value})
  }
  return (
    fields.map((input, index) => {
      if (input.hasOwnProperty('hide') && input.hide) return null;
      return (
        <Controller
          key={input.key + 'controller'}
          name={input.name}
          control={control}
          defaultValue={input.defaultValue !== null && input.defaultValue !== undefined ? input.defaultValue : null}
          rules={{required: input.required}}
          as={
            <div style={input.style} key={input.key + "div"}>
              {input.hasOwnProperty('label') && input.label !== '' &&
                <label key={input.key + 'label'} htmlFor={input.name}
                       style={{color: input.disabled ? 'gray' : null}}>
                  {input.label} {input.required &&
                  <span style={{color: 'red', fontSize: '22px'}}>*</span>}
                </label>
              }
              {input.hasOwnProperty('options') ? (
                <Select
                    popupClassName={style.dropDown}
                  defaultValue={input.defaultValue ? input.defaultValue : null}
                  onSelect={(option) => handleSelect(input.name, option)}
                  style={{border: errors.hasOwnProperty(`${input.name}`) ? '1px solid red' : ''}}
                  key={input.key + "select"}
                >
                  {input.options.map((option, i) => (
                    <Option key={input.key + i + 'option'}
                            value={input.name === 'year' ? option : option.value}
                            disabled={option.disabled}
                    >
                                            <span style={{color: option.disabled ? 'gray' : null}}>
                                            {input.name === 'year' ? option : option.name}
                                            </span>
                    </Option>
                  ))}
                </Select>
              ) : (
                <Input
                  key={input.key + "input"}
                  type='number'
                  name={input.name}
                  onInput={(event) => handleInputChange(input.name, event.target?.value)}
                  style={{border: input.required && errors.hasOwnProperty(`${input.name}`) ? '1px solid red' : ''}}
                  defaultValue={input.defaultValue !== null && input.defaultValue !== undefined ? input.defaultValue : null}
                  disabled={input.disabled}
                />
              )}
            </div>
          }
        />
      );
    })
  );
};

export default SectionController;
