import React from "react";
import Icon from "@ant-design/icons";

const CloseSvg = () => (
  <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.89579 8.26025L16.537 1.43355L15.1424 0L8.50098 6.82691L1.85829 0.000748827L0.463474 1.43409L7.10636 8.26046L0 15.5653L1.39461 16.9988L8.50118 9.6938L15.6082 16.9971L17.003 15.5638L9.89579 8.26025Z"
      fill="black"
    />
  </svg>
);

const CloseIcon = (props) => <Icon component={CloseSvg} {...props} />;

export default CloseIcon;
