import React, { useEffect } from 'react';
import { Select } from 'antd';
import style from './style.module.scss';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setSellModels, setSellTrim, saveSellData } from '../../../redux/Reducers/sellReducer';
import { useTranslation } from 'react-i18next';

const SellInput = React.forwardRef(({ name, inputs, belongsTo, errName, placeholder, onChange, setValue }, ref) => {
  //onChange comes from react-hook-form control
  const { Option } = Select;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const defaultValue = useSelector(
    (state) => state.sell.filter((value) => value.name === name)[0].defaultValue,
    shallowEqual
  );
  const options = useSelector((state) => state.sell.filter((value) => value.name === name)[0].options, shallowEqual);
  useEffect(() => {
    setValue(name, defaultValue);
  }, [setValue, name, defaultValue]);

  function handleChange(val) {
    onChange(val);
    if (name === 'make') {
      const makeId = { makeId: val };
      dispatch(setSellModels(makeId));
    }
    if (name === 'model') {
      const modelId = { modelId: val };
      dispatch(setSellTrim(modelId));
    }
    const data = [name, val];
    dispatch(saveSellData(data));
  }
  return (
    <Select
      showSearch
      disabled={
        (name === 'model' && inputs && !inputs[0].defaultValue) ||
        (name === 'engineCap' && inputs && !inputs[1].defaultValue)
          ? true
          : false
      }
      showArrow={
        (name === 'model' && inputs && !inputs[0].defaultValue) ||
        (name === 'engineCap' && inputs && !inputs[1].defaultValue)
          ? false
          : true
      }
      ref={ref}
      defaultValue={defaultValue}
      value={defaultValue}
      popupClassName={style.dropDown}
      className={errName ? style.selectInputEmpty : style.selectInput}
      style={{
        backgroundColor: belongsTo === 'bigForm' ? '#1E1E1E' : 'rgba(0,0,0,0.7)',
      }}
      placeholder={placeholder}
      optionFilterProp='children'
      onChange={handleChange}
      filterOption={(input, option) =>
        typeof option.children === 'string'
          ? option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          : option.children >= 0
      }
    >
      {options &&
        options.map((option, i) => {
          return (
            <Option
              key={i}
              value={
                name === 'make' ||
                name === 'model' ||
                name === 'engineCap' ||
                name === 'engine' ||
                name === 'gearBox' ||
                name === 'steeringWheel' ||
                name === 'country' ||
                name === 'currency' ||
                name === 'mileageType'
                  ? option.id
                  : option
              }
            >
              {name === 'make' ||
              name === 'model' ||
              name === 'engineCap' ||
              name === 'engine' ||
              name === 'gearBox' ||
              name === 'currency' ||
              name === 'mileageType'
                ? name === 'make' || name === 'model' || name === 'engineCap' || name === 'currency'
                  ? option.name
                  : t(`${name}_${option.name}`)
                : name === 'steeringWheel' || name === 'country'
                ? t(`${option.name}`)
                : t(`${option}`)}
            </Option>
          );
        })}
    </Select>
  );
});

export default SellInput;
