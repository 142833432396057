import React, { useEffect, useRef, useState } from "react";
import iaa from "../../../../../assets/Images/iaa.png";
import copart from "../../../../../assets/Images/copart.png";
import style from "./style.module.scss";
import AuctionImg from "./AuctionImg";

const Auctions = React.forwardRef(
  ({ handleAuctionSelect, locations, getValues, errors }, ref) => {
    const auctionRef = useRef(null);
    const [selectedAuction, setSelectedAuction] = useState(1);

    const auctions = [
      { id: 1, name: "IAA", img: iaa },
      { id: 2, name: "Copart", img: copart },
    ];

    const handleClick = (e, id) => {
      handleAuctionSelect(id);
      setSelectedAuction(id);
    };

    useEffect(() => {
      const auction = getValues().auction;
      if (auction === 1 && locations?.length > 0) {
        //const node = auctionRef.current && auctionRef.current.childNodes[0];
        //const pos = node && node.offsetLeft + node.offsetWidth / 2 - 9;
      }
    }, [locations?.length, getValues]);

    return (
      <div className={style.auctionsContainer}>
        <div className={style.picsContainer} ref={auctionRef}>
          {auctions.map((a) => {
            const isSelected = selectedAuction === a.id;
            const auctionStyle = isSelected
              ? `${style.AuctionImgBtnStyle} ${style.handleAuctionSelectStyle}`
              : style.AuctionImgBtnStyle;

            return (
              <AuctionImg
                key={a.id}
                img={a.img}
                name={a.name}
                id={a.id}
                handleClick={handleClick}
                style={auctionStyle}
              />
            );
          })}
        </div>
      </div>
    );
  }
);

export default Auctions;
