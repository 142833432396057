import { DatePicker} from 'antd';
import React, {useEffect, useCallback, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
//import SliderNextIcon from '../../../../components/Common/Icons/sliderNext';
import {
    setChart,
} from '../../../../redux/Reducers/dealerCarsReducer';
import style from './style.module.scss';
import {BarChart} from "@mui/x-charts";
import {useTranslation} from "react-i18next";

const Chart = () => {
    /* const history = useHistory();*/
    /*const [processingMarket, setProcessingMarket] = useState({});*/
    //
    const chart = useSelector(
        (state) => state.dealerCars.chart,
        shallowEqual,
    );
    const { t } = useTranslation();
    // const loading = useSelector(
    //   (state) => state.dealerCars.loading,
    //   shallowEqual,
    // );
    // const filters = useSelector(
    //   (state) => state.dealerCars.chartFilters,
    //   shallowEqual,
    // );
    const [searchItems, setSearchItems] = useState({
        year: new Date().getFullYear()
    });

   const dispatch = useDispatch();
    // const locationRegex = /\(.*\)/g;

    const yearSearch = (year) => {
        if(year) {
            if (searchItems.year === year) return;
            setSearchItems(prevState => {
                // Object.assign would also work
                prevState.year = year.year();
                return {...prevState};
            });
        }
    };

    useEffect(() => {
        setChart(searchItems.year)(dispatch);
    }, [dispatch, searchItems]);

    const [width, setWidth] = useState(500);
    const div = useCallback(node => {
        if (node !== null) {
          if(window.innerWidth - 20 < node.getBoundingClientRect().width) {
            setWidth(window.innerWidth - 20);
          } else {
            setWidth(node.getBoundingClientRect().width);
          }
        }
    }, []);

    return (
        <div className={style.wrapper}>
            <div className={style.dealerCarFilters}>
                <DatePicker onChange={yearSearch} picker="year"/>
            </div>
            {chart && Array.isArray(chart) &&
                <div className={style.chartView} ref={div}>
                    <div style={{color:'black'}}>{t('year')}: {searchItems.year}</div>
                    <BarChart
                        xAxis={[
                            {
                                id: 'barCategories',
                                data: [t('jan'), t('feb'), t('mar'), t('apr'), t('may'), t('jun'), t('jul'), t('aug'), t('sep'), t('oct'), t('nov'), t('dec')],
                                scaleType: 'band',
                            },
                        ]}
                        series={chart}
                        width={width}
                        height={(width * (9/16) * 70 /100)}
                    />
                </div>
            }
        </div>
    );
};

export default Chart;
