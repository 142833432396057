import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { addImages } from '../../../redux/Reducers/sellReducer';
import PlusIcon from '../Icons/plus';
import UploadMobileIcon from '../Icons/uploadMobile';
import style from './style.module.scss';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

const UploadBtn = React.forwardRef(({ innerText, setPopState }, ref) => {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const images = useSelector((state) => state.sell[16], shallowEqual);

  const handleChange = (e) => {
    const fileArray = Array.from(e.target.files);
    const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
    fileArray &&
      fileArray.forEach((file) => {
        if (allowedTypes.includes(file.type)) {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onloadend = () => {
            let file64 = reader.result;
            !images.defaultValue.includes(file64) && dispatch(addImages(file64));
            setPopState({ visible: false, description: '' });
          };
        } else {
          setPopState({ visible: true, description: 'File not supported' });
        }
      });
  };

  return (
    <React.Fragment>
      <label className={style.btnUpload} htmlFor='upload'>
        <span>{width > 480 ? <PlusIcon /> : <UploadMobileIcon />}</span>
        <p>{innerText}</p>
      </label>
      <input type='file' id='upload' hidden multiple onChange={handleChange} onClick={(e) => (e.target.value = null)} />
    </React.Fragment>
  );
});

export default UploadBtn;
