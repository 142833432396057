import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setFoundCarsByPage } from "../../redux/Reducers/carsReducer";
import Calculator from "../Calculator/Calculator";
import style from "./style.module.scss";
import HomeSection from "./Home-section";
import Carousel from "./Home_carousel";
import HomeAboutUs from "./Home_about_us";
import HomeService from "./Home_service";
import HomeLogistic from "./Home_logistics";
const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setFoundCarsByPage({}, null, () => {}, 0));
  }, []);
  return (
    <div className={style.mainContainer}>
      <HomeSection />
      <Carousel />
      <HomeAboutUs />
      <HomeLogistic />
      <HomeService />
      <Calculator />
    </div>
  );
};

export default Home;
