import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { activateByEmail } from '../../api/requests';
import Loader from '../Common/Loader/Loader';

const Activate = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const info = useSelector((state) => state.user.info, shallowEqual);

  useEffect(() => {
    const code = { activationCode: props.match.params.code };

    if (info && info.emailConfirmed === 0) {
      activateByEmail(code).then((res) => {
        if (res.status === 200) {
          const { message, ...data } = res.data;

          dispatch({ type: 'SET_EMAIL_CONFIRMED', data });
          history.push('/profile');
        } else {

          history.push('/');
        }
      });
    }
    ////console.log('no request was sent');
    history.push('/');
  }, [dispatch, history, info, props.match.params.code]);

  return <Loader isVisible={true} />;
};

export default Activate;
