import React, { useState } from "react";
import UpIcon from "../../../components/Common/Icons/arrowUp";
import style from "./style.module.scss";
import RelatedCars from "./RelatedCars/RelatedCars";
import { useTranslation } from "react-i18next";

const SecondSection = () => {
  const [state, setState] = useState("left");
  const { t } = useTranslation();
  return (
    <div className={style.secondSection}>
      <div className={style.titles}>
        <h1 onClick={() => setState("left")}>
           {t('other_announcement')}
        </h1>
      </div>
      <div className={style.arrowWrapper}>
        <UpIcon style={{ marginLeft: state === "left" ? "-20%" : "27%" }} />
      </div>
      <div className={style.carsWrapper}>
        <RelatedCars />
      </div>
    </div>
  );
};

export default SecondSection;
