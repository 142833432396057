import React from 'react';
import Icon from '@ant-design/icons';

const CalcArrowSvg = () => (
  <svg width='45' height='8' viewBox='0 0 45 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M0.646446 3.64644C0.451183 3.8417 0.451183 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.73079 4.34027 7.73079 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82842L1.70711 4L4.53553 1.17157C4.7308 0.976307 4.7308 0.659725 4.53553 0.464463C4.34027 0.2692 4.02369 0.2692 3.82843 0.464462L0.646446 3.64644ZM45 3.5L1 3.5L1 4.5L45 4.5L45 3.5Z'
      fill='white'
    />
  </svg>
);

const CalcArrow = (props) => <Icon component={CalcArrowSvg} {...props} />;

export default CalcArrow;
