import React, {useEffect} from 'react';

const Map = () => {
  useEffect(() => {
    // window.initMap();
  }, []);

  return (<div></div>);
};

export default Map;
