import {Alert, Input, Popover} from 'antd';
import React, {useEffect, useState} from 'react';
import {Controller} from 'react-hook-form';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {editUser} from '../../../../redux/Reducers/userReducer';
import Button from '../../Buttons/Button';
import DeleteSection from './DeleteSection';
import PhoneWrapper from './PhoneWrapper/PhoneWrapper';
import style from './style.module.scss';
import {useTranslation} from 'react-i18next';

const SettingsForm = ({handleSubmit, control, reset}) => {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();

  // take user info from redux state
  const user = useSelector(
    (state) => (state.user.info ? state.user : {info: {userDetails: {}, mobile: ''}}),
    shallowEqual
  );
  const [alertState, setAlertState] = useState({visible: false, description: '', type: ''}); //alert state
  const {email, passwordResetCode} = user.info;
  const mobile = user.info && user.info.mobile ? user.info.mobile.substring(4) : '';
  const name = user.info.userDetails ? user.info.userDetails.name : '';
  const surname = user.info.userDetails ? user.info.userDetails.surname : '';
  const [numberInputs, setNumberInputs] = useState([{defaultValue: mobile}]);

  //inputs to map over
  const inputs = [
    {name: 'Name', type: 'text', default: name, placeholder: t('placeholder_name')},
    {name: 'Surname', type: 'text', default: surname, placeholder: t('surname')},
    {name: 'Email', type: 'email', default: email, placeholder: t('email_btn')},
  ];
  const passwords = [
    {name: 'Password', type: 'password', required: true, placeholder: t('current_pass'), default: passwordResetCode},
    {name: 'PasswordCheck1', type: 'password', required: false, placeholder: t('new_pass'), default: null},
    {name: 'PasswordCheck2', type: 'password', required: false, placeholder: t('new_pass'), default: null},
  ];

  useEffect(() => {
    let timer = setTimeout(() => {
      alertState.visible && setAlertState({...alertState, visible: false});
    }, 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [alertState]);

  const onSubmit = (data) => {
    const formData = {
      ...data,
      Phone0:
        data.Phone0.length > 4 ? (data.Phone0.length === 9 ? `+374${data.Phone0.slice(1)}` : `+374${data.Phone0}`) : '',
    };

    if (data.Password || data.PasswordCheck1 || data.PasswordCheck2) {
      if (data.PasswordCheck1 !== data.PasswordCheck2) {
        setAlertState({description: `${t('pass_dont_match')}`, type: 'error', visible: true});
      } else if (!data.Password) {
        setAlertState({description: `${t('enter_odl_pass')}`, type: 'error', visible: true});
      } else {
        dispatch(editUser(formData, setAlertState, reset, i18n.language));
      }
    } else dispatch(editUser(formData, setAlertState, reset, i18n.language));
  };

  const addNumberInput = () => {
    setNumberInputs([...numberInputs, {placeholder: 'mobile', defaultValue: null}]);
  };

  return (
    <React.Fragment>
      {alertState.visible && (
        <Popover
          open={alertState.visible}
          overlayClassName={style.popover}
          content={
            <Alert
              showIcon
              type={alertState.type}
              description={alertState.description}
              closable
              className={style.alert}
              onClose={() => setAlertState({visible: false, description: ''})}
            />
          }
        ></Popover>
      )}
      <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
        {inputs.map((input, index) => {
          return (
            <Controller
              key={index}
              as={<Input type={input.type} placeholder={input.placeholder}/>}
              control={control}
              name={input.name}
              defaultValue={input.default}
            />
          );
        })}

        {numberInputs.map((input, i) => (
          <Controller
            as={<PhoneWrapper value={input.defaultValue}/>}
            key={i}
            name={`Phone${i}`}
            control={control}
            defaultValue={input.defaultValue}
          />
        ))}
        <span onClick={addNumberInput} style={{visibility: 'hidden'}}>
          + {t('add_num')}
        </span>
        <label htmlFor=''>{t('change_pass')}</label>
        <hr/>
        {passwords.map((password, index) => {
          return (
            <Controller
              as={
                <Input
                  type={password.type}
                  placeholder={password.placeholder}
                  defaultValue={password.default}
                  required={password.required}
                />
              }
              control={control}
              name={password.name}
              key={index}
              defaultValue={password.default}
            />
          );
        })}
        <Button innerText={t('save_changes')} type='submit'/>
        <DeleteSection control={control}/>
      </form>
    </React.Fragment>
  );
};

export default SettingsForm;
