import React from "react";
import "./style.scss";
import HotOfferItem from "./HotOfferItem";

const HotOffers = ({ offers, className, label }) => {
  return (
    <div className={`hot-offers ${className}`}>
      <h2>{label}</h2>
      <div className="hot-offer-items">
        {offers.map((offer, index) => (
          <HotOfferItem key={index} item={offer} />
        ))}
      </div>
    </div>
  );
};

export default HotOffers;
