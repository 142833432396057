import React from 'react';
import style from './style.module.scss';

import { Link } from 'react-router-dom';
import Button from '../Buttons/Button';
import { useTranslation } from 'react-i18next'


const AnnouncementCard = ({ homepage }) => {
  const { t } = useTranslation();
  return (
    <div className={style.cardContainer}>
      <div className={style.innerContainer}>
        <div className={style.imgContainer}></div>
        <div className={style.info}></div>
        <Link to={{ pathname: '/sell/0', state: {} }} id={style.annId}>
          <Button innerText={t("add_announcement")} />
        </Link>
      </div>
    </div>
  );
};

export default AnnouncementCard;
