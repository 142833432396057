import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getUserInfo } from '../../api/requests';
import { updateUserServer } from '../../redux/Reducers/userReducer';
import styles from './styles.module.scss';

const LiveAuction = () => {
  const { info } = useSelector((state) => state.user, shallowEqual);
  const dispatch = useDispatch();
  useEffect(() => {
    getUserInfo().then((res) => {
      console.log(res);
      const { auctionServer } = res.data;
      console.log(auctionServer);
      res.status === 200 && dispatch(updateUserServer(auctionServer));
    });
  }, [dispatch]);

  return (
    <div className={styles.pageContainer}>
      <div className={styles.iframeContainer}>
        <iframe
          title='live_auction'
          src={info && info.auctionServer && info.auctionServer}
          frameBorder='0'
          sandbox={'allow-same-origin allow-scripts'}
          allow='fullscreen'
          allowfullscreen
        ></iframe>
      </div>
    </div>
  );
};

export default LiveAuction;
