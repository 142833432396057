import { InputNumber } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { saveSellData } from '../../../../../redux/Reducers/sellReducer';

const InputWrapper = React.forwardRef(({ name, errors, defaultValue, setValue, onChange }, ref) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    setValue(name, defaultValue);
  }, [setValue, name, defaultValue]);

  const handleChange = (val) => {
    const data = [name, val];
    dispatch(saveSellData(data));
    onChange(val);
  };

  return (
    <InputNumber
      type='number'
      ref={ref}
      value={defaultValue}
      placeholder={t(`placeholder_${name}`)}
      onChange={handleChange}
      style={{ border: errors[name] && '1px solid red' }}
    />
  );
});

export default InputWrapper;
