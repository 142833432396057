import React from 'react';
import { useDispatch } from 'react-redux';
import CloseIcon from '../../../../../components/Common/Icons/close';
import UndoIcon from '../../../../../components/Common/Icons/undoIcon';
import { addRemovedId, deleteImage, deleteRemovedId } from '../../../../../redux/Reducers/sellReducer';
import style from '../../style.module.scss';
const UploadedImage = ({ image, images, id }) => {
  const dispatch = useDispatch();
  // //console.log(id);
  // //console.log(images);
  const removeImage = () => {
    !id ? dispatch(deleteImage(image)) : dispatch(addRemovedId(id));
  };
  const restoreImage = () => {
    dispatch(deleteRemovedId(id));
  };
  return (
    <div className={images.deletedImages.includes(id) ? style.deleted : null}>
      {!images.deletedImages.includes(id) ? (
        <CloseIcon onClick={removeImage} />
      ) : (
        <div className={style.undoWrapper}>
          <h3>Deleted</h3>
          <UndoIcon onClick={restoreImage} />
          <h3>Undo</h3>
        </div>
      )}
      <img src={image} alt='' />
    </div>
  );
};

export default UploadedImage;
