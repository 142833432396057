import suv from './Images/suv.png';
import sedan from './Images/sedan.png';
import truck from './Images/truck.png';
import minivan from './Images/minivan.png';
import van from './Images/van.png';
import coupe from './Images/coupe.png';
import hatchback from './Images/hatchback.png';

const Cars = [
  { img: suv, index: 1 },
  { img: sedan, index: 2 },
  { img: truck, index: 3 },
  { img: minivan, index: 4 },
  { img: van, index: 5 },
  { img: coupe, index: 6 },
  { img: hatchback, index: 7 },
];

export default Cars;
