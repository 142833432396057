import React from 'react';
import style from '../../style.module.scss';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { changeBodyType } from '../../../../../redux/Reducers/searchReducer';
import { changeSellBodyType } from '../../../../../redux/Reducers/sellReducer';

const BodyType = ({ img, path, innerText, index }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedTypes = useSelector(
    (state) => (path !== 'sell' ? state.search[0].defaultValue : state.sell[0].defaultValue),
    shallowEqual
  );
  const handleChange = () => {
    const pushStatus = path !== '' ? true : false;
    path !== 'sell' ? dispatch(changeBodyType(index.toString(), pushStatus)) : dispatch(changeSellBodyType(index));
  };
  return (
    <div>
      <input
        readOnly
        required={path === 'sell' ? true : false}
        name='bodyType'
        defaultValue={selectedTypes}
        className={
          path !== 'sell'
            ? selectedTypes.includes(index.toString())
              ? style.selected
              : null
            : selectedTypes === index
            ? style.selected
            : null
        }
        style={{ backgroundImage: `url(${img})` }}
        onClick={handleChange}
      />
      <p>{t(`bodyType_${innerText}`)}</p>
    </div>
  );
};

export default BodyType;
