import { getCarDetails, getModels, getOptions, getTrim } from '../../api/requests';
import years from '../../assets/years';

const initialState = [
  { name: 'bodyType', options: [], defaultValue: 0, isRequired: true },
  {
    name: 'make',
    placeholder: 'Choose Make',
    options: [],
    defaultValue: null,
    isRequired: true,
  },
  {
    name: 'model',
    placeholder: 'Choose Model',
    options: [],
    defaultValue: null,
    isRequired: true,
  },
  { name: 'engineCap', placeholder: 'Engine Capacity', options: [], defaultValue: null, isRequired: true },

  {
    name: 'year',
    placeholder: 'Year',
    options: years,
    defaultValue: null,
    isRequired: true,
  },
  {
    name: 'price',
    placeholder: 'Price',
    defaultValue: null,
    isRequired: true,
    type: 'input',
  },
  {
    name: 'currency',
    options: [
      { id: 0, name: 'USD' },
      { id: 1, name: 'AMD' },
      { id: 2, name: 'RUB' },
    ],
    defaultValue: 0,
  },
  { name: 'mileage', placeholder: 'Mileage', defaultValue: null, isRequired: true, type: 'input' },
  {
    name: 'mileageType',
    options: [
      { id: 0, name: 'Km' },
      { id: 1, name: 'Miles' },
    ],
    defaultValue: 0,
  },
  { name: 'engine', placeholder: 'Engine', options: ['Gasoline', 'Diesel'], defaultValue: null, isRequired: true },
  { name: 'gearBox', placeholder: 'Gear Box', options: ['Automatic', 'Manual'], defaultValue: null, isRequired: true },
  {
    name: 'steeringWheel',
    placeholder: 'Steering Wheel',
    options: [
      { id: 0, name: 'Left' },
      { id: 1, name: 'Right' },
    ],
    defaultValue: null,
    isRequired: true,
  },
  {
    name: 'country',
    placeholder: 'Country',
    options: [
      { id: 0, name: 'USA' },
      { id: 1, name: 'Japan' },
      { id: 2, name: 'Korea' },
      { id: 3, name: 'Europe' },
      { id: 4, name: 'Dubai' },
      { id: 5, name: 'Russia' },
      { id: 6, name: 'Armenia' },
    ],
    defaultValue: null,
    isRequired: true,
  },
  { name: 'extColor', placeholder: 'Color', options: [], defaultValue: null, isRequired: true },
  { name: 'intColor', placeholder: 'Color', options: [], defaultValue: null, isRequired: true },
  { name: 'customText', defaultValue: '' },
  { name: 'images', deletedImages: [], defaultValue: [] },
  { name: 'partSelling', defaultValue: 0 },
  { name: 'exchange', defaultValue: 0 },
  { name: 'cleanCar', defaultValue: 1 },
  { name: 'mobile', defaultValue: '', isRequired: true },
];

const sellReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SELL_OPTIONS': {
      return state.map((obj) => {
        if (obj.name === 'bodyType') {
          return { ...obj, options: action.data.bodyType };
        }
        if (obj.name === 'make') {
          return { ...obj, options: action.data.make };
        }
        if (obj.name === 'engine') {
          return { ...obj, options: action.data.engine };
        }
        if (obj.name === 'gearBox') {
          return { ...obj, options: action.data.gearbox };
        }
        if (obj.name === 'extColor' || obj.name === 'intColor') {
          return { ...obj, options: action.data.color };
        } else {
          return obj;
        }
      });
    }
    case 'SET_SELL_MODELS': {
      return state.map((obj) => {
        if (obj.name === 'model') {
          return { ...obj, options: action.data, defaultValue: null };
        }
        if (obj.name === 'engineCap') {
          return { ...obj, defaultValue: null };
        } else {
          return obj;
        }
      });
    }
    case 'SET_SELL_TRIM': {
      return state.map((obj, i) => {
        if (obj.name === 'engineCap') {
          return { ...obj, options: action.data, defaultValue: null };
        } else {
          return obj;
        }
      });
    }
    case 'SAVE_SELL_DATA': {
      return state.map((value) => {
        if (value.name === action.data[0]) {
          return { ...value, defaultValue: action.data[1] };
        }
        return value;
      });
    }
    case 'CHANGE_SELL_BODY': {
      return state.map((value) => {
        if (value.name === 'bodyType') {
          return {
            ...value,
            defaultValue: value.defaultValue !== action.id ? action.id : 0,
          };
        }
        return value;
      });
    }
    case 'ADD_IMAGE': {
      return state.map((value) => {
        if (value.name === 'images') {
          ////console.log(value.defaultValue);

          return { ...value, defaultValue: [...value.defaultValue, action.file64] };
        }
        return value;
      });
    }
    case 'REMOVE_IMAGE': {
      return state.map((value) => {
        if (value.name === 'images') {
          return { ...value, defaultValue: value.defaultValue.filter((value) => value !== action.image) };
        }
        return value;
      });
    }
    case 'ADD_REMOVED_ID': {
      return state.map((value) => {
        if (value.name === 'images') {
          return {
            ...value,
            deletedImages: !value.deletedImages.includes(action.mediaId)
              ? [...value.deletedImages, action.mediaId]
              : [...value.deletedImages],
          };
        }
        return value;
      });
    }
    case 'DELETE_REMOVED_ID': {
      return state.map((value) => {
        if (value.name === 'images') {
          return { ...value, deletedImages: value.deletedImages.filter((value) => value !== action.mediaId) };
        }
        return value;
      });
    }
    case 'EDIT_CAR': {
      return state.map((value) => {
        if (value.name === 'bodyType') {
          return { ...value, defaultValue: action.carDetails.bodyType };
        }
        if (value.name === 'make') {
          return { ...value, defaultValue: action.carDetails.make };
        }
        if (value.name === 'model') {
          return { ...value, defaultValue: action.carDetails.model };
        }
        if (value.name === 'engineCap') {
          return { ...value, defaultValue: action.carDetails.trim };
        }
        if (value.name === 'year') {
          return { ...value, defaultValue: action.carDetails.year };
        }
        if (value.name === 'price') {
          return { ...value, defaultValue: action.carDetails.price };
        }
        if (value.name === 'currency') {
          return { ...value, defaultValue: action.carDetails.currency };
        }
        if (value.name === 'mileage') {
          return { ...value, defaultValue: action.carDetails.mileage };
        }
        if (value.name === 'mileageType') {
          return { ...value, defaultValue: action.carDetails.mileageType };
        }
        if (value.name === 'engine') {
          return { ...value, defaultValue: action.carDetails.engine };
        }
        if (value.name === 'gearBox') {
          return { ...value, defaultValue: action.carDetails.gearBox };
        }
        if (value.name === 'steeringWheel') {
          return { ...value, defaultValue: action.carDetails.steeringWheel };
        }
        if (value.name === 'country') {
          return { ...value, defaultValue: action.carDetails.country };
        }
        if (value.name === 'extColor') {
          return { ...value, defaultValue: action.carDetails.carExtColor.id.toString() };
        }
        if (value.name === 'intColor') {
          return { ...value, defaultValue: action.carDetails.carIntColor.id.toString() };
        }
        if (value.name === 'customText') {
          return { ...value, defaultValue: action.carDetails.customText };
        }
        if (value.name === 'images') {
          return { ...value, defaultValue: [...action.carDetails.carMedia] };
        }
        if (value.name === 'partSelling') {
          return { ...value, defaultValue: Boolean(action.carDetails.partSelling) };
        }
        if (value.name === 'exchange') {
          return { ...value, defaultValue: Boolean(action.carDetails.exchange) };
        }
        if (value.name === 'cleanCar') {
          return { ...value, defaultValue: Boolean(action.carDetails.cleanCar) };
        }
        if (value.name === 'mobile') {
          return { ...value, defaultValue: action.carDetails.mobile };
        }
        return value;
      });
    }
    case 'SET_QUESTION_VALUE': {
      return state.map((value) => {
        if (value.name === action.value.name) {
          return { ...value, defaultValue: !value.defaultValue };
        }
        return value;
      });
    }
    case 'RESTORE_DEFAULT': {
      return initialState;
    }
    default:
      return state;
  }
};

//action
export const saveSellData = (data) => {
  return (dispatch) => dispatch({ type: 'SAVE_SELL_DATA', data });
};

export const setSellOptions = () => {
  return (dispatch) =>
    getOptions().then((res) => {
      if (res.status === 200) {
        const data = res.data;
        dispatch({ type: 'SET_SELL_OPTIONS', data });
      }
    });
};
export const setSellModels = (makeId) => {
  return (dispatch) =>
    getModels(makeId).then((res) => {
      if (res.status === 200) {
        const data = res.data;
        ////console.log(data);
        dispatch({ type: 'SET_SELL_MODELS', data });
      }
    });
};
export const setSellTrim = (modelId) => {
  return (dispatch) =>
    getTrim(modelId).then((res) => {
      if (res.status === 200) {
        const data = res.data;
        ////console.log(data);
        dispatch({ type: 'SET_SELL_TRIM', data });
      }
    });
};
export const changeSellBodyType = (id) => {
  return (dispatch) => {
    dispatch({ type: 'CHANGE_SELL_BODY', id });
  };
};

export const addImages = (file64) => {
  return (dispatch) => {
    dispatch({ type: 'ADD_IMAGE', file64 });
  };
};

export const deleteImage = (image) => {
  return (dispatch) => {
    dispatch({ type: 'REMOVE_IMAGE', image });
  };
};

export const addRemovedId = (mediaId) => {
  return (dispatch) => {
    dispatch({ type: 'ADD_REMOVED_ID', mediaId });
  };
};

export const deleteRemovedId = (mediaId) => {
  return (dispatch) => {
    dispatch({ type: 'DELETE_REMOVED_ID', mediaId });
  };
};

export const editCar = (id, make, model) => {
  return async (dispatch) => {
    const makeId = { makeId: make };
    const modelId = { modelId: model };
    await getModels(makeId).then((res) => {
      if (res.status === 200) {
        const data = res.data;
        dispatch({ type: 'SET_SELL_MODELS', data });
      }
    });
    await getTrim(modelId).then((res) => {
      if (res.status === 200) {
        const data = res.data;
        dispatch({ type: 'SET_SELL_TRIM', data });
      }
    });
    // get car details from server
    await getCarDetails(id).then((res) => {
      if (res.status === 200) {
        const carDetails = res.data.data;
        ////console.log(carDetails);
        dispatch({ type: 'EDIT_CAR', carDetails });
      }
    });
  };
};

export const setQuestionValue = (value) => {
  return (dispatch) => {
    dispatch({ type: 'SET_QUESTION_VALUE', value });
  };
};

export default sellReducer;
