import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import style from '../style.module.scss';
import { useTranslation } from 'react-i18next';
import { Radio, Row, Col, Divider } from 'antd';
import PriceContainer from './PriceContainer/PriceContainer';

const CarInfo = () => {
  const locationRegex = /\(.*\)/g;
  const { t } = useTranslation();
  const dealerCarDetails = useSelector(
    (state) => state.dealerCars.dealerCarDetails,
    shallowEqual,
  );
  const {
    vin,
    lot,
    buy_day,
    load_day,
    buy_source,
    buy_location,
    carMake,
    carModel,
    year,
    purpose,
    shipping_line,
    booking_number,
    container_number,
    trucking_url,
    final_destination,
    sub_lot,
  } = dealerCarDetails;

  return (
    <div className={style.carInfoContainer}>
      <div>
        <div style={{ padding: '10px', textAlign: 'left' }}>
          <Divider
            orientation="left"
            style={{ color: '#156dc6', borderColor: '#156dc6' }}
          >
            {t('carInfo')}
          </Divider>
          <Row>
            <Col span={8} style={{ color: '#156dc6' }}>
              <strong>{t('year')}</strong>
            </Col>
            <Col span={16}>{year ? year : '-'}</Col>
          </Row>
          <Row>
            <Col span={8} style={{ color: '#156dc6' }}>
              <strong>{t('make')}</strong>
            </Col>
            <Col span={16}>{carMake && carMake.name ? carMake.name : '-'}</Col>
          </Row>
          <Row>
            <Col span={8} style={{ color: '#156dc6' }}>
              <strong>{t('model')}</strong>
            </Col>
            <Col span={16}>
              {carModel && carModel.name ? carModel.name : '-'}
            </Col>
          </Row>
          <Row>
            <Col span={8} style={{ color: '#156dc6' }}>
              <strong>{t('vin')}</strong>
            </Col>
            <Col span={16}>{vin ? vin : '-'}</Col>
          </Row>
          <Divider
            orientation="left"
            style={{ color: '#156dc6', borderColor: '#156dc6' }}
          >
            {t('auctionInfo')}
          </Divider>
          <Row>
            <Col span={8} style={{ color: '#156dc6' }}>
              <strong>{t('bought_source')}</strong>
            </Col>
            <Col span={16}>{buy_source ? buy_source : '-'}</Col>
          </Row>
          <Row>
            <Col span={8} style={{ color: '#156dc6' }}>
              <strong>{t('lot')}</strong>
            </Col>
            <Col span={16}>{lot ? lot : '-'}</Col>
          </Row>
          <Row>
            <Col span={8} style={{ color: '#156dc6' }}>
              <strong>{t('bought_day')}</strong>
            </Col>
            <Col span={16}>{buy_day ? buy_day : '-'}</Col>
          </Row>
          <Row>
            <Col span={8} style={{ color: '#156dc6' }}>
              <strong>{t('bought_location')}</strong>
            </Col>
            <Col span={16}>
              {buy_location
                ? buy_location.replace(locationRegex, '') +
                  (sub_lot ? '-sublot' : '')
                : ''}
            </Col>
          </Row>
          <Divider
            orientation="left"
            style={{ color: '#156dc6', borderColor: '#156dc6' }}
          >
            {t('deliveryInfo')}
          </Divider>
          <Row>
            <Col span={8} style={{ color: '#156dc6' }}>
              <strong>{t('load_day')}</strong>
            </Col>
            <Col span={16}>{load_day ? load_day : '-'}</Col>
          </Row>
          <Row>
            <Col span={8} style={{ color: '#156dc6' }}>
              <strong>{t('shipping_line')}</strong>
            </Col>
            <Col span={16}>
              {shipping_line ? (
                <b>
                  <a href={trucking_url} target="_blank" rel="noreferrer">
                    {shipping_line}
                  </a>
                </b>
              ) : (
                '-'
              )}
            </Col>
          </Row>
          <Row>
            <Col span={8} style={{ color: '#156dc6' }}>
              <strong>{t('booking_number')}</strong>
            </Col>
            <Col span={16}>{booking_number ? booking_number : '-'}</Col>
          </Row>
          <Row>
            <Col span={8} style={{ color: '#156dc6' }}>
              <strong>{t('container_number')}</strong>
            </Col>
            <Col span={16}>{container_number ? container_number : '-'}</Col>
          </Row>
          <Row>
            <Col span={8} style={{ color: '#156dc6' }}>
              <strong>{t('final_destination')}</strong>
            </Col>
            <Col span={16}>{final_destination ? final_destination : '-'}</Col>
          </Row>
        </div>
        <div className={style.row_2}>
          <div className={style.purpose}>
            <p>{t('car_bought_for')}</p>
            <Radio.Group value={purpose}>
              <Radio value={0}>{t('cut')}</Radio>
              <Radio value={1}>{t('sell')}</Radio>
              <Radio value={2}>{t('parts')}</Radio>
            </Radio.Group>
          </div>
          <hr />
          <PriceContainer />
        </div>
      </div>
    </div>
  );
};

export default CarInfo;
