import { Collapse } from 'antd';
import React from 'react';
import style from '../../style.module.scss';
import Debt from './Debt/Debt';
import Expenses from './Expenses/Expenses';
import PaidExpenses from './PaidExpenses/PaidExpenses';
import PriceLabels from './PriceLabels/PriceLabels';
import { useTranslation } from 'react-i18next';

const PriceContainer = () => {
  const { t } = useTranslation();
  const { Panel } = Collapse;

  return (
    <div className={style.price}>
      <Collapse accordion={true}>
        <Panel header={t('expenses')}>
          <PriceLabels />
          <Expenses />
          <PaidExpenses />
          <Debt />
        </Panel>
      </Collapse>
    </div>
  );
};

export default PriceContainer;
