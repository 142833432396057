import React from 'react';
import { useForm } from 'react-hook-form';
import SettingsForm from '../../../../components/Common/Forms/SettingsForm/SettingsForm';
import style from '../../style.module.scss';

const SettingsTab = () => {
  const { handleSubmit, control, reset } = useForm();
  return (
    <div className={style.settings}>
      <SettingsForm handleSubmit={handleSubmit} control={control} reset={reset} />
    </div>
  );
};

export default SettingsTab;
