import React, { useEffect, useState } from 'react';
import style from './style.module.scss';
import FirstSection from './FirstSection/FirstSection';
import { useDispatch } from 'react-redux';
import Loader from '../../components/Common/Loader/Loader';
import { setDealerCarDetails } from '../../redux/Reducers/dealerCarsReducer';

const DealerCar = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const { id } = props.match.params;
  const dispatch = useDispatch();

  //get dealer car details from back
  useEffect(() => {
    dispatch(setDealerCarDetails(id, setIsVisible));
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [dispatch, id]);

  return (
    <div className={style.pageContainer}>
      <Loader isVisible={isVisible} />
      <FirstSection />
    </div>
  );
};

export default DealerCar;
