import { Radio } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setQuestionValue } from '../../../../../redux/Reducers/sellReducer';

const RadioWrapper = React.forwardRef(({ defaultValue, name }, ref) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <Radio.Group
      value={Boolean(defaultValue)}
      onChange={e => {
        const value = { name, value: +e.target.value };
        dispatch(setQuestionValue(value));
      }}>
      <Radio value={true}>{t('yes')}</Radio>
      <Radio value={false}>{t('no')}</Radio>
    </Radio.Group>
  );
});

export default RadioWrapper;
