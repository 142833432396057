import React from "react";
import { useState } from "react";
import CalculatorContainer from "./CalculatorContainer/CalculatorContainer";
import IconContainer from "./IconContainer/IconContainer";
import ResultsContainer from "./ResultsContainer/ResultsContainer";
import style from "./style.module.scss";

const Calculator = () => {
  const [prices, setPrices] = useState({
    fob: null,
    transport: null,
    ready: false,
    transportNotes: null,
  });
  const [details, setDetails] = useState({
    year: null,
    month: null,
    day: null,
    location: null,
    engine: "gasoline",
    engineVol: null,
    auction: null,
    carType: null,
    isJuridical: 1,
    monthDays: [],
    dateCheck: null,
    carScale: "sedan",
    nash: false,
    hasR: false,
    tonnage: 0,
  });
  return (
    <>
      <div
        className={
          !prices.ready
            ? style.pageContainer
            : `${style.pageContainer} ${style.showResults}`
        }>
        <IconContainer prices={prices} setPrices={setPrices} />
        <CalculatorContainer
          prices={prices}
          setPrices={setPrices}
          details={details}
          setDetails={setDetails}
        />
        {prices.ready &&
         <ResultsContainer prices={prices} details={details} />}
      </div>
    </>
  );
};

export default Calculator;
