import { Alert, Popover } from 'antd';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { shallowEqual, useSelector } from 'react-redux';
import UploadBtn from '../../../../components/Common/Buttons/UploadBtn';
// import Loader from '../../../../components/Common/Loader/Loader';
import style from '../style.module.scss';
import UploadedImage from './UploadedImage/UploadedImage';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';

const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:4000';

const UploadSection = ({ control, setValue, errors, id }) => {
  //id receives from formBlock which is urls id
  const images = useSelector((state) => state.sell[16], shallowEqual);
  const [popState, setPopState] = useState({ visible: false, description: '' });
  const { width } = useWindowDimensions();
  const { t } = useTranslation();

  useEffect(() => {
    let carId = id;
    carId === 0
      ? setValue('images', images.defaultValue)
      : setValue('images', {
          newImages: images.defaultValue.filter((value) => typeof value === 'string'),
          deletedImages: images.deletedImages,
        });
  }, [id, images, setValue]);

  return (
    <React.Fragment>
      <Popover
        placement={'topLeft'}
        align={'left'}
        open={popState.visible}
        overlayClassName={style.popover}
        content={<Alert type='error' description={popState.description} showIcon className={style.alert} />}
      >
        <div
          className={style.uploadContainer}
          style={{ border: Object.keys(errors).includes('images') ? '1px solid red' : null }}
        >
          <h1>
            {t('upload_text')}
            <span className={style.asterisk}>*</span>
          </h1>
          <Controller
            as={<UploadBtn innerText={t('add_photo')} setPopState={setPopState} />}
            control={control}
            name={'images'}
            defaultValue={images}
            rules={{
              validate: (value) => {
                if (id === 0) {
                  if (value.length < 8 ) {
                    setPopState({ visible: true, description: `${t('upload_text')}` });
                    return false;
                  }
                } else {
                  if (
                    images.defaultValue.length - value.deletedImages.length < 8
                  ) {
                    setPopState({ visible: true, description: `${t('upload_text')}` });
                    return false;
                  }
                }
                return true;
              },
            }}
          />

          {width <= 480 ? (
            <section>
              {images.defaultValue.map((image, i) => {
                let media;
                image.id
                  ? (media = BASE_URL + image.carImage.location + '/' + image.carImage.name + 'small.jpeg')
                  : (media = image);
                return <UploadedImage key={i} image={media} images={images} id={image.id} />;
              })}
            </section>
          ) : (
            images.defaultValue.map((image, i) => {
              // //console.log(image.id);
              let media;
              image.id
                ? (media = BASE_URL + image.carImage.location + '/' + image.carImage.name + 'small.jpeg')
                : (media = image);
              return <UploadedImage key={i} image={media} images={images} id={image.id} />;
            })
          )}
        </div>
      </Popover>
    </React.Fragment>
  );
};

export default UploadSection;
