import React from "react";
import CarouselContainer from "./CarouselContainer/CarouselContainer";
import CarInfo from "./CarInfo/CarInfo";
// import Description from "./Description/Description";

import style from "./style.module.scss";

const FirstSection = () => {
  return (
    <div className={style.firstSection}>
      <CarouselContainer />
      <CarInfo />
    </div>
  );
};

export default FirstSection;
