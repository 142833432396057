import React from 'react';
import { Controller } from 'react-hook-form';
import { shallowEqual, useSelector } from 'react-redux';
import SellInput from '../../../Selects/SellInput';
import style from '../../style.module.scss';
import { useTranslation } from 'react-i18next';
import InputWrapper from './InputWrapper';

const Section1 = ({ control, errors, setValue }) => {
  const { t } = useTranslation();
  const formSelects = useSelector((state) => state.sell.slice(1, 5), shallowEqual);
  const formInputs = useSelector((state) => state.sell.slice(5, 9), shallowEqual);
  return (
    <div className={style.section1}>
      {formSelects.map((input, i) => {
        return (
          <React.Fragment key={i}>
            {i === 0 && (
              <label className={style.label}>
                {t('choose_car_label')} <span className={style.asterisk}>*</span>
              </label>
            )}
            {i === 3 && (
              <label className={style.label}>
                {t('choose_year_label')}
                <span className={style.asterisk}>*</span>
              </label>
            )}

            <Controller
              as={
                <SellInput
                  placeholder={t(`placeholder_${input.name}`)}
                  inputs={formSelects}
                  name={input.name}
                  belongsTo='bigForm'
                  errName={errors[input.name]}
                  setValue={setValue}
                />
              }
              name={input.name}
              defaultValue={input.defaultValue}
              control={control}
              rules={
                input.isRequired && {
                  required: true,
                }
              }
            />
          </React.Fragment>
        );
      })}
      {formInputs.map((input, i) => {
        return (
          <React.Fragment key={i}>
            {i === 0 && (
              <label className={style.label}>
                {t('choose_price_label')}
                <span className={style.asterisk}>*</span>
              </label>
            )}
            {i === 2 && (
              <label className={style.label}>
                {t('choose_mileage_label')}
                <span className={style.asterisk}>*</span>
              </label>
            )}

            {input.type === 'input' ? (
              <Controller
                as={
                  <InputWrapper
                    name={input.name}
                    errors={errors}
                    defaultValue={input.defaultValue}
                    setValue={setValue}
                  />
                }
                name={input.name}
                defaultValue={input.defaultValue}
                control={control}
                rules={
                  input.isRequired && {
                    required: true,
                  }
                }
              />
            ) : (
              <Controller
                as={
                  <SellInput
                    placeholder={t(`placeholder_${input.name}`)}
                    name={input.name}
                    belongsTo='bigForm'
                    errName={errors[input.name]}
                    setValue={setValue}
                  />
                }
                name={input.name}
                defaultValue={input.defaultValue}
                control={control}
              />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Section1;
