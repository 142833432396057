import React, {useEffect, useState} from 'react';

const AcceptCookies = () => {
  const [accepted, setAccepted] = useState(false);

  // Function to handle the "Accept" button click
  const handleAcceptClick = () => {
    setAccepted(true);
    localStorage.setItem('cookiesAccepted', 'true'); // Store the user's preference
  };

  // Check if the user has already accepted cookies on component mount
  useEffect(() => {
    const cookiesAccepted = localStorage.getItem('cookiesAccepted');
    if (cookiesAccepted === 'true') {
      setAccepted(true);
    }
  }, []);

  // Check if the user has already accepted cookies
  // (You can use localStorage or a cookie to store this information)
  if (accepted) {
    return null;
  }

  return (
    <div className="accept-cookies-banner" style={bannerStyle}>
      <p style={textStyle}>
        This website uses cookies to ensure you get the best experience on our website.
        By using this website, you agree to our use of cookies.
      </p>
      <button onClick={handleAcceptClick} style={buttonStyle}>Accept All</button>
    </div>
  );
};

// CSS Styles for the banner and button
const bannerStyle = {
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '100%',
  background: '#000',
  color: '#fff',
  padding: '10px',
  textAlign: 'center',
  zIndex: 9999,
};

const textStyle = {
  filter: 'grayscale(100%)', // Set text to black and white
};

const buttonStyle = {
  background: '#fff',
  color: '#000',
  padding: '8px 20px',
  borderRadius: '5px',
  cursor: 'pointer',
};

export default AcceptCookies;
