import React from "react";
import { MdNavigateBefore } from "react-icons/md";

const PreviousBtn = (props) => {
  const { className, onClick, slideCount, currentSlide } = props;
  return (
    <>
      {currentSlide !== slideCount - props.slidesToShow && (
        <div className={className} onClick={onClick}>
          <MdNavigateBefore size={20} />
        </div>
      )}
    </>
  );
};

export default PreviousBtn;
