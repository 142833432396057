import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const TextAreaWrapper = ({ setValue, control }) => {
  const customText = useSelector((state) => state.sell[15].defaultValue, shallowEqual);
  const { t } = useTranslation();

  useEffect(() => {
    setValue('customText', customText); 
  }, [setValue, customText]);

  return (
    <Controller
      name='customText'
      defaultValue={customText}
      control={control}
      as={
        <textarea
          placeholder={t("add_ann_message")}
          id=''
          cols='30'
          rows='10'
          maxLength={640}
          value={customText}
        ></textarea>
      }
    />
  );
};

export default TextAreaWrapper;
