import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import RadioWrapper from './RadioWrapper';

const RadioGroup = ({ control, name, setValue, defaultValue }) => {
  ////console.log(defaultValue);

  useEffect(() => {
    setValue(name, defaultValue);
  }, [setValue, defaultValue, name]);

  return (
    <Controller
      as={<RadioWrapper defaultValue={defaultValue} name={name} />}
      control={control}
      name={name}
      defaultValue={defaultValue}
    />
  );
};

export default RadioGroup;
