import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

const PaidExpenses = () => {
  const dealerCarDetails = useSelector((state) => state.dealerCars.dealerCarDetails.expenses, shallowEqual);
  let { car_price, transport_fee, insurance_fee, storage_fee, dealer_fee, late_fee, document_fee, custom_fee,sub_lot } =
    dealerCarDetails;
  car_price = parseInt(car_price || 0);
  transport_fee = parseInt(transport_fee || 0);
  insurance_fee = parseInt(insurance_fee || 0);
  storage_fee = parseInt(storage_fee || 0);
  dealer_fee = parseInt(dealer_fee || 0);
  late_fee = parseInt(late_fee || 0);
  document_fee = parseInt(document_fee || 0);
  custom_fee = parseInt(custom_fee || 0);
  sub_lot = parseInt(sub_lot || 0);
  //console.log(dealerCarDetails);
  return (
    <div>
      <h1>paid</h1>
      <div>
        {/* <p>{t('placeholder_price')}</p> */}
        <h1>{car_price ? `$${car_price}` : '$0'}</h1>
      </div>
      <div>
        {/* <p>{t('t_price')}</p> */}
        <h1>{transport_fee ? `$${transport_fee}` : '$0'}</h1>
      </div>
      <div>
        {/* <p>{t('Insurance')}</p> */}
        <h1>{insurance_fee ? `$${insurance_fee}` : '$0'}</h1>
      </div>
      <div>
        {/* <p>{t('Storage')}</p> */}
        <h1>{storage_fee ? `$${storage_fee}` : '$0'}</h1>
      </div>
      <div>
        {/* <p>{t('Dealer fee')}</p> */}
        <h1>{dealer_fee ? `$${dealer_fee}` : '$0'}</h1>
      </div>
      <div>
        {/* <p>{t('Late fee')}</p> */}
        <h1>{late_fee ? `$${late_fee}` : '$0'}</h1>
      </div>
      <div>
        {/* <p>{t('Document fee')}</p> */}
        <h1>{document_fee ? `$${document_fee}` : '$0'}</h1>
      </div>
      <div>
        {/* <p>{t('Custom fee')}</p> */}
        <h1>{custom_fee ? `$${custom_fee}` : '$0'}</h1>
      </div>
      <div>
        {/* <p>{t('Custom fee')}</p> */}
        <h1>{sub_lot ? `$${sub_lot}` : '$0'}</h1>
      </div>
      <div>
        {/* <p>{t('total')}</p> */}
        <h1>{`$${car_price + transport_fee + insurance_fee + storage_fee + dealer_fee + late_fee + document_fee + custom_fee + sub_lot
          }`}</h1>
      </div>
    </div>
  );
};

export default PaidExpenses;
