import React from "react";
import Icon from "@ant-design/icons";

const SliderPrevSvg = () => (
  <svg
    width="80"
    height="8"
    viewBox="0 0 80 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.646446 4.35355C0.451187 4.15828 0.451187 3.8417 0.646446 3.64644L3.82843 0.464459C4.02369 0.269197 4.34027 0.269197 4.53554 0.464459C4.7308 0.659722 4.7308 0.976304 4.53554 1.17157L1.70711 3.99999L4.53554 6.82842C4.7308 7.02368 4.7308 7.34027 4.53554 7.53553C4.34027 7.73079 4.02369 7.73079 3.82843 7.53553L0.646446 4.35355ZM80 4.5L1 4.49999L1 3.49999L80 3.5L80 4.5Z"
      fill="#FF8D4E"
    />
  </svg>
);

const SliderPrevIcon = (props) => <Icon component={SliderPrevSvg} {...props} />;

export default SliderPrevIcon;
