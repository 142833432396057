import React, { useEffect, useRef, useState } from 'react';
import style from './style.module.scss';
import SellForm from '../../../components/Common/Forms/SellForm/SellForm';
import Button from '../../../components/Common/Buttons/Button';
import { notification } from 'antd';
import { useForm } from 'react-hook-form';
import UploadSection from './UploadSection/UploadSection';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { addToCars } from '../../../redux/Reducers/userReducer';
import Loader from '../../../components/Common/Loader/Loader';
import TextAreaWrapper from './TextArea/TextAreaWrapper';
import LoginSignup from '../../../components/Common/Modals/LoginSignup/LoginSignup';
import PhoneWrapper from './PhoneWrapper/PhoneWrapper';
import { useTranslation } from 'react-i18next';

const FormBlock = ({ id }) => {
  const { handleSubmit, control, setValue, errors } = useForm();
  const [isVisible, setIsVisible] = useState(false); //state for loader
  const [modalState, setModalState] = useState({ isVisible: false, initialForm: '', message: '' }); //state for login/signup modal
  const user = useSelector((state) => state.user.info, shallowEqual);
  const scrollRef = useRef(null);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const openNotification = (text, type) => {
    notification[type]({
      description: text,
      className: style.notification,
      duration: 10,
    });
  };

  const onSubmit = (data) => {
    if (Object.keys(data).length > 0) {
      user
        ? dispatch(addToCars(data, id, setIsVisible, openNotification, i18n.language))
        : setModalState({ ...modalState, isVisible: true, message: t('login_signup_ann') });
    }
  };

  useEffect(() => {
    Object.keys(errors).length > 0 && scrollRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [scrollRef, errors]);

  return (
    <div
      className={style.mainContainer}
      ref={scrollRef}
      style={{ scrollMarginTop: Object.keys(errors).includes('images') ? '170px' : '-300px' }}
    >
      <form key={4} autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
        <UploadSection control={control} setValue={setValue} errors={errors} id={id} />
        <div className={style.formContainer}>
          <SellForm control={control} setValue={setValue} errors={errors} scrollRef={scrollRef} />
        </div>
        <div className={style.textContainer}>
          <TextAreaWrapper setValue={setValue} control={control} />
          <PhoneWrapper setValue={setValue} control={control} id={id} error={errors['Phone']} />
          <Button innerText={t('publish_btn')} type='submit' />
        </div>
      </form>
      <Loader isVisible={isVisible} />
      <LoginSignup modalState={modalState} setModalState={setModalState} />
    </div>
  );
};

export default FormBlock;
