import Modal from 'antd/lib/modal/Modal';
import React, { useState } from 'react';
import MobileActivationForm from '../../Forms/MobileActivationForm/MobileActivationForm';
import EmailActivationForm from '../../Forms/EmailActivationForm/EmailActivationForm';
import style from '../style.module.scss';

const ActivationModal = ({ modalState, setModalState }) => {
  const [step, setStep] = useState(1); //state for form steps

  return (
    <Modal
      title='Search Modal'
      centered
      footer={null}
      open={modalState.isVisible}
      width={650}
      wrapClassName={style.activationModal}
      onCancel={() => {
        setModalState({ ...modalState, isVisible: false });
        setStep(1);
      }}
      className={style.activationModal}
    >
      {modalState.innerContent === 'mobile' ? (
        <MobileActivationForm step={step} setStep={setStep} modalState={modalState} setModalState={setModalState} />
      ) : (
        <EmailActivationForm step={step} setStep={setStep} modalState={modalState} setModalState={setModalState} />
      )}
    </Modal>
  );
};

export default ActivationModal;
