//handle horizontal scroll
export const handleScrollHelp = (e, isHovered, setIsHovered) => {
  let scrollWidth = Math.ceil(e.currentTarget.scrollWidth);
  let scrollLeft = Math.ceil(e.currentTarget.scrollLeft);
  let clientWidth = Math.ceil(e.currentTarget.clientWidth);
  // //console.log(e.deltaY);
  if (e.deltaY < 0) {
    //scrolls left
    e.currentTarget.scrollBy({ top: 0, left: -240, behaviour: "smooth" });
    if (scrollLeft === 0) {
      setIsHovered(false);
    } else if (!isHovered) {
      setIsHovered(true);
    }
  }
  //scrolls right
  else {
    e.currentTarget.scrollBy({ top: 0, left: 240, behaviour: "smooth" });
    if (scrollWidth - scrollLeft === clientWidth) {
      setIsHovered(false);
    } else if (!isHovered) {
      setIsHovered(true);
    }
  }
};

export const queryToObject = (query) => {
  const newObj = {};
  const keys = Object.keys(query);
  const arrToCheck = [
    "bodyType",
    "make",
    "model",
    "engineCap",
    "yearFrom",
    "yearTo",
    "priceFrom",
    "priceTo",
    "milageFrom",
    "milageTo",
    "engine",
    "gearBox",
    "steeringWheel",
    "country",
    "color",
    "currency",
    "mileageType",
    "ByParts",
    "CustomCleared",
    "Exchange",
    "FromDealers",
    "currentPage",
  ];
  const filteredArr = keys.filter((key) => arrToCheck.includes(key));

  if (keys.length === 22 && filteredArr.length === 22) {
    for (const key of keys) {
      if (key === "bodyType" || key === "color") {
        if (query[key]) {
          newObj[key] = [...query[key].replaceAll(",", "")];
        } else newObj[key] = [];
        continue;
      }
      if (query[key] === "false") {
        newObj[key] = false;
        continue;
      }
      if (query[key] === "true") {
        newObj[key] = true;
        continue;
      }

      if (key === "country") {
        newObj[key] = query[key];
        continue;
      }
      // eslint-disable-next-line
      newObj[key] = parseInt(query[key]);
    }

    return newObj;
  } else return null;
};

export const searchToObject = (search) => {
  const newObj = {};
  search.forEach((value) => {
    newObj[value.name] = value.defaultValue;
  });
  return newObj;
};

export const calcCopartFob = (price, fobs) => {
  let fobPrice = 0;
  for (let i = 0; i < fobs.length; i++) {
    const fob = fobs[i];
    if (
      fob.auction === "copart" &&
      ((price >= parseFloat(fob.priceFrom) &&
        price <= parseFloat(fob.priceTo)) ||
        (price >= parseFloat(fob.priceFrom) && parseFloat(fob.priceTo) === 0))
    ) {
      fobPrice =
        parseFloat(fob.biddingFee) +
        parseFloat(fob.virtualBidFee) +
        parseFloat(fob.gateFee) +
        parseFloat(fob.brokerFee);
      // eslint-disable-next-line radix
      if (parseInt(fob.is_percent) === 1) {
        return Math.round((price * parseFloat(fob.percent)) / 100 + fobPrice);
      }
      return Math.round(fobPrice);
    }
  }
  return 0;
};

export const calcIaaFob = (price, fobs) => {
  let fobPrice = 0;
  for (let i = 0; i < fobs.length; i++) {
    const fob = fobs[i];
    if (
      fob.auction === "iaai" &&
      ((price >= parseFloat(fob.priceFrom) &&
        price <= parseFloat(fob.priceTo)) ||
        (price >= parseFloat(fob.priceFrom) && parseFloat(fob.priceTo) === 0))
    ) {
      fobPrice =
        parseFloat(fob.biddingFee) +
        parseFloat(fob.virtualBidFee) +
        parseFloat(fob.gateFee) +
        parseFloat(fob.brokerFee);
      // eslint-disable-next-line radix
      if (parseInt(fob.is_percent) === 1) {
        return Math.round((price * parseFloat(fob.percent)) / 100 + fobPrice);
      }
      return Math.round(fobPrice);
    }
  }
  return 0;
};

export const checkFilter = (data) => {
  // TODO Should be rewrited
  const filtersToCheck = ['steering', 'wheelDrive', 'intColor', 'extColor', 'exchange', 'soldUrgent', 'arrivedStatus', 'customsCleared'];
  let exist = false;

  filtersToCheck.map(filter => {
    if (exist) {
      return;
    }
    if (data?.[filter]) {
      exist = true;
    }
  });

  return exist;
}

export const getQueryParams = (search) => {
  const params = new URLSearchParams(search);
  const queryObj = {};

  for (const [key, value] of params.entries()) {
    if (value !== "null" && !value.includes("null")) {
      queryObj[key] = value;
    }
  }
  return queryObj;
};
