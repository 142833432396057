import React from "react";
import "./style.scss";
import {servicesLogistic} from "../../../fakeData";
import { useTranslation } from "react-i18next";

const HomeLogistic = () => {
  const { t } = useTranslation();
  return (
    <div className="home-logistic">
      <h1 className="title">{t("logistics")}</h1>
      <div className="services-grid">
        {servicesLogistic.map((service) => (
          <div key={service.id} className="logistic-item">
            <div className="service-image" />
            <div className="service-info">
              <p className="description">{t(service.title)}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeLogistic;
