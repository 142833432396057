import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { redirectPartner } from "../../redux/Reducers/userReducer";
import style from "./style.module.scss";
import { FaPhone, FaRegEnvelope, FaLocationDot } from "react-icons/fa6";

const Partners = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user, shallowEqual);
  const dispatch = useDispatch();
  const userType = user.info ? user.info.userType : null;

  useEffect(() => {
    dispatch(redirectPartner(userType));
  }, [dispatch, userType]);

  return (
    <div className={style.pageContainer}>
      <div className={style.mainContainer}>
        <div className={style.txtContainer}>
          <div>
            <h2>{t("partner_orange")}</h2>
            <h1>{t("partner_white")}</h1>
          </div>
          <div style={{ flexWrap: "nowrap", display: "flex" }}>
            <FaPhone size={20} color="#fff" />
            <div>
              <p>+374 60 670000 </p>
              <p>+374 77 006045 </p>
            </div>
          </div>
          <div style={{ flexWrap: "nowrap", display: "flex" }}>
            <FaLocationDot size={20} color="#fff" />
            <div>
              <p>{t("address")}</p>
            </div>
          </div>
          <div style={{ flexWrap: "nowrap", display: "flex" }}>
            <FaRegEnvelope size={20} color="#fff" />

            <div>
              <p>sales@autobuy.am</p>
            </div>
          </div>
        </div>
        <div className={style.recsContainer}>
          <div className={style.rec1}></div>
          <div className={style.rec2}></div>
          <div className={style.rec3}></div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
