import React, { useEffect, useState } from 'react';
import Button from '../../Buttons/Button';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { authUser } from '../../../../redux/Reducers/userReducer';
import { Controller } from 'react-hook-form';
import { useHistory } from 'react-router';
// import SocialButton from "../../Buttons/SocialButton";
import style from './style.module.scss';
import {loginUser, resetPassword} from '../../../../api/requests';
import { useTranslation } from 'react-i18next';
import InputWrapper from './InputWrapper';

const SigninForm = ({ handleSubmit, modalState, setModalState, control, setAlertState }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const path = history.location.pathname.substring(1);
  const [forgotPassword, setForgotPassword] = useState(false);
  const lang = useSelector((state) => state.user.lang[0].value, shallowEqual);
  const { t } = useTranslation();

  useEffect(() => {
    setForgotPassword(false);
    return () => {
      setForgotPassword(false);
    };
  }, [modalState]);

  const onSubmit = (data) => {
    //make api call here then dispatch result
    const formData = { ...data, lang };

    loginUser(formData).then((res) => {
      if(res && res.data) {
        if (res.status === 200) {
          dispatch(authUser(res.data));
          setModalState !== undefined && setModalState({...modalState, isVisible: false});
          path === 'login' && history.push('/');
        } else {
          setAlertState({visible: true, description: t(`${res.data.error.message}`)});
        }
      }
    });
  };

  const handleChange = () => {
    setForgotPassword(true);
  };

  const getPasswordLink = (data) => {
    const formData = { ...data, lang };
    resetPassword(formData).then((res) => {
      if(res && res.data) {
        if (res.status === 200) {
          setAlertState({visible: true, description: t(`password_link_sent`), type: 'success'});
        } else {
          setAlertState({visible: true, description: t(`${res.data.error.message}`)});
        }
      }
    });
  };

  return (
    <React.Fragment>
      {!forgotPassword ? (
        <form onSubmit={handleSubmit(onSubmit)} key='login' className={style.signInForm} autoComplete='on'>
          <Controller
            as={<InputWrapper type={'text'} required={true} label={t('placeholder_username')} name={'username'} />}
            name='username'
            control={control}
            defaultValue=''
          />
          <Controller
            as={<InputWrapper type={'password'} required={true} label={t('placeholder_pass')} name='password' autocomplete="current-password" />}
            name='password'
            control={control}
            defaultValue=''
          />
          <span className={style.span} onClick={handleChange}>
            {t('forgot_pass')}
          </span>
          <Button innerText={t('signin')} type='submit' />
          {/* <p className={style.p}>OR</p>
      <div>
        <SocialButton belongsTo="signin" innerText="Google" />
        <SocialButton belongsTo="signin" innerText="Facebook" />
      </div> */}
        </form>
      ) : (
        <form onSubmit={handleSubmit(getPasswordLink)} key='password' className={style.signInForm}>
          <p style={{ margin: 0 }}>{t('reset_pass')}</p>
          <Controller
            as={<InputWrapper type={'text'} required={true} label={t('placeholder_email')} name={'email'} />}
            name='email'
            control={control}
            defaultValue=''
          />
          <Button innerText={t('submit_btn')} type='submit' />
        </form>
      )}
    </React.Fragment>
  );
};

export default SigninForm;
