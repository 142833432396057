export const SET_PAGE = 'SET_PAGE';
export const SET_QUERY = 'SET_QUERY';

export const setPage = (page) => ({
    type: SET_PAGE,
    payload: page
  });

  export const setQuery = (query) => ({
    type: SET_QUERY,
    payload: query
  });