import {
  addCar,
  editUserDetails,
  findUserCars,
  editCarReq,
  deleteCar,
  addToFavsReq,
  findUserFavs,
  deleteFavorite,
  logOut,
} from '../../api/requests';
import { push } from 'connected-react-router';

const initialState = {
  info: null,
  activeTab: '3',
  favourites: [],
  compare: [],
  activeCompareIndex: 0,
  cars: [],
  userStatus: null,
  defaultView: 1,
  bigForm: true,
  lang: [
    { name: 'Հայ', value: 'am' },
    { name: 'Ру', value: 'ru' },
    { name: 'Eng', value: 'en' },
  ],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'AUTH_USER': {
      return { ...state, info: action.data };
    }
    case 'SET_ACTIVE_TAB': {
      return { ...state, activeTab: action.data };
    }
    case 'ADD_TO_COMPARE': {
      return {
        ...state,
        compare: [...state.compare, action.carInfo],
      };
    }
    case 'REMOVE_FROM_COMPARE': {
      return { ...state, compare: state.compare.filter((car) => car.id !== action.carId), activeCompareIndex: 0 };
    }
    case 'SET_COMPARE_INDEX': {
      return { ...state, activeCompareIndex: action.index };
    }
    case 'SET_FAVOURITES': {
      return { ...state, favourites: [...action.favCars] };
    }
    case 'ADD_TO_FAVOURITES': {
      return { ...state, favourites: [...state.favourites, action.car] };
    }
    case 'REMOVE_FROM_FAVOURITES': {
      return { ...state, favourites: state.favourites.filter((car) => car.id !== action.carId) };
    }
    case 'SET_USER_CARS': {
      return { ...state, cars: [...action.cars] };
    }
    case 'EDIT_USER': {
      return { ...state, info: action.info };
    }
    case 'SET_MAIN_LANG': {
      return { ...state, lang: [action.data, ...state.lang.filter((item) => item.value !== action.data.value)] };
    }
    case 'REMOVE_CAR': {
      return { ...state, cars: state.cars.filter((car) => car.id !== action.id) };
    }
    case 'LOG_OUT': {
      return { ...state, info: null, cars: [], favourites: [], compare: [] };
    }
    case 'SET_VIEW_GRID': {
      return { ...state, defaultView: 1 };
    }
    case 'SET_VIEW_LIST': {
      return { ...state, defaultView: 0 };
    }
    case 'SET_EMAIL_CONFIRMED': {
      return {
        ...state,
        info: { ...state.info, emailConfirmed: action.data.emailConfirmed, userType: action.data.userType },
      };
    }
    case 'SET_MOBILE_CONFIRMED': {
      return {
        ...state,
        info: { ...state.info, mobileConfirmed: action.data.mobileConfirmed, userType: action.data.userType },
      };
    }
    case 'TOGGLE_FORM_VIEW': {
      return {
        ...state,
        bigForm: !state.bigForm,
      };
    }
    case 'SET_FORM_VIEW': {
      return {
        ...state,
        bigForm: action.data,
      };
    }
    case 'UPDATE_USER_AUCTION_SERVER': {
      return { ...state, info: { ...state.info, auctionServer: action.data } };
    }
    default:
      return state;
  }
};

//action

export const authUser = (data) => {
  ////console.log(data);
  return (dispatch) => {
    dispatch({ type: 'AUTH_USER', data });
    const favCars = data.userFavourites.map((car) => {
      return { ...car, carMedia: [{ carImage: { name: '' } }], carMake: '', carModel: '' };
    });
    dispatch({ type: 'SET_FAVOURITES', favCars });
  };
};

export const setActiveTab = (data) => {
  return (dispatch) => dispatch({ type: 'SET_ACTIVE_TAB', data });
};

export const addToCompare = (carInfo) => {
  return (dispatch, getState) => {
    const compare = getState().user.compare;
    ////console.log(compare);
    const carId = carInfo.id;

    if (compare.length > 0) {
      if (compare.some((car) => car.id === carInfo.id)) {
        dispatch({ type: 'REMOVE_FROM_COMPARE', carId });
      } else dispatch({ type: 'ADD_TO_COMPARE', carInfo });
    } else {
      dispatch({ type: 'ADD_TO_COMPARE', carInfo });
    }
  };
};

export const removeFromCompare = (carId) => {
  return { type: 'REMOVE_FROM_COMPARE', carId };
};

export const setActiveCompareIndex = (index) => {
  return { type: 'SET_COMPARE_INDEX', index };
};

export const addToFavourites = (carId, setModalState, lang) => {
  return (dispatch, getState) => {
    const favourites = getState().user.favourites.map((car) => car.id);
    ////console.log(favourites);
    if (!favourites.includes(carId)) {
      addToFavsReq(carId).then((res) => {
        ////console.log(res);
        if (res.status === 200) {
          const car = { id: carId, carMedia: [{ carImage: { name: '' } }], carMake: '', carModel: '' };
          dispatch({ type: 'ADD_TO_FAVOURITES', car });
        }
        if (res.status === 401) {
          ////console.log(lang);
          let text =
            (lang === 'am' && 'Գրանցվեք կամ մուտք գործեք Ձեր հաշիվ՝ Հավանածներ ավելացնելու համար') ||
            (lang === 'en' && 'Login or Signup to add to favourites') ||
            (lang === 'ru' && 'Войдите или зарегистрируйтесь, чтобы добавить в избранное');
          setModalState && setModalState({ isVisible: true, message: text });
        }
      });
    } else {
      deleteFavorite(carId).then((res) => {
        ////console.log(res);
        if (res.status === 200) {
          dispatch({ type: 'REMOVE_FROM_FAVOURITES', carId });
        }
      });
    }
  };
};

export const setUserFavs = (userId) => {
  return (dispatch) => {
    findUserFavs(userId).then((res) => {
      ////console.log(res);
      if (res.status === 200) {
        const favCars = res.data.favourites.map((car) => car.Cars);
        ////console.log(favCars);
        dispatch({ type: 'SET_FAVOURITES', favCars });
      } else {
        dispatch({ type: 'LOG_OUT' });
      }
    });
  };
};

export const removeFromFavourites = (carId) => {
  return (dispatch) => {
    deleteFavorite(carId).then((res) => {
      ////console.log(res);
      if (res.status === 200) {
        dispatch({ type: 'REMOVE_FROM_FAVOURITES', carId });
      }
    });
  };
};

export const setUserCars = (userId) => {
  return (dispatch) => {
    findUserCars(userId).then((res) => {
      if (res.status === 200) {
        const cars = res.data.cars.reverse();
        if (cars) {
          dispatch({ type: 'SET_USER_CARS', cars });
        }
      }
      if (res.status === 401) {
        dispatch({ type: 'LOG_OUT' });
      }
    });
  };
};

export const addToCars = (data, id, setIsVisible, openNotification, lang) => {
  return (dispatch) => {
    const formData = {
      ...data,
      Phone: data.Phone.length === 9 ? `+374${data.Phone.slice(1)}` : `+374${data.Phone}`,
      partSelling: +data.partSelling,
      exchange: +data.exchange,
      cleanCar: +data.cleanCar,
    };

    if (id === 0) {
      setIsVisible(true);
      addCar(formData).then((res) => {
        if (res.status === 401) {
          setIsVisible(false);
          dispatch({ type: 'LOG_OUT' });
        }
        if (res.status === 200 && res.data.code !== 0) {
          let text =
            (lang === 'am' && 'Մեքենան ավելացված է') ||
            (lang === 'en' && 'Car added') ||
            (lang === 'ru' && 'Машина добавлена');
          openNotification(text, 'success');
          setIsVisible(false);
          dispatch({ type: 'SET_ACTIVE_TAB', data: '1' });
          dispatch(push('/profile'));
          dispatch({ type: 'RESTORE_DEFAULT' });
        } else if (res.data.code === 0) {
          setIsVisible(false);
          let text =
            (lang === 'am' && 'Դուք այլևս չեք կարող մեքենա ավելացնել') ||
            (lang === 'en' && "You can't add more cars") ||
            (lang === 'ru' && 'Вы не можете больше добавить объявление');
          openNotification(text, 'error');
          dispatch({ type: 'SET_ACTIVE_TAB', data: '1' });
          dispatch(push('/profile'));
        } else {
          setIsVisible(false);
        }
      });
    } else {
      setIsVisible(true);
      editCarReq(formData, id).then((res) => {
        if (res.status === 401) {
          setIsVisible(false);
          dispatch({ type: 'LOG_OUT' });
        }
        if (res.status === 200) {
          setIsVisible(false);
          dispatch({ type: 'SET_ACTIVE_TAB', data: '1' });
          dispatch(push('/profile'));
          dispatch({ type: 'RESTORE_DEFAULT' });
        } else {
          setIsVisible(false);
        }
      });
    }
  };
};

export const deleteUserCar = (id, openNotification, lang) => {
  return (dispatch) => {
    deleteCar(id).then((res) => {
      if (res.status === 200) {
        let successText =
          (lang === 'am' && 'Մեքնան ջնջված է') ||
          (lang === 'en' && 'Car deleted') ||
          (lang === 'ru' && 'Машина удалена');
        dispatch({ type: 'REMOVE_CAR', id });
        dispatch(push('/profile'));
        openNotification(successText, 'success');
      } else {
        let errorText =
          (lang === 'am' && 'Մեքնան հնարավոր չէ ջնջնել') ||
          (lang === 'en' && 'Unable to delete car') ||
          (lang === 'ru' && 'Не удалось удалить машину');
        openNotification(errorText, 'error');
      }
    });
  };
};

export const logOutUser = () => {
  return (dispatch) => {
    logOut().then((res) => {
      try {
        if (CustomEvent) {
          //console.log('logout-event called');
          // Create the event
          const logoutEvent = new CustomEvent('logout', {
            detail: {
              logout: true,
            },
          });

          // Dispatch/Trigger/Fire the event
          document.dispatchEvent(logoutEvent);
        }
      } catch (e) {
        //console.log('logout-event', e.message);
      }
      ////console.log(res);
      res.status === 200 && dispatch({ type: 'LOG_OUT' });
    });
  };
};

export const editUser = (data, setAlertState, reset, lang) => {
  return (dispatch) => {
    editUserDetails(data).then((res) => {
      ////console.log(res);
      ////console.log(lang);
      const info = res.data.data;
      if (res.status === 200) {
        let okText = (lang === 'am' && 'Պահպանված է') || (lang === 'en' && 'Saved') || (lang === 'ru' && 'Сохранено');
        let text =
          (lang === 'am' && 'Ընթացիկ Գաղտնաբառը սխալ է') ||
          (lang === 'en' && 'Wrong Current Password') ||
          (lang === 'ru' && 'Неверный текущий пароль');

        let text_2 =
          (lang === 'am' && 'Նշված հեռախոսահամարը կամ Էլ․ հասցեն արդեն գոյություն ունեն') ||
          (lang === 'en' && 'Mobile number or Email already exist') ||
          (lang === 'ru' && 'Номер телефона или эл. адрес существуют');

        if (res.data.code === 1) {
          dispatch({ type: 'EDIT_USER', info });
          setAlertState({ description: okText, type: 'success', visible: true });
          reset();
        }
        if (res.data.code === 0) {
          setAlertState({ description: text, type: 'error', visible: true });
        }
        if (res.data.code === 2) {
          setAlertState({ description: text_2, type: 'error', visible: true });
        }
      }
      if (res.status === 401) {
        logOut().then((res) => {
          ////console.log(res);
          res.status === 200 && dispatch({ type: 'LOG_OUT' });
        });
      }
    });
  };
};

export const setMainLanguage = (data) => {
  return (dispatch) => {
    dispatch({ type: 'SET_MAIN_LANG', data });
  };
};

export const redirectPartner = (userType) => {
  return (dispatch) => {
    if (userType >= 5) {
      dispatch({ type: 'SET_ACTIVE_TAB', data: '3' });
      dispatch(push('/profile'));
    }
  };
};
export const redirectUserSettings = (userType) => {
  return (dispatch) => {
    if (userType >= 1) {
      dispatch({ type: 'SET_ACTIVE_TAB', data: '5' });
      dispatch(push('/profile'));
    }
  };
};
export const toggleFormView = () => {
  return { type: 'TOGGLE_FORM_VIEW' };
};

export const setFormView = (data) => {
  return { type: 'SET_FORM_VIEW', data };
};

export const updateUserServer = (data) => {
  return { type: 'UPDATE_USER_AUCTION_SERVER', data };
};

export default userReducer;
