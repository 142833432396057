import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import style from './style.module.scss';

const Terms = () => {
  const dispatch = useDispatch();
  const {i18n} = useTranslation();
  const lang = i18n.language;
  useEffect(() => {
    // const lang = JSON.parse(localStorage.getItem('lang'));
  }, [dispatch, i18n]);

  return (
    <div className={style.pageContainer}>
      {lang === 'am' && (
        <div>
          <h1>Autobuy LLC - Terms and Conditions</h1>
          <div className={style.textContainer}>
            <h2>1. Service Description</h2>
            <p>Autobuy LLC provides logistics services for the transfer of cars from location A to location B. The
              transportation time may vary depending on factors such as location, weather conditions, and route
              statuses.</p>

            <h2>2. Service Limitations</h2>
            <p>Autobuy LLC reserves the right to decline transportation services for vehicles that do not meet our
              safety and accessibility standards. Additionally, we may have limitations based on geographical areas or
              other factors that affect the feasibility of the transfer.</p>

            <h2>3. Obligations of Autobuy LLC</h2>
            <p>Autobuy LLC agrees to exercise reasonable care and diligence in the transportation of vehicles. We will
              facilitate the arrangement of insurance coverage for the vehicles during transit, utilizing third-party
              insurance providers, upon the request of the customer. The terms and conditions of the insurance policy
              will be as per the policies of the selected insurance provider.</p>

            <h2>4. Obligations of the Customer</h2>
            <p>The customer agrees to provide accurate and complete information about the vehicle, including its
              condition and any specific requirements for transportation. The customer is responsible for ensuring that
              the vehicle is accessible for pick-up and delivery at the agreed-upon times.</p>

            <h2>5. Pricing and Payment Terms</h2>
            <p>The pricing for our logistics service will be communicated to the customer upon agreement. Additional
              fees, such as insurance charges or any other agreed-upon costs, may apply and will be disclosed to the
              customer in advance.</p>
            <p>Payment for the service is due upon agreement and before the commencement of the transportation process.
              Autobuy LLC accepts various payment methods, including but not limited to bank transfers, credit cards, or
              cash payments, as mutually agreed upon by both parties.</p>

            <h2>6. Insurance</h2>
            <p>Autobuy LLC facilitates the arrangement of insurance coverage for vehicles during transit, utilizing
              reputable third-party insurance providers. The extent of coverage and any applicable terms and conditions
              will be as per the policies of the selected insurance provider.</p>

            <h2>7. Liability</h2>
            <p>Autobuy LLC will take reasonable precautions to ensure the safe transportation of vehicles. However, we
              shall not be held liable for any damages or losses that may occur during transit due to circumstances
              beyond our control, including but not limited to accidents, acts of nature, or force majeure events.</p>

            <h2>8. Delays and Force Majeure</h2>
            <p>Autobuy LLC shall not be held responsible for delays in transportation caused by factors such as traffic,
              weather conditions, or unforeseen circumstances. In the event of force majeure events that significantly
              impact the transportation schedule, both parties will work together to find a reasonable solution.</p>

            <h2>9. Cancellations and Refunds</h2>
            <p>If the customer wishes to cancel the transportation service, they must notify Autobuy LLC within a
              reasonable time frame. Cancellation fees may apply. Refunds, if applicable, will be provided according to
              our refund policy, as mutually agreed upon by both parties.</p>

            <h2>10. Dispute Resolution</h2>
            <p>In the event of any disputes arising from this logistics service agreement, both parties agree to first
              attempt to resolve the matter through good-faith negotiations. If an amicable resolution cannot be
              reached, the parties may pursue other dispute resolution methods as outlined in the governing law.</p>

            <h2>11. Confidentiality</h2>
            <p>Autobuy LLC shall treat all customer information and details related to the logistics service with strict
              confidentiality.</p>

            <h2>12. Governing Law</h2>
            <p>This logistics service agreement shall be governed by the laws of Armenia, and any disputes shall be
              subject to the exclusive jurisdiction of the courts in Armenia.</p>

            <h2>13. Account and Order Management</h2>
            <p>Autobuy LLC provides registered users with access to an online account where they can view and manage
              their orders. Upon creating an account, users are responsible for maintaining the confidentiality of their
              account credentials and are solely responsible for all activities that occur under their account.</p>
            <p>Users agree to provide accurate and up-to-date information when creating their accounts. Autobuy LLC
              reserves the right to terminate or suspend accounts at its sole discretion if users violate any terms and
              conditions of this agreement or engage in fraudulent or unlawful activities.</p>

            <h2>14. Entire Agreement</h2>
            <p>This agreement constitutes the entire agreement between the parties with respect to the subject matter
              and supersedes all prior agreements, understandings, negotiations, and discussions, whether oral or
              written, between the parties.</p>

          </div>
        </div>
      )}
      {lang === 'ru' && (
        <div>
          <h1>Условия и положения</h1>
          <div className={style.textContainer}>
            <h2>1. Описание услуги</h2>
            <p>Компания Autobuy LLC предоставляет услуги логистики по перевозке автомобилей из места А в место В. Время
              доставки может варьироваться в зависимости от местоположения, погодных условий и статуса маршрута.</p>

            <h2>2. Ограничения на предоставление услуги</h2>
            <p>Компания Autobuy LLC оставляет за собой право отказаться от предоставления услуг по перевозке
              автомобилей, которые не соответствуют нашим стандартам безопасности и доступности. Кроме того, мы можем
              иметь ограничения, связанные с географическими областями или другими факторами, которые влияют на
              возможность выполнения перевозки.</p>

            <h2>3. Обязанности Autobuy LLC</h2>
            <p>Компания Autobuy LLC обязуется выполнять услуги перевозки автомобилей с должным вниманием и
              добросовестностью. По запросу клиента, мы предоставим необходимую страховку на автомобили во время
              перевозки за дополнительную плату.</p>

            <h2>4. Обязанности клиента</h2>
            <p>Клиент обязуется предоставлять точную и полную информацию о своем автомобиле, включая его состояние и
              любые особенные требования к перевозке. Клиент несет ответственность за обеспечение доступности автомобиля
              для его забора и доставки в оговоренное время.</p>

            <h2>5. Цены и условия оплаты</h2>
            <p>Цены на наши услуги будут согласованы с клиентом по договоренности. Дополнительные платежи, такие как
              страховка или другие согласованные расходы, могут быть применены и будут предварительно оговорены с
              клиентом.</p>
            <p>Оплата за услуги производится по договоренности и перед началом процесса перевозки. Компания Autobuy LLC
              принимает различные способы оплаты, включая банковские переводы, кредитные карты или наличные,
              согласованные обеими сторонами.</p>

            <h2>6. Страхование</h2>
            <p>Компания Autobuy LLC предоставляет страхование для автомобилей во время перевозки по запросу клиента за
              дополнительную плату. Стоимость и условия страхования будут определены согласно полисам выбранного
              страховщика.</p>

            <h2>7. Ответственность</h2>
            <p>Компания Autobuy LLC предпримет все необходимые меры для обеспечения безопасной перевозки автомобилей.
              Однако мы не несем ответственности за любые ущербы или утраты, которые могут возникнуть в результате
              обстоятельств, находящихся вне нашего контроля, включая, но не ограничиваясь, авариями, стихийными
              бедствиями или событиями непреодолимой силы.</p>

            <h2>8. Задержки и форс-мажор</h2>
            <p>Компания Autobuy LLC не несет ответственности за задержки в перевозке, вызванные трафиком, погодными
              условиями или другими непредвиденными обстоятельствами. В случае событий форс-мажора, серьезно влияющих на
              график перевозки, стороны будут работать вместе для нахождения разумного решения.</p>

            <h2>9. Отмена и возврат денежных средств</h2>
            <p>Если клиент желает отменить услуги перевозки, он обязан уведомить компанию Autobuy LLC в разумные сроки.
              Могут применяться штрафные сборы при отмене. Политика возврата денежных средств, если таковая
              предусмотрена, будет определена согласно нашим правилам возврата, согласованным обеими сторонами.</p>

            <h2>10. Разрешение споров</h2>
            <p>В случае возникновения споров по данному соглашению, стороны соглашаются попробовать решить вопрос путем
              добросовестных переговоров. Если мирное урегулирование не удается достигнуть, стороны могут обратиться к
              другим методам разрешения споров, как определено в применимом законодательстве.</p>

            <h2>11. Конфиденциальность</h2>
            <p>Компания Autobuy LLC обязуется строго соблюдать конфиденциальность информации о клиентах и деталях,
              связанных с логистическими услугами.</p>

            <h2>12. Применимое законодательство</h2>
            <p>Данное соглашение о логистических услугах регулируется законодательством Армении, и все споры подлежат
              исключительной юрисдикции судов Армении.</p>

            <h2>13. Управление аккаунтом и заказами</h2>
            <p>Компания Autobuy LLC предоставляет зарегистрированным пользователям доступ к онлайн-аккаунту, где они
              могут просматривать и управлять своими заказами. Создавая аккаунт, пользователи несут ответственность за
              сохранность учетных данных и являются полностью ответственными за все действия, производимые под их
              аккаунтом.</p>
            <p>Пользователи соглашаются предоставлять точную и актуальную информацию при создании своих аккаунтов.
              Autobuy LLC оставляет за собой право приостановить или закрыть аккаунты по своему усмотрению в случае
              нарушения пользователем любых условий этого соглашения или при замечании мошеннических или незаконных
              действий.</p>

            <h2>14. Полное соглашение</h2>
            <p>Это соглашение является полным соглашением между сторонами относительно предмета соглашения и заменяет
              все предыдущие соглашения, понимания, переговоры и обсуждения, будь то устные или письменные, между
              сторонами.</p>
          </div>
        </div>)}
      {lang === 'en' && (
        <div>
          <h1>Autobuy LLC - Terms and Conditions</h1>
          <div className={style.textContainer}>
            <h2>1. Service Description</h2>
            <p>Autobuy LLC provides logistics services for the transfer of cars from location A to location B. The
              transportation time may vary depending on factors such as location, weather conditions, and route
              statuses.</p>

            <h2>2. Service Limitations</h2>
            <p>Autobuy LLC reserves the right to decline transportation services for vehicles that do not meet our
              safety and accessibility standards. Additionally, we may have limitations based on geographical areas or
              other factors that affect the feasibility of the transfer.</p>

            <h2>3. Obligations of Autobuy LLC</h2>
            <p>Autobuy LLC agrees to exercise reasonable care and diligence in the transportation of vehicles. We will
              facilitate the arrangement of insurance coverage for the vehicles during transit, utilizing third-party
              insurance providers, upon the request of the customer. The terms and conditions of the insurance policy
              will be as per the policies of the selected insurance provider.</p>

            <h2>4. Obligations of the Customer</h2>
            <p>The customer agrees to provide accurate and complete information about the vehicle, including its
              condition and any specific requirements for transportation. The customer is responsible for ensuring that
              the vehicle is accessible for pick-up and delivery at the agreed-upon times.</p>

            <h2>5. Pricing and Payment Terms</h2>
            <p>The pricing for our logistics service will be communicated to the customer upon agreement. Additional
              fees, such as insurance charges or any other agreed-upon costs, may apply and will be disclosed to the
              customer in advance.</p>
            <p>Payment for the service is due upon agreement and before the commencement of the transportation process.
              Autobuy LLC accepts various payment methods, including but not limited to bank transfers, credit cards, or
              cash payments, as mutually agreed upon by both parties.</p>

            <h2>6. Insurance</h2>
            <p>Autobuy LLC facilitates the arrangement of insurance coverage for vehicles during transit, utilizing
              reputable third-party insurance providers. The extent of coverage and any applicable terms and conditions
              will be as per the policies of the selected insurance provider.</p>

            <h2>7. Liability</h2>
            <p>Autobuy LLC will take reasonable precautions to ensure the safe transportation of vehicles. However, we
              shall not be held liable for any damages or losses that may occur during transit due to circumstances
              beyond our control, including but not limited to accidents, acts of nature, or force majeure events.</p>

            <h2>8. Delays and Force Majeure</h2>
            <p>Autobuy LLC shall not be held responsible for delays in transportation caused by factors such as traffic,
              weather conditions, or unforeseen circumstances. In the event of force majeure events that significantly
              impact the transportation schedule, both parties will work together to find a reasonable solution.</p>

            <h2>9. Cancellations and Refunds</h2>
            <p>If the customer wishes to cancel the transportation service, they must notify Autobuy LLC within a
              reasonable time frame. Cancellation fees may apply. Refunds, if applicable, will be provided according to
              our refund policy, as mutually agreed upon by both parties.</p>

            <h2>10. Dispute Resolution</h2>
            <p>In the event of any disputes arising from this logistics service agreement, both parties agree to first
              attempt to resolve the matter through good-faith negotiations. If an amicable resolution cannot be
              reached, the parties may pursue other dispute resolution methods as outlined in the governing law.</p>

            <h2>11. Confidentiality</h2>
            <p>Autobuy LLC shall treat all customer information and details related to the logistics service with strict
              confidentiality.</p>

            <h2>12. Governing Law</h2>
            <p>This logistics service agreement shall be governed by the laws of Armenia, and any disputes shall be
              subject to the exclusive jurisdiction of the courts in Armenia.</p>

            <h2>13. Account and Order Management</h2>
            <p>Autobuy LLC provides registered users with access to an online account where they can view and manage
              their orders. Upon creating an account, users are responsible for maintaining the confidentiality of their
              account credentials and are solely responsible for all activities that occur under their account.</p>
            <p>Users agree to provide accurate and up-to-date information when creating their accounts. Autobuy LLC
              reserves the right to terminate or suspend accounts at its sole discretion if users violate any terms and
              conditions of this agreement or engage in fraudulent or unlawful activities.</p>

            <h2>14. Entire Agreement</h2>
            <p>This agreement constitutes the entire agreement between the parties with respect to the subject matter
              and supersedes all prior agreements, understandings, negotiations, and discussions, whether oral or
              written, between the parties.</p>

          </div>
        </div>)}
    </div>
  );
};

export default Terms;
