import React from 'react';
import style from './style.module.scss';
import logo from '../../assets/Images/biglogo.png';
import PhoneIcon from '../../components/Common/Icons/phone';
import LocationIcon from '../../components/Common/Icons/location';
import MailIcon from '../../components/Common/Icons/mail';

const Maintenance = () => {
  return (
    <div className={style.pageContainer}>
      <div className={style.details}>
        <img src={logo} width={'298px'} height={'73px'} alt='' />
        <h1>Under Maintenance</h1>
        <div className={style.contacts}>
          <h2>Contact Us: </h2>
          <div>
            <div>
              <PhoneIcon />
              <p>+374 60 670000 </p>
              <p>+374 77 006045 </p>
            </div>
            <div>
              <LocationIcon /> <p>Armenia, Yerevan, Kilikia district 3</p>
            </div>
            <div>
              <MailIcon /> <p>autobuy.am@gmail.com </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Maintenance;
