export const formatPrice = (price) => {
  const parsedPrice = parseFloat(price);
  return new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "USD",
  }).format(parsedPrice);
};

export function buildHttpQuery(queryObject) {
  const params = new URLSearchParams();

  for (const key in queryObject) {
    if (queryObject.hasOwnProperty(key) && queryObject[key]) {
      params.append(key, queryObject[key]);
    }
  }

  return params.toString();
}
