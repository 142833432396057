import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import style from './style.module.scss';
import Map from "./Map";

const AboutUs = () => {
  const dispatch = useDispatch();
  const {i18n} = useTranslation();
  const lang = i18n.language;
  useEffect(() => {
    // const lang = JSON.parse(localStorage.getItem('lang'));
  }, [dispatch, i18n]);

  return (
    <div className={style.pageContainer}>
      {lang === 'am' && (
        <div>
          <h1>Autobuy LLC-ի մասին</h1>
          <div className={style.textContainer}>
            <p>
              Autobuy ՍՊԸ-ն միջազգային ավտոտրանսպորտային ծառայություն է, որը հիմնված է Հայաստանում: Արդյունաբերության մեջ տարիների փորձով մենք համբավ ենք վաստակել ամբողջ աշխարհում մեքենաների տեղափոխման արդյունավետ և հուսալի լուծումներ տրամադրելու համար:
            </p>
            <p>
              Autobuy ՍՊԸ-ում մենք հպարտանում ենք հաճախորդների բավարարվածության մեր հանձնառությամբ: Լոգիստիկայի ոլորտի փորձագետների մեր նվիրված թիմը երաշխավորում է, որ ձեր մեքենան վարվում է առավելագույն խնամքով և ժամանակին և կատարյալ վիճակում տեղափոխվում է նպատակակետ:
            </p>
            <p>
              Մենք հասկանում ենք ձեր մեքենայի կարևորությունը, և այդ իսկ պատճառով մենք առաջարկում ենք համապարփակ ապահովագրություն՝ ձեր ակտիվը տարանցման ժամանակ պաշտպանելու համար: Մեր իրական ժամանակում հետևելու համակարգը թույլ է տալիս վերահսկել ձեր տեղափոխման կարգավիճակը՝ ապահովելով ձեզ հանգիստ ողջ գործընթացի ընթացքում:
            </p>
            <p>
              Անկախ նրանից՝ դուք մեքենայի գնորդ եք, դիլեր կամ անհատ, ով մեքենայի փոխադրման կարիք ունի, Autobuy ՍՊԸ-ն այստեղ է՝ գործընթացը հեշտ և առանց սթրեսի դարձնելու համար: Մենք հավատարիմ ենք տրամադրելու անհատականացված լուծումներ՝ ձեր եզակի կարիքները բավարարելու համար, և հաճախորդների աջակցության մեր ընկերական թիմը հասանելի է ձեզ ամեն քայլափոխի աջակցելու համար:
            </p>
            <p>Որպես միջազգային ավտոտրանսպորտի ծառայություն, Autobuy LLC-ն հպարտանում է անխափան և անվտանգ առաջարկով
              մեքենաների տեղափոխման լուծումներ մեքենաների գնորդներին, դիլերներին և անհատներին, ովքեր ցանկանում են տեղափոխել իրենց տրանսպորտային միջոցները
              սահմանները։ Արդյունաբերության մեջ տարիների փորձով մենք հաստատվել ենք որպես վստահելի անուն
              ավտոտրանսպորտի ոլորտ. Մեր առաքելությունն է պարզեցնել մեքենաների տեղափոխման գործընթացը և ապահովել մեր արժեքները
              հաճախորդներ, ովքեր ունեն անհանգիստ փորձ: Մենք հասկանում ենք ձեր մեքենայի նշանակությունը և նվիրված ենք
              ապահովելու համար դրա անվտանգ և ժամանակին տեղափոխումը նպատակակետ:</p>

            <h2>Մեր մեքենաների տեղափոխման ծառայությունները</h2>
            <ul>
              <li>Միջազգային ավտոմեքենաների տեղափոխում</li>
              <li>Փակ և բաց կրիչներ</li>
              <li>Ապահովագրության համապարփակ ծածկույթ</li>
              <li>Իրական ժամանակում հետևում</li>
            </ul>

            <h2>Ինչպես կազմակերպել մեքենայի տեղափոխում</h2>
            <ol>
              <li>Պահանջեք գնանշում. կապվեք մեզ հետ հեռախոսով +374 60 670000 հասցեով կամ էլեկտրոնային փոստով մեզ support@autobuy.am հասցեով՝
                խնդրեք անհատականացված գնանշում: Կիսվեք մանրամասներով ձեր մեքենայի և նախատեսված վերցնելու և տեղափոխման մասին
                վայրերը.
              </li>
              <li>Ստացեք ձեր գնանշումը. մեր թիմը անմիջապես կտրամադրի ձեզ հարմարեցված գնանշում` հիմնված ձեր կոնկրետության վրա
                մեքենաների տեղափոխման պահանջները.
              </li>
              <li>Պլանավորեք տեղափոխումը. Երբ գնանշումը հաստատեք, մենք միասին կաշխատենք ամենահարմարը պլանավորելու համար
                ձեր մեքենայի վերցնելու և տեղափոխման ամսաթվերը:
              </li>
              <li>Անվտանգ և ժամանակին փոխադրումներ. մեր փորձառու լոգիստիկ թիմը կզբաղվի բոլոր անհրաժեշտ պայմանավորվածություններով
                Ձեր մեքենայի անվտանգ և ճշգրիտ փոխադրման համար:
              </li>
              <li>Տեղափոխման հաստատում. վստահ եղեք, որ ձեր մեքենան կտեղափոխվի նշանակված վայր
                ապահով կերպով, և դուք կստանաք դրա ժամանման հաստատում:
              </li>
            </ol>

            <h2>Ինչու՞ ընտրել Autobuy LLC-ն մեքենայի տեղափոխման համար:</h2>
            <ul>
              <li>Փորձ և վստահություն. Մեր մեծ փորձի շնորհիվ Autobuy LLC-ն անուն է, որին կարող եք վստահել մեքենայի համար
                տրանսպորտային ծառայություններ.
              </li>
              <li>Նվիրված հաճախորդների սպասարկում. հաճախորդների աջակցության մեր ընկերասեր և բանիմաց թիմը հասանելի է
                օգնել ձեզ գործընթացի յուրաքանչյուր փուլում:
              </li>
              <li>Անվտանգ փոխադրում. մենք առաջնահերթություն ենք տալիս ձեր մեքենայի և մեր ամբողջությամբ ապահովագրված տրանսպորտի անվտանգությանը
                երաշխավորում է մտքի խաղաղությունը:
              </li>
              <li>Հարմարված լուծումներ. Autobuy LLC-ում մենք տրամադրում ենք անհատականացված լուծումներ՝ ձեր եզակի մեքենայի տեղափոխումը բավարարելու համար
                կարիքները.
              </li>
            </ul>
          </div>
          <Map/>
        </div>
      )}
      {lang === 'ru' && (
        <div>
          <h1>Об ООО "Автобай"</h1>
          <div className={style.textContainer}>
            <p>
              Autobuy LLC — международная служба автомобильных перевозок, базирующаяся в Армении. Имея многолетний опыт работы в отрасли, мы заработали репутацию поставщика эффективных и надежных решений по доставке автомобилей клиентам по всему миру.
            </p>
            <p>
              В Autobuy LLC мы гордимся своей приверженностью удовлетворенности клиентов. Наша преданная команда экспертов по логистике гарантирует, что ваш автомобиль будет обработан с максимальной осторожностью и доставлен в пункт назначения вовремя и в отличном состоянии.
            </p>
            <p>
              Мы понимаем важность вашего автомобиля, поэтому предлагаем комплексное страховое покрытие для защиты вашего имущества во время перевозки. Наша система отслеживания в режиме реального времени позволяет вам отслеживать статус вашего груза, обеспечивая вам душевное спокойствие на протяжении всего процесса.
            </p>
            <p>
              Являетесь ли вы покупателем автомобиля, дилером или частным лицом, нуждающимся в транспортировке автомобиля, Autobuy LLC здесь, чтобы сделать этот процесс легким и беззаботным. Мы стремимся предоставлять персонализированные решения для удовлетворения ваших уникальных потребностей, а наша дружная команда поддержки клиентов готова помочь вам на каждом этапе пути.
            </p>
            <p>Предоставляя услуги по перевозке автомобилей по всему миру, компания Autobuy LLC гордится тем, что предлагает беспроблемные и безопасные услуги.
              решения по доставке автомобилей для покупателей автомобилей, дилеров и частных лиц, желающих перевезти свои автомобили через
              границы. Имея многолетний опыт работы в отрасли, мы зарекомендовали себя как надежное имя в
              сфере автомобильных перевозок. Наша миссия состоит в том, чтобы упростить процесс доставки автомобиля и предоставить нашим ценным
              клиентов с беспроблемным опытом. Мы понимаем важность вашего автомобиля и преданы
              обеспечить его безопасную и своевременную доставку к месту назначения.</p>

            <h2>Наши услуги по доставке автомобилей</h2>
            <ul>
              <li>Международная доставка автомобилей</li>
              <li>Закрытые и открытые носители</li>
              <li>Комплексное страховое покрытие</li>
              <li>Отслеживание в реальном времени</li>
            </ul>

            <h2>Как организовать отправку автомобиля</h2>
            <ol>
              <li>Запрос коммерческого предложения: свяжитесь с нами по телефону +374 60 670000 или напишите нам по адресу support@autobuy.am
                запросить индивидуальное предложение. Поделитесь подробностями о вашем транспортном средстве и предполагаемом пикапе и доставке
                места.
              </li>
              <li>Получите ваше предложение: наша команда быстро предоставит вам индивидуальное предложение на основе ваших конкретных
                Требования к перевозке автомобиля.
              </li>
              <li>Запланируйте отгрузку: как только вы одобрите предложение, мы будем работать вместе, чтобы запланировать наиболее удобный
                даты забора и доставки вашего автомобиля.
              </li>
              <li>Безопасная и своевременная транспортировка: наша опытная команда логистики возьмет на себя все необходимые меры.
                для безопасной и пунктуальной перевозки вашего автомобиля.
              </li>
              <li>Подтверждение доставки: будьте уверены, что ваш автомобиль будет доставлен в указанное место назначения.
                благополучно, и вы получите подтверждение о его прибытии.
              </li>
            </ol>

            <h2>Почему стоит выбрать ООО «Автобай» для перевозки автомобилей?</h2>
            <ul>
              <li>Опыт и доверие. Благодаря нашему обширному опыту компания Autobuy LLC— это имя, которому вы можете доверять.
                транспортные услуги.
              </li>
              <li>Специальная служба поддержки клиентов. Наша дружелюбная и компетентная команда поддержки клиентов всегда готова помочь
                помощь на каждом этапе процесса.
              </li>
              <li>Безопасная транспортировка. Мы уделяем первостепенное внимание безопасности вашего автомобиля и полностью застрахованному транспорту.
                гарантирует спокойствие.
              </li>
              <li>Индивидуальные решения. Компания Autobuy LLC предлагает персонализированные решения для перевозки вашего уникального автомобиля.
                потребности.
              </li>
            </ul>
          </div>
          <Map/>
        </div>
      )}
      {lang === 'en' && (
        <div>
          <h1>Welcome to Autobuy LLC</h1>
          <div className={style.textContainer}>
            <p>As an international car transportation service, Autobuy LLC takes pride in offering seamless and secure
              car shipment solutions to car buyers, dealers, and individuals looking to move their vehicles across
              borders. With years of experience in the industry, we have established ourselves as a trusted name in the
              field of car transportation. Our mission is to simplify the car shipment process and provide our valued
              customers with a hassle-free experience. We understand the significance of your vehicle and are dedicated
              to ensuring its safe and timely delivery to its destination.</p>

            <h2>Our Car Shipment Services</h2>
            <ul>
              <li>International Car Shipping</li>
              <li>Enclosed and Open Carriers</li>
              <li>Comprehensive Insurance Coverage</li>
              <li>Real-Time Tracking</li>
            </ul>

            <h2>How to Arrange Car Shipment</h2>
            <ol>
              <li>Request a Quote: Contact us via phone at +374 60 670000 or email us at support@autobuy.am to
                request a personalized quote. Share details about your vehicle and the intended pickup and delivery
                locations.
              </li>
              <li>Receive Your Quote: Our team will promptly provide you with a customized quote based on your specific
                car shipment requirements.
              </li>
              <li>Schedule the Shipment: Once you approve the quote, we'll work together to schedule the most convenient
                pickup and delivery dates for your vehicle.
              </li>
              <li>Safe and Timely Transportation: Our experienced logistics team will handle all necessary arrangements
                for the secure and punctual transportation of your vehicle.
              </li>
              <li>Delivery Confirmation: Rest assured that your vehicle will be delivered to the designated destination
                safely, and you'll receive confirmation of its arrival.
              </li>
            </ol>

            <h2>Why Choose Autobuy LLC for Car Shipment?</h2>
            <ul>
              <li>Experience and Trust: With our extensive experience, Autobuy LLC is a name you can trust for car
                transportation services.
              </li>
              <li>Dedicated Customer Support: Our friendly and knowledgeable customer support team is available to
                assist you at every stage of the process.
              </li>
              <li>Secure Transportation: We prioritize the security of your vehicle, and our fully insured transport
                guarantees peace of mind.
              </li>
              <li>Tailored Solutions: At Autobuy LLC, we provide personalized solutions to meet your unique car shipment
                needs.
              </li>
            </ul>
          </div>
          <Map/>
        </div>
      )}
    </div>
  );
};

export default AboutUs;
