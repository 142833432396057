import { getModels, getOptions, getTrim } from '../../api/requests';
import years from '../../assets/years';
import { push } from 'connected-react-router';

const initialState = [
  {
    name: 'bodyType',
    options: [],
    defaultValue: [],
  },
  {
    name: 'make',
    placeholder: 'Choose Make',
    options: [],
    defaultValue: null,
    belongs: 1,
    smallSearch: 1,
  },
  {
    name: 'model',
    placeholder: 'Choose Model',
    options: [],
    defaultValue: null,
    belongs: 1,
    smallSearch: 1,
  },
  //{ name: 'engineCap', placeholder: 'Engine Capacity', options: [], defaultValue: null, belongs: 1 },

  {
    name: 'yearFrom',
    placeholder: 'From',
    options: years,
    defaultValue: null,
    belongs: 1,
    smallSearch: 1,
  },
  { name: 'yearTo', placeholder: 'To', options: years, defaultValue: null, belongs: 1, smallSearch: 1 },
  {
    name: 'priceFrom',
    placeholder: 'From',
    defaultValue: null,
    belongs: 1,
    type: 'input',
  },
  {
    name: 'priceTo',
    placeholder: 'To',
    defaultValue: null,
    belongs: 1,
    type: 'input',
  },
  { name: 'milageFrom', placeholder: 'From', options: [], defaultValue: null, type: 'input' },
  { name: 'milageTo', placeholder: 'To', options: [], defaultValue: null, type: 'input' },
  { name: 'engine', placeholder: 'Engine', options: ['Gasoline', 'Diesel'], defaultValue: null },
  { name: 'gearBox', placeholder: 'Gear Box', options: ['Automatic', 'Manual'], defaultValue: null },
  {
    name: 'steeringWheel',
    placeholder: 'Steering Wheel',
    options: [
      { id: 0, name: 'Left' },
      { id: 1, name: 'Right' },
    ],
    defaultValue: null,
  },
  {
    name: 'country',
    placeholder: 'Country',
    options: ['USA', 'Japan', 'Korea', 'Europe', 'Dubai', 'Russia', 'Armenia'],
    defaultValue: null,
  },
  { name: 'color', placeholder: 'Color', options: [], defaultValue: [] },
  {
    name: 'currency',
    options: [
      { id: 0, name: 'USD' },
      { id: 1, name: 'AMD' },
      { id: 2, name: 'RUB' },
    ],
    defaultValue: 0,
  },
  {
    name: 'mileageType',
    options: [
      { id: 0, name: 'Km' },
      { id: 1, name: 'Miles' },
    ],
    defaultValue: 0,
  },
  { name: 'ByParts', text: 'By parts', defaultValue: false },
  { name: 'CustomCleared', text: 'Custom Cleared', defaultValue: true },
  { name: 'Exchange', text: 'Exchange', defaultValue: false },
  { name: 'FromDealers', text: 'From Dealers', defaultValue: false },
  { name: 'currentPage', defaultValue: 0 },
];

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_OPTIONS': {
      return state.map((obj) => {
        if (obj.name === 'bodyType') {
          return { ...obj, options: action.data.bodyType };
        }
        if (obj.name === 'make') {
          return { ...obj, options: [{ id: 0, name: 'All' }, ...action.data.make] };
        }
        if (obj.name === 'engine') {
          return { ...obj, options: action.data.engine };
        }
        if (obj.name === 'gearBox') {
          return { ...obj, options: action.data.gearbox };
        }
        if (obj.name === 'color') {
          return { ...obj, options: action.data.color };
        } else {
          return obj;
        }
      });
    }
    case 'SET_MODELS': {
      return state.map((obj) => {
        if (obj.name === 'model') {
          return { ...obj, options: action.data, defaultValue: null };
        }
        if (obj.name === 'engineCap') {
          return { ...obj, defaultValue: null };
        } else {
          return obj;
        }
      });
    }
    case 'SET_TRIM': {
      return state.map((obj, i) => {
        if (obj.name === 'engineCap') {
          return { ...obj, options: action.data, defaultValue: null };
        } else {
          return obj;
        }
      });
    }
    case 'SAVE_SEARCH_DATA': {
      return state.map((value) => {
        if (action?.data?.[value?.name]) {
          return { ...value, defaultValue: action?.data?.[value?.name] };
        }
        return value;
      });
    }
    case 'SAVE_URL_DATA': {
      return state.map((value) => {
        let urlVal;
        Object.keys(action.data).forEach((key) => {
          if (value.name === key) {
            urlVal = action.data[key];
          }
        });
        return { ...value, defaultValue: urlVal };
      });
    }
    case 'LOCATION_CHANGE': {
      return state;
    }
    case 'SET_YEAR_TO': {
      return state.map((value) => {
        if (value.name === 'yearTo') {
          return {
            ...value,
            options: initialState[4].options.filter((option) => option >= action.yearFrom),
          };
        }
        return value;
      });
    }
    case 'SET_YEAR_FROM': {
      return state.map((value) => {
        if (value.name === 'yearFrom') {
          return {
            ...value,
            options: initialState[5].options.filter((option) => option <= action.yearTo),
          };
        }
        return value;
      });
    }
    case 'CHANGE_BODY': {
      return state.map((value) => {
        if (value.name === 'bodyType') {
          return {
            ...value,
            defaultValue: !value.defaultValue.includes(action.id)
              ? [...value.defaultValue, action.id]
              : value.defaultValue.filter((val) => val !== action.id),
          };
        }
        return value;
      });
    }
    case 'ADD_COLOR': {
      return state.map((value) => {
        if (value.name === 'color') {
          return { ...value, defaultValue: [...value.defaultValue, action.color] };
        }
        return value;
      });
    }
    case 'REMOVE_COLOR': {
      return state.map((value) => {
        if (value.name === 'color') {
          return { ...value, defaultValue: value.defaultValue.filter((val) => val !== action.color) };
        }
        return value;
      });
    }

    case 'CHANGE_SWITCH_VALUE': {
      return state.map((value) => {
        if (value.name === action.switchValue.name) {
          return { ...value, defaultValue: action.switchValue.checked };
        }
        return value;
      });
    }

    case 'RESTORE_SEARCH_DEFAULT': {
      return state.map((obj) => {
        if (obj.name === 'bodyType' || obj.name === 'color') {
          return { ...obj, defaultValue: [] };
        }
        if (obj.name === 'currency' || obj.name === 'mileageType') {
          return { ...obj, defaultValue: 0 };
        }
        if (obj.name === 'ByParts' || obj.name === 'Exchange' || obj.name === 'FromDealers') {
          return { ...obj, defaultValue: false };
        }
        if (obj.name === 'CustomCleared') {
          return { ...obj, defaultValue: true };
        }
        return { ...obj, defaultValue: null };
      });
    }
    case 'SET_CURRENT_PAGE': {
      return state.map((obj) => {
        return obj.name === 'currentPage' ? { ...obj, defaultValue: action.page } : obj;
      });
    }
    default:
      return state;
  }
};

//action
export const saveSearchData = (data, pushStatus, query = {}) => {
  return (dispatch, getState) => {
    dispatch({ type: 'SAVE_SEARCH_DATA', data });
    const state = getState();
    const url = query ? query : state.search
      .map((obj) => {
        if (Array.isArray(obj.defaultValue) && obj.defaultValue.length === 0) return;
        if (obj.defaultValue) {
          return `${obj.name}=${obj.defaultValue}`;
        }
      }).filter(( element ) => {
        return element !== undefined;
      }).join('&');
    pushStatus && dispatch(push(`/cars?${url}`));
  };
};

export const saveUrlData = (data) => {
  return { type: 'SAVE_URL_DATA', data };
};

export const setOptions = () => {
  return (dispatch) =>
    getOptions().then((res) => {
      if (res && res.status === 200) {
        const data = res.data;
        dispatch({ type: 'SET_OPTIONS', data });
      }
    });
};

export const setModels = (makeId) => {
  return (dispatch) =>
    getModels(makeId).then((res) => {
      if (res && res.status === 200) {
        const data = res.data;
        dispatch({ type: 'SET_MODELS', data });
      }
    });
};

export const setTrim = (modelId) => {
  return (dispatch) =>
    getTrim(modelId).then((res) => {
      if (res && res.status === 200) {
        // const data = res.data;
        //dispatch({ type: 'SET_TRIM', data });
      }
    });
};

export const setYearTo = (yearFrom) => {
  return { type: 'SET_YEAR_TO', yearFrom };
};

export const setYearFrom = (yearTo) => {
  return { type: 'SET_YEAR_FROM', yearTo };
};

export const changeBodyType = (id, pushStatus) => {
  return (dispatch, getState) => {
    ////console.log(id, typeof id);
    dispatch({ type: 'CHANGE_BODY', id });
    const state = getState();
    const url = state.search
      .map((obj) => {
        return `${obj.name}=${obj.defaultValue}`;
      })
      .join('&');

    pushStatus && dispatch(push(`/cars?${url}`));
  };
};

export const addColor = (color, pushStatus) => {
  return (dispatch, getState) => {
    dispatch({ type: 'ADD_COLOR', color });
    const state = getState();
    const url = state.search
      .map((obj) => {
        return `${obj.name}=${obj.defaultValue}`;
      })
      .join('&');

    pushStatus && dispatch(push(`/cars?${url}`));
  };
};

export const removeColor = (color, pushStatus) => {
  return (dispatch, getState) => {
    dispatch({ type: 'REMOVE_COLOR', color });
    const state = getState();
    const url = state.search
      .map((obj) => {
        return `${obj.name}=${obj.defaultValue}`;
      })
      .join('&');

    pushStatus && dispatch(push(`/cars?${url}`));
  };
};

export const clearFilters = (location) => {
  return (dispatch, getState) => {
    dispatch({ type: 'RESTORE_SEARCH_DEFAULT' });
    const state = getState();
    const url = state.search
      .map((obj) => {
        return `${obj.name}=${obj.defaultValue}`;
      })
      .join('&');
    location !== '/' && dispatch(push(`/cars?${url}`));
  };
};

export const changeSwitch = (switchValue) => {
  return (dispatch, getState) => {
    dispatch({ type: 'CHANGE_SWITCH_VALUE', switchValue });
    const state = getState();
    const url = state.search
      .map((obj) => {
        return `${obj.name}=${obj.defaultValue}`;
      })
      .join('&');
    dispatch(push(`/cars?${url}`));
  };
};

export default searchReducer;
