import { Input, Select, Radio } from "antd";
import React from "react";
import { Controller } from "react-hook-form";
import { getTransportPrice } from "../../../../../api/requests";
import { calcCopartFob, calcIaaFob } from "../../../../../Helpers/helpers";
import style from "./style.module.scss";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";

const { Option } = Select;

const Section1 = ({
  control,
  fobs,
  locations,
  errors,
  clearErrors,
  prices,
  setPrices,
  getValues,
  setValue,
  details,
  setDetails,
}) => {
  const initialValues = getValues();
  const handleLocationSelect = (option) => {
    if (option === 0) {
      setPrices({ ...prices, transport: 0, notes: null });
      setDetails({ ...details, location: null });
      return { value: 0 };
    }
    const values = getValues();
    const data = { auction: values.auction, location: option };
    for (let i = 0; i < locations.length; i++) {
      if (locations[i].id === option) {
        setDetails({ ...details, location: locations[i].location });
        break;
      }
    }
    getTransportPrice(data).then((res) => {
      const { fee, notes } = res.data;
      setPrices({ ...prices, transport: fee, notes });
    });

    return { value: option };
  };
  const handlePersonType = (option) => {
    setDetails({ ...details, isJuridical: option.target.value });
    setValue("isJuridical", parseInt(option.target.value));
    return { value: option.target.value };
  };

  const getFob = (e) => {
    setValue("price", e.target.value);
    const values = getValues();
    if (values.auction) {
      setDetails({
        ...details,
        auction: values.auction === 1 ? "IAAI" : "COPART",
      });
      let fob =
        values.auction === 1
          ? calcIaaFob(e.target.value, fobs)
          : calcCopartFob(e.target.value, fobs);
      setPrices({ ...prices, fob: fob });
      clearErrors("price");
    }
  };
  const customPriceField = (e) => {
    if (e.target && e.target.value > 0) {
      setValue("customPrice", parseInt(e.target.value));
      setPrices({
        ...prices,
        transport: 0,
        notes: null,
        customPrice: parseInt(e.target.value),
      });
      clearErrors("customPrice");
    } else {
      setValue("customPrice", null);
    }
  };
  const user = useSelector(
    (state) => (state.user.info ? state.user : { info: { userDetails: {} } }),
    shallowEqual
  );

  const { t } = useTranslation();
  return (
    <div className={style.section1Container}>
      <label htmlFor="personType" className={style.personType}>
        {t("importer")} <span className={style.requiredMark}>*</span>
      </label>
      <div className={style.personType}>
        <Controller
          name="isJuridical"
          control={control}
          defaultValue={details.isJuridical}
          render={() => (
            <Radio.Group
              defaultValue={details.isJuridical}
              onChange={handlePersonType}>
              <Radio.Button value={1}>{t("juridical")}</Radio.Button>
              <Radio.Button value={0} className={style.radioButton}>
                {t("physical")}
              </Radio.Button>
            </Radio.Group>
          )}
        />
      </div>
      <div className={style.flexContainer}>
        <div className={style.firstColumn}>
          <label htmlFor="price">
            {t("car_price")}
            <span className={style.requiredMark}>*</span>
          </label>
          <Controller
            name="price"
            control={control}
            defaultValue={""}
            rules={{ required: true }}
            render={({ value }) => (
              <Input
                type="number"
                onChange={getFob}
                autoComplete={"off"}
                value={value}
                className={
                  errors.hasOwnProperty("price") ? style.errorBorder : ""
                }
              />
            )}
          />
        </div>
        <div className={style.secondColumn}>
          <label htmlFor="fob">{t("fob")}</label>
          <Input name="fob" readOnly={true} value={prices.fob} />
        </div>
      </div>
      <div className={style.personType}>
        <label htmlFor="location">
          {t("auc_loc")} <span className={style.requiredMark}>*</span>
        </label>
        <Controller
          name="location"
          control={control}
          defaultValue={0}
          rules={{ required: true }}
          as={
            <Select
              showSearch
              onSelect={handleLocationSelect}
              popupClassName={style.dropDown}
              className={
                errors.hasOwnProperty("location") ? style.errorBorder : ""
              }
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }>
              <Option key={"empty-loc"} value={0}>
                &nbsp;
              </Option>
              {locations?.map((option) => (
                <Option key={option.id} value={option.id}>
                  {option.location}
                </Option>
              ))}
            </Select>
          }
        />
      </div>
      {user && user.info && user.info.id && (
        <div className={style.personType}>
          <label htmlFor="transport">
            {prices.notes === "call" ? t("call_for_price") : t("tr_fee")}
          </label>
          {initialValues.location !== 0 ? (
            <Input
              name="transport"
              readOnly={true}
              value={
                user && user.info && user.info.id ? prices.transport : "******"
              }
            />
          ) : (
            <Controller
              name="customPrice"
              control={control}
              defaultValue={false}
              rules={{ required: true }}
              render={({ value }) => (
                <Input
                  type="number"
                  onChange={customPriceField}
                  autoComplete={"off"}
                  value={value}
                  className={
                    errors.hasOwnProperty("customPrice")
                      ? style.errorBorder
                      : ""
                  }
                />
              )}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Section1;
