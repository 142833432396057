import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { notification, Popconfirm } from "antd";
import {
  deleteUserCar,
  removeFromFavourites,
} from "../../../../../redux/Reducers/userReducer";
import Button from "../../../../../components/Common/Buttons/ButtonSmall";
import EditIcon from "../../../../../components/Common/Icons/edit";
import TrashIcon from "../../../../../components/Common/Icons/trash";
const BASE_URL = process.env.REACT_APP_BASE_URL || "http://localhost:4000";
function formatMoney(amount, locale = "en-US", currency = "USD") {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);
}
const HotOfferItem = ({
  item,
  inAnnouncements,
  inFavourites,
}) => {
  const [hovered, setHovered] = useState(false);
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  let firstMedia = "";

  if (item?.carMedia?.length) {
    firstMedia =
      `${BASE_URL}` +
      item.carMedia[0].carImage.location +
      "/" +
      item.carMedia[0].carImage.name +
      "medium.webp";
    if (item.firstMedia && item.firstMedia.length) {
      firstMedia =
        `${BASE_URL}` +
        item.firstMedia[0].carImage.location +
        "/" +
        item.firstMedia[0].carImage.name +
        "medium.webp";
    }
  }

  const openNotification = (text, type) => {
    notification[type]({
      description: text,
      className: "notification",
    });
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    const makeId = item.carMake.id;
    const modelId = item.carModel.id;
    history.push({ pathname: `/sell/${item.id}`, state: { makeId, modelId } });
  };

  const deleteCar = (e) => {
    e.stopPropagation();
    dispatch(deleteUserCar(item.id, openNotification, i18n.language));
  };

  const handleRemoveFromFavourites = (e) => {
    e.stopPropagation();
    dispatch(removeFromFavourites(item.id));
  };

  const goToCar = (e) => {
    e.stopPropagation();
    history.push(`/car/${item.id}`);
  };
  
  return (
    <div
      className="hot-offer-item"
      onClick={goToCar}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{ cursor: "pointer" }}>
      <div className="hot-offer-image">
          <img className="img" src={`${item?.image || firstMedia}`} />
        {hovered && <Button className={'hoverBtn'} innerText={t("view_more")} />}
        {item?.hotCar === 1 && <div className="hot-badge">{t("hot")}</div>}
      </div>
      <div className="hot-offer-info">
        <h3>
          {item?.year} {item?.carMake?.name} {item?.carModel?.name}
        </h3>
        <p>{(item?.price && formatMoney(item.price)) || t("onAgree")}</p>
      </div>
      {inAnnouncements && (
        <div className="iconsWrapper">
          <EditIcon onClick={handleEdit} />
          <Popconfirm
            onClick={(e) => e.stopPropagation()}
            onCancel={(e) => e.stopPropagation()}
            onConfirm={deleteCar}
            title={t("del_car")}
            okText={t("yes")}
            cancelText={t("no")}
            overlayClassName={"popConfirm"}>
            <TrashIcon />
          </Popconfirm>
        </div>
      )}
      {inFavourites && (
        <div>
          <Popconfirm
            onClick={(e) => e.stopPropagation()}
            onCancel={(e) => e.stopPropagation()}
            onConfirm={handleRemoveFromFavourites}
            title={t("del_favs")}
            okText={t("yes")}
            cancelText={t("no")}
            overlayClassName={undefined}
          >
            <TrashIcon />
          </Popconfirm>
        </div>
      )}
    </div>
  );
};

export default HotOfferItem;
