import React, { useState } from 'react';
import Button from '../../../components/Common/Buttons/Button';
import ActivationModal from '../../../components/Common/Modals/ActivationModal/ActivationModal';
import style from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';

const Activation = () => {
  const [modalState, setModalState] = useState({ isVisible: false, innerContent: '' });
  const info = useSelector((state) => state.user.info, shallowEqual);
  const activateMobile = () => {
    setModalState({ innerContent: 'mobile', isVisible: true });
  };
  const activateEmail = () => {
    ////console.log('activate email');
    setModalState({ innerContent: 'email', isVisible: true });
  };
  const { t } = useTranslation();
  ////console.log(info);
  return (
    <div className={style.activation}>
      <h1>{t('activate_txt')}</h1>
      {info.mobile && info.mobile.length > 0 && info.mobileConfirmed === 0 && (
        <Button innerText={t('mobile_btn')} handleClick={activateMobile} />
      )}
      {(!info.email || info.emailConfirmed === 0) && <Button innerText={t('email_btn')} handleClick={activateEmail} />}
      <ActivationModal modalState={modalState} setModalState={setModalState} />
    </div>
  );
};

export default Activation;
