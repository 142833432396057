import React, {useEffect, useRef} from 'react';
import {useDispatch} from 'react-redux';
import {setSellOptions, editCar} from '../../redux/Reducers/sellReducer';
import FormBlock from './FormBlock/FormBlock';
import MainBlock from './MainBlock/MainBlock';
import style from './style.module.scss';

const SellPage = (props) => {
  ////console.log(props);
  // eslint-disable-next-line
  const id = parseInt(props.match.params.id);
  const {makeId, modelId} = props.location.state || {makeId: 0, modelId: 0};
  const scrollRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    dispatch(setSellOptions());
    id > 0 && makeId > 0 && modelId > 0 && dispatch(editCar(id, makeId, modelId));
    return () => {
      dispatch({type: 'RESTORE_DEFAULT'});
    };
  }, [dispatch, id, makeId, modelId]);

  return (
    <div className={style.pageContainer}>
      {(!id || id < 1) && (<MainBlock scrollRef={scrollRef}/>)}
      <div ref={scrollRef}></div>
      <FormBlock id={id}/>
    </div>
  );
};

export default SellPage;
