import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { shallowEqual, useSelector } from 'react-redux';
import InputWrapper from './InputWrapper';

const PhoneWrapper = ({ setValue, error, control, id }) => {
  const mobile = useSelector(
    (state) => (state.user.info && state.user.info.mobile ? state.user.info.mobile.substring(4) : ''),
    shallowEqual
  );
  const carMobile = useSelector(
    (state) => (state.sell[20].defaultValue ? state.sell[20].defaultValue.substring(4) : ''),
    shallowEqual
  );

  useEffect(() => {
    id === 0 ? setValue('Phone', mobile) : setValue('Phone', carMobile);
  }, [setValue, id, mobile, carMobile]);

  return (
    <Controller
      name='Phone'
      control={control}
      defaultValue={id === 0 ? mobile : carMobile}
      as={<InputWrapper error={error} value={id === 0 ? mobile : carMobile} />}
      rules={{
        required: true,
      }}
    />
  );
};

export default PhoneWrapper;
