import {push} from 'connected-react-router';
import {
    getChartData,
    getDealerCarDetails,
    getDealerCars,
    getSubDealers,
    getTAccount,
    getTDept
} from '../../api/requests';

const initialState = {
    pagination: {
        current: 1,
        pageSize: 25,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        showSizeChanger: false,
    },
    loading: false,
    sorter: {field: 'bought_day', order: 'desc'},
    filters: {},
    chartFilters: {},
    previewImages: [],
    searchElements: {},
    chart: {},
    report: {sumInvoicesBefore:0,sumBefore:0,totals:{credit:0,debit:0}},
    tDept: {},
    subDealers: [],
    boughtCars: [
        {
            key: 0,
            carMedia: [],
            vin: '',
            lot: '',
            buy_source: '',
            buy_day: '',
            buy_location: '',
            load_day: '',
            port: '',
            deliver_date: '',
        },
    ],
    dealerCarDetails: {
        key: 0,
        carMake: {},
        carModel: {},
        year: 0,
        carMedia: [],
        vin: '',
        lot: '',
        buy_source: '',
        buy_day: '',
        buy_location: '',
        load_day: '',
        port: '',
        deliver_date: '',
    },
};

const dealerCarsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_DEALER_CARS': {
            return {...state, boughtCars: [...action.cars]};
        }
        case 'SET_SUB_DEALERS': {
            return {...state, subDealers: [...action.subDealers]};
        }
        case 'SET_CHART': {
            return {...state, chart: [...action.chart]};
        }
        case 'SET_T_ACCOUNT': {
            return {...state, report: {...action.report}};
        }
        case 'SET_T_DEPT': {
            return {...state, tDept: {...action.tDept}};
        }
        case 'SET_CAR_IMAGES': {
            return {...state, previewImages: [...action.images]};
        }
        case 'SET_PAGINATION': {
            return {...state, pagination: {...action.pagination}};
        }
        case 'SET_DEALER_CARS_LOADING': {
            return {...state, loading: action.loading};
        }
        case 'SET_SEARCH_ELEMENTS': {
            return {...state, searchElements: {...action.items}};
        }
        case 'SET_DEALER_CAR_DETAILS': {
            return {...state, dealerCarDetails: {...action.car}};
        }
        default:
            return state;
    }
};

//action
export const setDealerCarImages = (images = []) => {
    return (dispatch) => {
        dispatch({type: 'SET_CAR_IMAGES', images});
    };
};

export const setChart = (year) => {
    return (dispatch) => {
        dispatch({type: 'SET_DEALER_CARS_LOADING', loading: true});
        getChartData(year).then((res) => {
            if (!res || res.status === 401) {
                dispatch({type: 'LOG_OUT'});
                dispatch(push('/'));
            } else {
                const result = res.data;
                if (result) {
                    if(result && !result.message && result.length > 0) {
                       // get car_count from result
                        const car_count = result.map((item) => {
                            return item.car_count;
                        });
                        const series = [
                            {
                                data: car_count,
                            }
                        ];
                        return dispatch({type: 'SET_CHART', chart: series});
                    }
                }
                dispatch({type: 'SET_CHART', chart: []});
            }
        }).finally(() => {
            dispatch({type: 'SET_DEALER_CARS_LOADING', loading: false});
        });
    };
}
export const setTAccount = (startDate, endDate) => {
    return (dispatch) => {
        dispatch({type: 'SET_DEALER_CARS_LOADING', loading: true});
        getTAccount(startDate, endDate).then((res) => {
            if (!res || res.status === 401) {
                dispatch({type: 'LOG_OUT'});
                dispatch(push('/'));
            } else {
                const result = res.data;
                console.log(result);
                if (result) {
                    if(result && !result.error) {
                        return dispatch({type: 'SET_T_ACCOUNT', report: result});
                    }
                }
                dispatch({type: 'SET_T_ACCOUNT', report: null});
            }
        }).finally(() => {
            dispatch({type: 'SET_DEALER_CARS_LOADING', loading: false});
        });
    };
}
export const setTDept = (startDate, endDate) => {
    return (dispatch) => {
        dispatch({type: 'SET_DEALER_CARS_LOADING', loading: true});
        getTDept(startDate, endDate).then((res) => {
            if (!res || res.status === 401) {
                dispatch({type: 'LOG_OUT'});
                dispatch(push('/'));
            } else {
                const result = res.data;
                if (result) {
                    if(result && !result.error) {
                        return dispatch({type: 'SET_T_DEPT', tDept: result});
                    }
                }
                dispatch({type: 'SET_T_DEPT', tDept: null});
            }
        }).finally(() => {
            dispatch({type: 'SET_DEALER_CARS_LOADING', loading: false});
        });
    };
}
export const setDealerCars = (
    pagination,
    filters,
    sorter,
    searchElements = {},
) => {
    return (dispatch) => {
        dispatch({type: 'SET_DEALER_CARS_LOADING', loading: true});
        getDealerCars(pagination, filters, sorter, searchElements)
            .then((res) => {
                if (!res || res.status === 401) {
                    dispatch({type: 'LOG_OUT'});
                    dispatch(push('/'));
                } else {
                    const result = res.data.rows;
                    if (result) {
                        const cars = result.map((car) => {
                            //console.log(car, typeof car.buy_day, car.buy_day.substring(0, 10));
                            return {
                                ...car,
                                key: car.id,
                                buy_day: car.buy_day
                                    ? car.buy_day.substring(0, 10).replaceAll('-', '.')
                                    : '',
                                load_day: car.load_day
                                    ? car.load_day.substring(0, 10).replaceAll('-', '.')
                                    : '',
                                deliver_date: car.deliver_date
                                    ? car.deliver_date.substring(0, 10).replaceAll('-', '.')
                                    : '',
                            };
                        });
                        const pagination = {
                            current: res.data.currentPage + 1,
                            pageSize: 25,
                            total: res.data.totalItems,
                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                            showSizeChanger: false,
                        };
                        dispatch({type: 'SET_PAGINATION', pagination});
                        dispatch({type: 'SET_DEALER_CARS', cars});
                    }
                }
            })
            .finally(() => {
                dispatch({type: 'SET_DEALER_CARS_LOADING', loading: false});
            });
    };
};

export const setSubDealers = () => {
    return (dispatch) => {
        getSubDealers().then((res) => {
            if (!res || res.status === 401) {
                dispatch({type: 'LOG_OUT'});
                dispatch(push('/'));
            } else {
                const result = res.data;
                if (result) {
                    const subDealers = result.map((dealer) => {
                        return {
                            key: dealer.dealerId,
                            dealerName: dealer.dealer.username,
                        };
                    });
                    dispatch({type: 'SET_SUB_DEALERS', subDealers});
                }
            }
        });
    }
}

export const setDealerCarDetails = (id, setIsVisible) => {
    return (dispatch) => {
        setIsVisible(true);
        getDealerCarDetails(id).then((res) => {
            if (res.status === 401) {
                dispatch({type: 'LOG_OUT'});
                dispatch(push('/'));
            }
            if (!res.data.car) {
                dispatch(push('/'));
            } else {
                //console.log(res);
                const result = res.data.car;
                const car = {
                    ...result,
                    key: result.id,
                    buy_day: result.buy_day
                        ? result.buy_day.substring(0, 10).replaceAll('-', '.')
                        : '',
                    load_day: result.load_day
                        ? result.load_day.substring(0, 10).replaceAll('-', '.')
                        : '',
                    deliver_date: result.deliver_date
                        ? result.deliver_date.substring(0, 10).replaceAll('-', '.')
                        : '',
                };
                //console.log(car);
                dispatch({type: 'SET_DEALER_CAR_DETAILS', car});

                setIsVisible(false);
            }
        });
    };
};

export default dealerCarsReducer;
