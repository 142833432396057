import React, { useState } from "react";
import style from "./style.module.scss";
import ButtonSmall from "../Buttons/ButtonSmall";
import { useHistory } from "react-router-dom";
import EditIcon from "../Icons/edit";
import TrashIcon from "../Icons/trash";
import { useTranslation } from "react-i18next";
import StatusLabel from "./StatusLabel";
import { notification, Popconfirm } from "antd";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  deleteUserCar,
  removeFromFavourites,
} from "../../../redux/Reducers/userReducer";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { formatPrice } from "../../../general-functions";

const CarCard = ({
  inAnnouncements,
  inFavourites,
  id,
  make,
  model,
  price,
  image,
  year,
  shopStatus,
  currency,
}) => {
  const defaultView = useSelector(
    (state) => state.user.defaultView,
    shallowEqual
  );
  const { t, i18n } = useTranslation();
  const [hovered, setHovered] = useState(false);
  const history = useHistory();
  const { pathname } = history.location;
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const openNotification = (text, type) => {
    notification[type]({
      description: text,
      className: style.notification,
    });
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    const makeId = make.id;
    const modelId = model.id;
    history.push({ pathname: `/sell/${id}`, state: { makeId, modelId } });
  };

  const deleteCar = (e) => {
    e.stopPropagation();
    dispatch(deleteUserCar(id, openNotification, i18n.language));
  };

  const handleRemoveFromFavourites = (e) => {
    e.stopPropagation();
    dispatch(removeFromFavourites(id));
  };

  const goToCar = (e) => {
    e.stopPropagation();
    // shopStatus === 1 &&
    history.push(`/car/${id}`);
  };

  return (
    <div
      className={
        width <= 768 && defaultView === 0 && pathname === "/cars"
          ? style.cardContainerList
          : style.cardContainer
      }
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={goToCar}
      style={{
        cursor: (pathname === "/cars" || pathname === "/") && "pointer",
      }}>
      <div
        className={
          width <= 768 && defaultView === 0 && pathname === "/cars"
            ? style.innerContainerList
            : style.innerContainer
        }>
        <div
          className={
            width <= 768 && defaultView === 0 && pathname === "/cars"
              ? style.imgContainerList
              : style.imgContainer
          }>
          {inAnnouncements && <StatusLabel status={shopStatus} />}
          {hovered && <ButtonSmall innerText={t("view_more")} />}
          <img
            src={image}
            alt=""
            style={{
              opacity: hovered ? 0.7 : 1,
              aspectRatio: "16/9",
            }}
          />
        </div>
        <div
          className={
            width <= 768 && defaultView === 0 && pathname === "/cars"
              ? style.infoList
              : style.info
          }>
          <h1 className={style.brand}>
            {year ? `${year} ` : ""}
            {make ? `${make.name} ` : ""} {model ? `${model.name} ` : ""}&nbsp;
          </h1>
          <h1 className={style.price}>
            {price ? `${formatPrice(price)}` : t("onAgree")}
          </h1>
          {inAnnouncements && (
            <div className={style.iconsWrapper}>
              <EditIcon onClick={handleEdit} />
              <Popconfirm
                onClick={(e) => e.stopPropagation()}
                onCancel={(e) => e.stopPropagation()}
                onConfirm={deleteCar}
                title={t("del_car")}
                okText={t("yes")}
                cancelText={t("no")}
                overlayClassName={style.popConfirm}>
                <TrashIcon />
              </Popconfirm>
            </div>
          )}
          {inFavourites && (
            <div className={style.iconsWrapper}>
              <Popconfirm
                onClick={(e) => e.stopPropagation()}
                onCancel={(e) => e.stopPropagation()}
                onConfirm={handleRemoveFromFavourites}
                title={t("del_favs")}
                okText={t("yes")}
                cancelText={t("no")}
                overlayClassName={style.popConfirm}>
                <TrashIcon />
              </Popconfirm>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CarCard;
