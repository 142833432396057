import React, { useState } from 'react';
import style from '../../style.module.scss';
import BodyType from './BodyType';
import cars from '../../../../../assets/cars';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { changeBodyType } from '../../../../../redux/Reducers/searchReducer';
import { changeSellBodyType } from '../../../../../redux/Reducers/sellReducer';
import DownOutlined from '@ant-design/icons/DownOutlined';
import useWindowDimensions from '../../../../../hooks/useWindowDimensions';

const BodyTypesWrapper = React.forwardRef(({ path, errors }, ref) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false); //state for bodyTypes mobile
  const { width } = useWindowDimensions();
  const bodyTypes = useSelector(
    (state) =>
      path !== 'sell'
        ? state.search.filter((field) => field.name === 'bodyType')[0].options
        : state.sell.filter((field) => field.name === 'bodyType')[0].options,
    shallowEqual
  );

  const selectedTypes = useSelector(
    (state) => (path !== 'sell' ? state.search[0].defaultValue : state.sell[0].defaultValue),
    shallowEqual
  );

  const handleOther = () => {
    const pushStatus = path !== '' ? true : false;
    path !== 'sell' ? dispatch(changeBodyType('8', pushStatus)) : dispatch(changeSellBodyType(8));
  };

  const handleBodyTypeView = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={style.bodyTypesContainer}
      style={{ border: path === 'sell' && errors.bodyType && selectedTypes === 0 && '1px solid red' }}
    >
      <label
        className={path !== 'sell' ? `${style.label} ${style.labelBodyType}` : `${style.label}`}
        onClick={handleBodyTypeView}
      >
        {t('choose_bodyType')}
        {width <= 480 && path !== 'sell' && (
          <DownOutlined className={style.arrowDown} style={{ transform: isOpen && 'rotate(-90deg)' }} />
        )}

        {path === 'sell' && <span className={style.asterisk}>*</span>}
      </label>

      <section className={style.bodyTypes} style={{ marginTop: (isOpen || path === 'sell') && '0px' }}>
        <div className={style.otherLabelWrapper}>
          <label
            className={style.other}
            onClick={handleOther}
            style={{
              color:
                path !== 'sell'
                  ? selectedTypes.includes('8')
                    ? '#FF8D4E'
                    : null
                  : selectedTypes === 8
                  ? '#FF8D4E'
                  : null,
            }}
          >
            {t('other_type')}
          </label>
        </div>
        {bodyTypes.map((type, i) => {
          return <BodyType key={i} path={path} img={cars[i].img} innerText={type.name} index={type.id} />;
        })}
      </section>
    </div>
  );
});

export default BodyTypesWrapper;
