import React from "react";
import Icon from "@ant-design/icons";

const SliderNextSvg = () => (
  <svg
    width="80"
    height="8"
    viewBox="0 0 80 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M79.3536 4.35355C79.5488 4.15828 79.5488 3.8417 79.3536 3.64644L76.1716 0.464459C75.9763 0.269197 75.6597 0.269197 75.4645 0.464459C75.2692 0.659722 75.2692 0.976304 75.4645 1.17157L78.2929 3.99999L75.4645 6.82842C75.2692 7.02368 75.2692 7.34027 75.4645 7.53553C75.6597 7.73079 75.9763 7.73079 76.1716 7.53553L79.3536 4.35355ZM4.37114e-08 4.5L79 4.49999L79 3.49999L-4.37114e-08 3.5L4.37114e-08 4.5Z"
      fill="#FF8D4E"
    />
  </svg>
);

const SliderNextIcon = (props) => <Icon component={SliderNextSvg} {...props} />;

export default SliderNextIcon;
