import React from 'react';
import CarouselContainer from './CarouselContainer/CarouselContainer';
import CarInfo from './CarInfo/CarInfo';
import MessageForm from './MessageForm/MessageForm';
import style from './style.module.scss';
import {shallowEqual, useSelector} from "react-redux";

const FirstSection = () => {

  const carDetails = useSelector((state) => state.dealerCars.dealerCarDetails, shallowEqual);
  console.log("carDetails", carDetails)
  return (
    <div className={style.firstSection}>
      {carDetails.carMedia && carDetails.carMedia.length > 0 && <CarouselContainer/>}
      <CarInfo/>
      {false && <MessageForm/>}
    </div>
  );
};

export default FirstSection;
