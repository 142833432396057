import React, { useEffect, useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import style from '../style.module.scss';
import { useForm } from 'react-hook-form';
// import UpIcon from '../../Icons/arrowUp';
import SignupForm from '../../Forms/SignupForm/SignupForm';
import SigninForm from '../../Forms/SigninForm/SigninForm';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Alert, Popover } from 'antd';

//
//receives props from navbar and sellPage
const LoginSignup = ({ modalState, setModalState }) => {
  const { register, handleSubmit, control, setValue } = useForm();
  const history = useHistory();
  const path = history.location.pathname.slice(1, 5);
  // const { t, i18n } = useTranslation();
  const { t } = useTranslation();
  const [alertState, setAlertState] = useState({ visible: false, description: '', type: '', status: 0 }); //alert state

  useEffect(() => {
    if (modalState.newUser) {
      setAlertState({ visible: true, description: t('signup_success'), type: 'success' });
    }
    if (modalState.message.length > 0) {
      setAlertState({ visible: true, description: modalState.message, type: 'info' });
    }

    return () => {
      setAlertState({ visible: false, description: '' });
    };
  }, [modalState.newUser, modalState.message, t]);

  useEffect(() => {
    if (alertState.visible) {
      setTimeout(() => {
        setAlertState((alertState) => {
          return { ...alertState, visible: false };
        });
      }, 5000);
    }
  }, [alertState.visible]);

  return (
    <Modal
      title='Login/Signup Modal'
      centered
      destroyOnClose={false}
      forceRender={true}
      footer={null}
      open={modalState.isVisible}
      width={1071}
      wrapClassName={style.loginSignup}
      maskClosable={path === 'prof' ? false : true}
      onCancel={() => {
        if (path !== 'prof') {
          setModalState({ isVisible: false, message: '' });
        }
      }}
      afterClose={() => {
        setAlertState({ visible: false, description: '' });
      }}
      className={style.loginSignup}
    >
      <Popover
        open={alertState.visible && modalState.isVisible ? true : false}
        overlayClassName={style.popover}
        defaultOpen={false}
        destroyTooltipOnHide={true}
        content={
          <Alert
            showIcon
            closable
            type={alertState.type}
            description={alertState.description}
            className={style.alert}
            onClose={() => setAlertState({ visible: false, description: '' })}
          />
        }
      ></Popover>

      <div className={style.titles}>
        {/* <h1
          onClick={() => {
            setModalState({ ...modalState, initialForm: 'signup' });
            setAlertState({ ...alertState, visible: false, description: '' });
          }}
        >
          {t('signup')}
        </h1> */}
        <h1
          onClick={() => {
            setModalState({ ...modalState, initialForm: 'signin' });
            setAlertState({ ...alertState, visible: false, description: '' });
          }}
        >
          {t('signin')}
        </h1>
      </div>
      {/* <div className={style.arrowWrapper}>
        <UpIcon
          className={
            modalState.initialForm === 'signup'
              ? (i18n.language === 'en' && style.signup) ||
                (i18n.language === 'am' && style.signupArm) ||
                (i18n.language === 'ru' && style.signupRu)
              : (i18n.language === 'en' && style.signin) ||
                (i18n.language === 'am' && style.signinArm) ||
                (i18n.language === 'ru' && style.signinRu)
          }
        />
      </div> */}
      {modalState.initialForm === 'signup' ? (
        <SignupForm
          handleSubmit={handleSubmit}
          register={register}
          control={control}
          setValue={setValue}
          setModalState={setModalState}
          modalState={modalState}
          alertState={alertState}
          setAlertState={setAlertState}
        />
      ) : (
        <SigninForm
          handleSubmit={handleSubmit}
          register={register}
          control={control}
          setValue={setValue}
          setModalState={setModalState}
          modalState={modalState}
          alertState={alertState}
          setAlertState={setAlertState}
        />
      )}
    </Modal>
  );
};

export default LoginSignup;
