import { Input } from 'antd';
import React from 'react';
import style from '../style.module.scss';

const InputWrapper = React.forwardRef(({ error, value, onChange }, ref) => {
  ////console.log(error);
  const handlePhoneChange = (e) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    let regex = /[^0-9.,]+/;
    if (regex.test(keyValue)) {
      e.preventDefault();
    }
  };

  return (
    <Input
      ref={ref}
      value={value}
      type='text'
      addonBefore={'+374'}
      className={!error ? style.phoneInput : style.wrongPhone}
      maxLength={9}
      minLength={8}
      onKeyPress={handlePhoneChange}
      onChange={onChange}
    />
  );
});

export default InputWrapper;
