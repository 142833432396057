import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

const Expenses = () => {
  const dealerCarDetails = useSelector((state) => state.dealerCars.dealerCarDetails, shallowEqual);
  let { price, transportation, insurance, storage, dealer_fee, late_fee, document_fee, custom_fee,sub_lot } = dealerCarDetails;
  price = parseInt(price || 0);
  transportation = parseInt(transportation || 0);
  insurance = parseInt(insurance || 0);
  storage = parseInt(storage || 0);
  dealer_fee = parseInt(dealer_fee || 0);
  late_fee = parseInt(late_fee || 0);
  document_fee = parseInt(document_fee || 0);
  custom_fee = parseInt(custom_fee || 0);
  sub_lot = parseInt(sub_lot || 0);
  return (
    <div>
      <h1>expenses</h1>
      <div>
        {/* <p>{t('placeholder_price')}</p> */}
        <h1>{price ? `$${price}` : '$0'}</h1>
      </div>
      <div>
        {/* <p>{t('t_price')}</p> */}
        <h1>{transportation ? `$${transportation}` : '$0'}</h1>
      </div>
      <div>
        {/* <p>{t('Insurance')}</p> */}
        <h1>{insurance ? `$${insurance}` : '$0'}</h1>
      </div>
      <div>
        {/* <p>{t('Storage')}</p> */}
        <h1>{storage ? `$${storage}` : '$0'}</h1>
      </div>
      <div>
        {/* <p>{t('Dealer fee')}</p> */}
        <h1>{dealer_fee ? `$${dealer_fee}` : '$0'}</h1>
      </div>
      <div>
        {/* <p>{t('Late fee')}</p> */}
        <h1>{late_fee ? `$${late_fee}` : '$0'}</h1>
      </div>
      <div>
        {/* <p>{t('Document fee')}</p> */}
        <h1>{document_fee ? `$${document_fee}` : '$0'}</h1>
      </div>
      <div>
        {/* <p>{t('Custom fee')}</p> */}
        <h1>{custom_fee ? `$${custom_fee}` : '$0'}</h1>
      </div>
      <div>
        {/* <p>{t('Custom fee')}</p> */}
        <h1>{sub_lot ? `$${sub_lot}` : '$0'}</h1>
      </div>
      <div>
        {/* <p>{t('total')}</p> */}
        <h1>{`$${price + transportation + insurance + storage + dealer_fee + late_fee + document_fee + custom_fee + sub_lot}`}</h1>
      </div>
    </div>
  );
};

export default Expenses;
