import React from 'react';
import { Controller } from 'react-hook-form';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import SellInput from '../../../Selects/SellInput';
import ColorPicker from '../../BigForm/ColorPicker';
import style from '../../style.module.scss';
import Questions from '../Questions/Questions';
import { useTranslation } from 'react-i18next';

const Section2 = ({ control, errors, setValue }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const path = history.location.pathname.slice(1, 5);
  ////console.log(path);
  const formInputs = useSelector((state) => state.sell.slice(9, 13), shallowEqual);
  return (
    <div className={style.section2}>
      {formInputs.map((input, i) => {
        return (
          <React.Fragment key={i}>
            {i === 0 && (
              <label className={style.label}>
                {t('choose_options')}
                <span className={style.asterisk}>*</span>
              </label>
            )}
            <Controller
              as={
                <SellInput
                  placeholder={t(`placeholder_${input.name}`)}
                  inputs={formInputs}
                  name={input.name}
                  setValue={setValue}
                  belongsTo='bigForm'
                  errName={errors[input.name]}
                />
              }
              name={input.name}
              defaultValue={input.defaultValue}
              control={control}
              rules={
                input.isRequired && {
                  required: true,
                }
              }
            />
          </React.Fragment>
        );
      })}
      <label className={style.label}>
        {t('choose_color_label')}
        <span className={style.asterisk}>*</span>
      </label>
      {path === 'sell' ? (
        <React.Fragment>
          <ColorPicker
            control={control}
            path={path}
            key={1}
            name={'extColor'}
            setValue={setValue}
            errName={errors['extColor']}
          />
          <ColorPicker
            control={control}
            path={path}
            key={2}
            name={'intColor'}
            setValue={setValue}
            errName={errors['intColor']}
          />
        </React.Fragment>
      ) : (
        <ColorPicker control={control} path={path} errors={errors} />
      )}
      <Questions control={control} setValue={setValue} />
    </div>
  );
};

export default Section2;
