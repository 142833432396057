import React, {StrictMode, Suspense} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import store from './redux/store';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import './i18n';
import Loader from './components/Common/Loader/Loader';

ReactDOM.render(
    <StrictMode>
        <Provider store={store}>
            <Suspense fallback={<Loader isVisible={true}/>}>
                <App/>
            </Suspense>
        </Provider>
    </StrictMode>,
    document.getElementById('root')
);

reportWebVitals();
