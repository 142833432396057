import React from 'react';
import style from './style.module.scss';
import Button from '../../../components/Common/Buttons/Button';
import { useTranslation } from 'react-i18next';

const MainBlock = ({ scrollRef }) => {
  const { t } = useTranslation();
  const slideDown = () => {
    scrollRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <div className={style.mainContainer}>
      <div className={style.txtContainer}>
        <div>
          <h2>The Best Way to Sell Your Car.</h2>
          <h1>Sell Your Car</h1>
        </div>
        <div>
          <p>
            {/* Lorem ipsum dolor sit amet, consectetur adipisicing elit. Natus ipsa pariatur itaque officia cumque placeat
            architecto distinctio quam voluptate temporibus. Lorem ipsum dolor sit amet consectetur, adipisicing elit.
            Reiciendis quibusdam sed enim quo architecto accusamus soluta molestias eos eligendi maiores placeat maxime
            quisquam tempore, ea praesentium nostrum minus. Assumenda, nostrum! */}
          </p>
        </div>
        <Button innerText={t('add_announcement')} handleClick={slideDown} />
      </div>
      <div className={style.recsContainer}>
        <div className={style.rec1}></div>
        <div className={style.rec2}></div>
        <div className={style.rec3}></div>
      </div>
    </div>
  );
};

export default MainBlock;
