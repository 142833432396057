import { findCarsByPage, findUserPublicCars } from "../../api/requests";

import { buildHttpQuery } from "../../general-functions";

const initialState = { owner: { userDetails: {} }, cars: [], totalItems: 9 };

const carsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CARS": {
      return {
        ...state,
        cars: [
          ...(action?.findCarsResult?.data?.rows || action?.data?.rows || []),
        ],
        totalItems:
          action?.findCarsResult?.data?.totalItems || action?.data?.totalItems,
      };
    }
    case "SET_USER_PUBLIC_CARS": {
      return {
        ...state,
        cars: [...(action?.data?.rows || [])],
        owner: action.data.owner,
      };
    }
    default:
      return state;
  }
};

//action

export const setFoundCarsByPage = (data, pushStatus, setIsVisible, page, history) => {
  
  return (dispatch, getState) => {
    setIsVisible && setIsVisible(true);
    
    dispatch({ type: "SAVE_SEARCH_DATA", data });
    const _data = buildHttpQuery(data);

    findCarsByPage(_data, page).then((res) => {
      if (res && res.status === 200 && res.data) {
        const data = res.data.data;
        
        dispatch({ type: "SET_CARS", data });
        dispatch({ type: "SET_CURRENT_PAGE", page });

        const state = getState();

        const cleanedStateSearch = state.search.filter(( element ) => {
          if (Array.isArray(element.defaultValue) && element.defaultValue.length === 0) return;
          return element?.defaultValue;
        })

        const url = cleanedStateSearch
          .map((obj) => {
            return `${obj.name}=${obj.defaultValue}`
          }).join('&');

          pushStatus && history?.push(`/cars?${url}`);
      }
      setIsVisible && setIsVisible(false);
    });
  };
};

export const setUserPublicCars = (userId) => {
  return (dispatch) => {
    findUserPublicCars(userId).then((res) => {
      const data = res.data;
      if (res.status === 200) {
        if (data) {
          dispatch({ type: "SET_USER_PUBLIC_CARS", data });
        }
      }
    });
  };
};
export default carsReducer;
