import React, { useMemo, useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import style from '../style.module.scss';
import CarouselContainer from './CarouselContainer';

const SliderModal = ({ isVisible, setIsVisible, images, startingNumberProp, setStartingNumber }) => {
  const [index, setIndex] = useState(null);
  ////console.log(startingNumberProp);

  useMemo(() => setIndex(startingNumberProp), [startingNumberProp]);

  const handleCancel = () => {
    setIsVisible(false);
    setIndex(0);
    setStartingNumber(0);
  };

  return (
    <Modal
      title='Search Modal'
      centered
      footer={null}
      open={isVisible}
      width={'unset'}
      wrapClassName={style.sliderModal}
      onCancel={handleCancel}
    >
      <CarouselContainer
        images={images}
        setIsVisible={setIsVisible}
        startingNumberProp={startingNumberProp}
        index={index}
        setIndex={setIndex}
      />
    </Modal>
  );
};

export default SliderModal;
