import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
// import {setMainLanguage} from '../../redux/Reducers/userReducer';
import style from './style.module.scss';

const Privacy = () => {
  const dispatch = useDispatch();
  const {i18n} = useTranslation();
  const lang = i18n.language;
  useEffect(() => {
    // const lang = JSON.parse(localStorage.getItem('lang'));
  }, [dispatch, i18n]);

  return (
    <div className={style.pageContainer}>
      {['am', 'en'].includes(lang) && (
        <div>
          <h1>Privacy Policy for Autobuy LLC:</h1>
          <div className={style.textContainer}>
            <p>Autobuy LLC ("Autobuy") respects your privacy. This Privacy Policy sets forth how we handle the
              information
              we obtain from you and learn about you when you visit our website and use our application. This Privacy
              Policy
              contains information about:</p>

            <ul>
              <li>The information that we collect and how it will be used;</li>
              <li>Your right to access or update your Personally Identifiable Information (“PII”);</li>
              <li>Your right to opt-out of receiving communications from us;</li>
              <li>Our pledge to have reasonable security measures in place to protect against the loss, misuse, or
                alteration of the information under our control;
              </li>
              <li>How your information is collected through our website;</li>
              <li>How your information is collected through our application;</li>
              <li>How we handle links to other websites;</li>
              <li>How we notify you of changes to this Privacy Policy; and</li>
              <li>How you can contact us with questions or concerns about your information.</li>
            </ul>

            <h2>Collection of Information through the Website</h2>
            <p>While the word “privacy” may mean different things to different people, we believe that once you
              understand
              how your information will be used, the steps we take to protect your PII, and the resulting benefits to
              you,
              you will feel confident when sharing information with us. One key is that we do not rent, sell, or share
              your
              PII to anyone without your consent. Additionally, you will not be contacted by any third-party without
              your
              consent.</p>

            <h2>Personally Identifiable Information (PII)</h2>
            <p>Autobuy LLC does not collect PII without your knowledge. PII is collected on the Autobuy website through
              prompt only. The only personally identifying information that we collect and store about you is the
              information that you choose to provide to us. Several areas of the website ask for personal information.
              Requested information includes your name, address, email address, billing information, business profile,
              shipment information (shipping and receiving addresses), and information about your business contacts
              (company
              name, addresses, and contact name). This information is collected to help us further develop our services,
              to
              provide you access to valuable Autobuy information and services. Any information you give to us will not
              be
              used in ways that you have not consented to by accepting the terms of this Privacy Policy.</p>

            <h2>Requests for Information Forms</h2>
            <p>Our requests for information forms require that you provide us with PII. This information is used to
              contact
              you and to process your requests for information. We will also use this information to contact you about
              the
              services we offer, as well as to send you site and service announcement updates.</p>

            <h2>Electronic Mail</h2>
            <p>We will also gather PII when you send us an e-mail. We will use your PII to respond to your e-mail, and
              we
              may use your PII to send you notifications about our services.</p>

            <h2>When you register for an Autobuy Account</h2>
            <p>When you register for an Autobuy account, we collect Personally Identifiable Information that is
              necessary
              to
              provide Products and Services. You must provide your name and e-mail address. You also must choose a
              password,
              which will be required to gain access to portions of our site reserved for registered customer use. We
              might
              also request your ZIP code. You may elect to provide other information, including credit card information
              to
              be used to pay invoices through our secure website, which you can store on our website for ease of
              use.</p>

            <h2>Non-Personally Identifiable Information</h2>
            <p>Autobuy collects anonymous browser information from visitors to our website. This information is not
              personally identifiable and is not linked to any individual person. To collect this information, we may
              store
              information in the form of a “cookie” on your computer. Cookies allow us to tailor our website, and they
              help
              us better respond to your interests and preferences. With most Internet Browsers, you can erase cookies
              from
              your computer hard drive, block all cookies, or receive a warning before a cookie is stored. Please refer
              to
              your Browser instructions or Help screen to learn more about these functions.</p>

            <h2>Referrers, IP Addresses, and Other Non-Personal Information</h2>
            <p>Autobuy collects information through “referrers,” IP addresses, and various environmental variables. A
              “referrer” is information the web browser passes along to the Autobuy web server that references the URL
              from
              which you came. The “IP address” is a number used by computers on the network to identify your computer so
              that data can be transmitted to you. “Environmental variables” include, among other things, the domain
              from
              which you access the Internet, the time you accessed our website, the type of web browser and operating
              system
              or platform used, the Internet address of the website you left to visit Autobuy, the names of the pages
              you
              visit while at our website, and the Internet address of the website you then visit. We collect all of this
              information to allow us to detect broad demographic trends, to provide information tailored to your
              interests,
              and to enhance your experience on the Autobuy website.</p>

            <h2>Profiling</h2>
            <p>To help us improve our website and to ensure that we make every effort to provide you with information
              and
              offers that are of interest to you, we may merge your information with click-stream data and other data we
              have, including offline data. We will provide notice of this, as well as an opportunity to opt-out of it,
              at
              the time and place of information collection and before data is entered into our system. We may also
              develop
              a
              non-identifiable profile on you based upon the pages within our website that you visit. In addition, we
              may
              allow third-party advertisements to appear on our website. If we do so, our advertisers and our network
              advertising companies may, through the use of cookies or other technology, collect non-personally
              identifiable
              information about you. Over time, they may be able to develop a profile on you based on your visits not
              only
              to our website but to other sites as well.</p>

            <h2>Choices Regarding the Use of Your Information</h2>
            <p>You may choose not to provide Autobuy with any contact information/PII. However, if that occurs, we will
              be
              unable to process your request for information, and you will not be able to access many parts of the
              Autobuy
              website and application. If you would like to unsubscribe to any of our services or would like us to
              remove
              you from any of our online mailing lists, send an e-mail to support@autobuy.am. Please include your name
              and
              the e-mail address you wish to unsubscribe in the body of the message. In some cases, Autobuy may also
              provide
              you with an opt-out of some services in e-mail messages that you receive from Autobuy.</p>

            <h2>Information Sharing: Blogs and Social Networks</h2>
            <p>Some Autobuy websites and the application allow you to participate in “blog” discussions and other forms
              of
              social networking. Please be aware that these blogs and social networks are accessible to other users. We
              urge
              you to not submit any personally identifiable information to these sites because any information posted on
              a
              blog can be read, collected, shared, or otherwise used by anyone who accesses the site. Autobuy is not
              responsible for the information you choose to submit in these forums.</p>

            <h2>Accessing and Updating Your Information</h2>
            <p>We strive to keep our customer records as accurate as possible. Please let us know if you see any
              inaccuracy
              in your information or if your information changes. You may request such changes by sending an e-mail to
              support@autobuy.am or by contacting your assigned Autobuy representative.</p>

            <h2>Assent</h2>
            <p>Your continued use of this site and application signifies your assent to the Autobuy Privacy Policy as
              presented. We reserve the right, at our discretion, to modify, add, or remove portions of this policy at
              any
              time by posting the revised policy online. Please check this page periodically for changes.</p>

            <h2>Resale or Disclosure of Information to Third-Parties</h2>
            <p>We do not rent, sell, or share your PII to anyone without your consent. When we do share information with
              our
              associate members, including advertisers, vendors, and publishers, it is in the aggregate form
              (non-personally
              identifiable information that is provided to third-parties in a group) and does not identify you
              personally.
              We do not collect or use any information about you except for information that you have provided to us or
              is
              publicly available.</p>

            <h2>Security</h2>
            <p>The information you enter is stored on our servers in a password-protected, personal account. All
              information
              is stored behind a secure computer firewall, a barrier that prevents outsiders from accessing our servers.
              To
              verify that you have a secure connection, look for the locked padlock symbol at the bottom of your browser
              window or the HTMLS-code in the site connection-identifier. When you access your account information, the
              information is kept on a secure server.</p>

            <p>While we implement the above security measures, you should be aware that 100% security is not possible.
              If
              you have any questions about the security of Autobuy's website and application, please send an e-mail to
              support@autobuy.am. Although our data is stored in Armenia, it is possible that some of the data
              processing
              may occur outside of Armenia. While the data protection laws of these countries may vary, we protect this
              information based upon the express terms of this Privacy Policy.</p>

            <h2>Notice to/Regarding Children under the Age of 13</h2>
            <p>Autobuy LLC does not target children under the age of 13, and we do not knowingly request PII from any
              child
              under the age of 13 without parental consent. When we do receive such information, we delete it as soon as
              we
              discover it and do not use it or share it with third-parties.</p>
          </div>
        </div>
      )}
      {['ru'].includes(lang) && (
        <div>
          <h1>Политика конфиденциальности Autobuy LLC:</h1>
          <div className={style.textContainer}>
            <p>Компания Autobuy LLC («Autobuy») уважает вашу конфиденциальность. Настоящая Политика конфиденциальности
              определяет порядок обработки информации, которую мы получаем от вас и узнаем о вас при посещении нашего
              сайта и использовании нашего приложения. В данной Политике конфиденциальности содержится информация о:</p>

            <ul>
              <li>Информации, которую мы собираем и как она будет использоваться;</li>
              <li>Вашем праве доступа или обновления вашей личной идентифицируемой информации ("ЛИИ");</li>
              <li>Вашем праве отказаться от получения коммуникаций от нас;</li>
              <li>Нашем обязательстве обеспечить разумные меры безопасности для защиты от потери, неправомерного
                использования или изменения информации, находящейся под нашим контролем;
              </li>
              <li>Способе сбора информации через наш сайт;</li>
              <li>Способе сбора информации через наше приложение;</li>
              <li>Обработке ссылок на другие веб-сайты;</li>
              <li>Способе уведомления вас об изменениях в настоящей Политике конфиденциальности; и</li>
              <li>Способе связи с нами для задач вопросов или выражения волнений относительно вашей информации.</li>
            </ul>

            <h2>Сбор информации через веб-сайт</h2>
            <p>Пока слово "конфиденциальность" может иметь разное значение для разных людей, мы считаем, что после того,
              как вы поймете, как ваша информация будет использоваться, меры, которые мы предпринимаем для защиты вашей
              ЛИИ и выгоды, которые вы получите, вы будете уверены, делиться информацией с нами. Одно из ключевых
              положений состоит в том, что мы не сдаем, не продаем и не передаем вашу ЛИИ кому-либо без вашего согласия.
              Кроме того, вы не будете контактированы третьими сторонами без вашего согласия.</p>

            <h2>Лично идентифицируемая информация (ЛИИ)</h2>
            <p>Компания Autobuy LLC не собирает ЛИИ без вашего согласия. ЛИИ собирается на сайте Autobuy только по
              запросу. Единственная идентифицирующая личность информация, которую мы собираем и храним о вас, - это
              информация, которую вы согласны предоставить нам. Несколько разделов веб-сайта запрашивают персональную
              информацию. Запрашиваемая информация включает ваше имя, адрес, адрес электронной почты, платежные данные,
              профиль вашего бизнеса, информацию о грузоперевозке (адреса отправки и получения) и информацию о ваших
              контактах в бизнесе (название компании, адреса и имя контактного лица). Эта информация собирается для
              разработки наших услуг, предоставления вам доступа к ценной информации и услугам Autobuy. Любая
              информация, которую вы предоставите нам, не будет использоваться вне пределов вашего согласия на основании
              условий настоящей Политики конфиденциальности.</p>

            <h2>Формы запросов информации</h2>
            <p>Наши формы запросов информации требуют предоставления ЛИИ. Эта информация используется для связи с вами и
              обработки ваших запросов на получение информации. Мы также будем использовать эту информацию, чтобы
              связаться с вами относительно предлагаемых нами услуг, а также для отправки вам обновлений сайта и
              уведомлений об услугах.</p>

            <h2>Электронная почта</h2>
            <p>Мы также собираем ЛИИ, когда вы отправляете нам электронное письмо. Мы будем использовать вашу ЛИИ для
              ответа на ваше электронное письмо, и мы можем использовать вашу ЛИИ для отправки вам уведомлений о наших
              услугах.</p>

            <h2>При регистрации учетной записи Autobuy</h2>
            <p>При регистрации учетной записи Autobuy мы собираем лично идентифицируемую информацию, необходимую для
              предоставления продуктов и услуг. Вы должны предоставить свое имя и адрес электронной почты. Вы также
              должны выбрать пароль, который будет требоваться для доступа к частям нашего сайта, зарезервированным для
              зарегистрированного использования клиентов. Мы также можем запросить ваш почтовый индекс. Вы можете
              предоставить другую информацию, включая информацию о кредитной карте для оплаты счетов через наш
              безопасный сайт, которую вы можете сохранить на нашем сайте для удобства использования.</p>

            <h2>Неличностно идентифицируемая информация</h2>
            <p>Autobuy собирает анонимную информацию о браузере от посетителей нашего сайта. Эта информация не является
              лично идентифицируемой и не связана с отдельным лицом. Чтобы собрать эту информацию, мы можем сохранить
              информацию в виде «cookie» на вашем компьютере. Куки позволяют нам настраивать наш веб-сайт и помогают нам
              лучше реагировать на ваши интересы и предпочтения. С помощью большинства веб-браузеров вы можете стирать
              куки с вашего компьютера, блокировать все куки или получать предупреждение перед сохранением куки.
              Пожалуйста, обратитесь к инструкциям вашего браузера или экрану справки, чтобы узнать больше о этих
              функциях.</p>

            <h2>Референты, IP-адреса и другая неличностная информация</h2>
            <p>Autobuy собирает информацию через «референты», IP-адреса и различные средовые переменные. «Референт» -
              это информация, которую веб-браузер передает серверу Autobuy и которая отсылает к URL-адресу, с которого
              вы пришли. "IP-адрес" - это номер, используемый компьютерами в сети для идентификации вашего компьютера,
              чтобы данные можно было передавать вам. «Средовые переменные» включают, помимо прочего, домен, с которого
              вы получаете доступ к Интернету, время доступа к нашему веб-сайту, тип веб-браузера и операционную систему
              или платформу, используемую, Интернет-адрес веб-сайта, с которого вы перешли на сайт Autobuy, названия
              страниц, которые вы посещаете на нашем веб-сайте, и Интернет-адрес веб-сайта, который вы затем посещаете.
              Мы собираем всю эту информацию, чтобы определить общие демографические тенденции, предоставить информацию,
              соответствующую вашим интересам, и улучшить ваш опыт работы на веб-сайте Autobuy.</p>

            <h2>Профилирование</h2>
            <p>Для улучшения нашего веб-сайта и обеспечения наилучшего предоставления информации и предложений, которые
              будут вас интересовать, мы можем объединить вашу информацию с данными о кликах и другими данными, которые
              у нас есть, включая офлайн-данные. Мы предоставим уведомление об этом, а также возможность отказаться от
              этого, в момент и месте сбора информации и перед вводом данных в нашу систему. Мы также можем разработать
              неидентифицируемый профиль на основе страниц нашего веб-сайта, которые вы посещаете. Кроме того, мы можем
              разрешить размещение рекламы сторонних лиц на нашем веб-сайте. Если это произойдет, наши рекламодатели и
              наши компании сетевой рекламы могут собирать неличностную информацию о вас с использованием файлов cookie
              или других технологий. Со временем они могут создать профиль на вас на основе посещений не только нашего
              веб-сайта, но и других сайтов.</p>

            <h2>Варианты использования вашей информации</h2>
            <p>Вы можете выбрать не предоставлять Autobuy никакую контактную информацию или ЛИИ. Однако, если это
              произойдет, мы будем не в состоянии обработать ваш запрос на получение информации, и вы не сможете
              получить доступ к многим разделам веб-сайта и приложения Autobuy. Если вы хотите отказаться от наших услуг
              или хотите, чтобы мы удалили вас из наших онлайн списков рассылок, отправьте электронное письмо на
              support@autobuy.am. Пожалуйста, укажите ваше имя и адрес электронной почты, с которого вы хотите
              отказаться от рассылки, в тексте сообщения. В некоторых случаях Autobuy также может предоставить вам
              возможность отказаться от некоторых услуг в письмах электронной почты, которые вы получаете от
              Autobuy.</p>

            <h2>Обмен информацией: блоги и социальные сети</h2>
            <p>Некоторые веб-сайты Autobuy позволяют вам участвовать в дискуссиях "блога" и других формах социальных
              сетей. Пожалуйста, обратите внимание, что эти блоги и социальные сети доступны другим пользователям. Мы
              настоятельно рекомендуем вам не предоставлять в этих местах никакую лично идентифицируемую информацию, так
              как любая информация, размещенная на блоге, может быть прочитана, собрана, распространена или иным образом
              использована любым, кто получает доступ к сайту. Autobuy не несет ответственности за информацию, которую
              вы выберете разместить в этих форумах.</p>

            <h2>Доступ и обновление вашей информации</h2>
            <p>Мы стремимся сохранять актуальность наших данных о клиентах. Пожалуйста, сообщите нам, если вы обнаружите
              неточность в вашей информации или если ваша информация изменится. Вы можете запросить такие изменения,
              позвонив представителю Autobuy по телефону 1-866-564-7587 или отправив электронное письмо на
              support@autobuy.am.</p>

            <h2>Согласие</h2>
            <p>Ваше дальнейшее использование этого сайта и приложения свидетельствует о вашем согласии с Политикой
              конфиденциальности Autobuy, как она изложена. Мы оставляем за собой право по своему усмотрению изменять,
              добавлять или удалять части настоящей политики в любое время, размещая пересмотренную политику в
              Интернете. Пожалуйста, периодически проверяйте эту страницу на наличие изменений.</p>

            <h2>Перепродажа или раскрытие информации третьим сторонам</h2>
            <p>Мы не арендуем, не продаем и не передаем вашу ЛИИ кому-либо без вашего согласия. Когда мы предоставляем
              информацию нашим ассоциированным членам, включая рекламодателей, поставщиков и издателей, это делается в
              агрегированной форме (неличностная информация, предоставленная третьим сторонам в группе) и не
              идентифицирует вас лично. Мы не собираем и не используем никакую информацию о вас, кроме информации,
              которую вы предоставили нам или которая доступна публично.</p>

            <p>Возможно, время от времени мы будем отправлять собранную информацию третьим сторонам для организации,
              обработки и/или анализа. Мы используем эту информацию исключительно для улучшения предложений нашего
              веб-сайта, в основном для индивидуальной настройки обновлений и рекламы под ваши интересы. Все такие
              сторонние стороны обязаны по контракту соблюдать Политику конфиденциальности Autobuy. Кроме того, мы
              оставляем за собой право раскрыть ЛИИ: (i) как это может быть разрешено или требуется законом или на
              основании нашего добросовестного убеждения в том, что это необходимо для соблюдения закона или по
              распоряжению суда или уполномоченного административного органа; или (ii) как это может быть необходимо для
              защиты наших прав или имущества.</p>

            <h2>Безопасность</h2>
            <p>Информация, которую вы вводите, хранится на наших серверах в защищенной паролем личной учетной записи.
              Вся информация хранится за защищенным компьютерным брандмауэром, который предотвращает доступ сторонних
              лиц к нашим серверам. Чтобы убедиться в том, что у вас есть безопасное соединение, ищите замок с закрытым
              замком внизу окна вашего браузера или код HTMLS в идентификаторе соединения сайта. Когда вы получаете
              доступ к информации своей учетной записи, информация хранится на защищенном сервере.</p>

            <p>Хотя мы применяем вышеуказанные меры безопасности, стоит знать, что 100% безопасность невозможна. Если у
              вас есть вопросы о безопасности веб-сайта Autobuy, пожалуйста, отправьте электронное письмо на
              support@autobuy.am. Хотя наши данные хранятся в Соединенных Штатах, возможно, некоторая обработка данных
              может осуществляться за пределами Соединенных Штатов. В то время как законы о защите данных этих стран
              могут различаться, мы защищаем эту информацию на основе прямых условий настоящей Политики
              конфиденциальности.</p>

            <h2>Уведомление о/относительно детей до 13 лет</h2>
            <p>Autobuy не направляет свои услуги детям до 13 лет и не запрашивает ЛИИ от детей до 13 лет без согласия
              родителей. Когда мы получаем такую информацию, мы удаляем ее, как только обнаруживаем, и не используем и
              не передаем ее третьим сторонам.</p>

            <p>Если у вас есть вопросы или предложения относительно нашей Политики конфиденциальности, пожалуйста,
              свяжитесь с нами по адресу support@autobuy.am.</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Privacy;
