import React from 'react';
import style from '../style.module.scss';
import BodyTypesContainer from '../BigForm/BodyTypes/BodyTypesContainer';
import Section1 from './Section1/Section1';
import Section2 from './Section2/Section2';

const SellForm = ({ control, setValue, errors }) => {
  return (
    <div>
      <BodyTypesContainer //path={path}
        control={control}
        setValue={setValue}
        errors={errors}
      />
      <div className={style.bigWrapper}>
        <Section1 control={control} errors={errors} setValue={setValue} />
        <hr style={{ height: 'auto', margin: '0 20px' }} />
        <Section2 control={control} errors={errors} setValue={setValue} />
      </div>
    </div>
  );
};

export default SellForm;
