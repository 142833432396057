import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

const Debt = () => {
  const dealerCarDetails = useSelector((state) => state.dealerCars.dealerCarDetails, shallowEqual);
  let { price, transportation, insurance, storage, dealer_fee, late_fee, document_fee, custom_fee, sub_lot } = dealerCarDetails;
  let { expenses } = dealerCarDetails;
  expenses.car_price = parseInt(expenses.car_price || 0);
  expenses.transport_fee = parseInt(expenses.transport_fee || 0);
  expenses.insurance_fee = parseInt(expenses.insurance_fee || 0);
  expenses.storage_fee = parseInt(expenses.storage_fee || 0);
  expenses.dealer_fee = parseInt(expenses.dealer_fee || 0);
  expenses.late_fee = parseInt(expenses.late_fee || 0);
  expenses.document_fee = parseInt(expenses.document_fee || 0);
  expenses.custom_fee = parseInt(expenses.custom_fee || 0);
  expenses.sub_lot = parseInt(expenses.sub_lot || 0);
  price = parseInt(price || 0);
  transportation = parseInt(transportation || 0);
  insurance = parseInt(insurance || 0);
  storage = parseInt(storage || 0);
  dealer_fee = parseInt(dealer_fee || 0);
  late_fee = parseInt(late_fee || 0);
  document_fee = parseInt(document_fee || 0);
  custom_fee = parseInt(custom_fee || 0);
  sub_lot = parseInt(sub_lot || 0);
  return (
    <div>
      <h1>debt</h1>
      <div>
        {/* <p>{t('placeholder_price')}</p> */}
        <h1>{`$${price - expenses.car_price}`}</h1>
      </div>
      <div>
        {/* <p>{t('t_price')}</p> */}
        <h1>{`$${transportation - expenses.transport_fee}`}</h1>
      </div>
      <div>
        {/* <p>{t('Insurance')}</p> */}
        <h1>{`$${insurance - expenses.insurance_fee}`}</h1>
      </div>
      <div>
        {/* <p>{t('Storage')}</p> */}
        <h1>{`$${storage - expenses.storage_fee}`}</h1>
      </div>
      <div>
        {/* <p>{t('Dealer fee')}</p> */}
        <h1>{`$${dealer_fee - expenses.dealer_fee}`}</h1>
      </div>
      <div>
        {/* <p>{t('Late fee')}</p> */}
        <h1>{`$${late_fee - expenses.late_fee}`}</h1>
      </div>
      <div>
        {/* <p>{t('Document fee')}</p> */}
        <h1>{`$${document_fee - expenses.document_fee}`}</h1>
      </div>
      <div>
        {/* <p>{t('Custom fee')}</p> */}
        <h1>{`$${custom_fee - expenses.custom_fee}`}</h1>
      </div>
      <div>
        {/* <p>{t('Custom fee')}</p> */}
        <h1>{`$${sub_lot - expenses.sub_lot}`}</h1>
      </div>
      <div>
        {/* <p>{t('total')}</p> */}
        <h1>{`$${
          price -
          expenses.car_price +
          (transportation - expenses.transport_fee) +
          (insurance - expenses.insurance_fee) +
          (storage - expenses.storage_fee) +
          (dealer_fee - expenses.dealer_fee) +
          (late_fee - expenses.late_fee) +
          (document_fee - expenses.document_fee) +
          (custom_fee - expenses.custom_fee) +
          (sub_lot - expenses.sub_lot)
        }`}</h1>
      </div>
    </div>
  );
};

export default Debt;
