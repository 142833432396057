import { Tabs } from 'antd';
import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import AnnouncementCard from '../../../components/Common/Cards/AnnouncementCard';
import CarCard from '../../../components/Common/Cards/CarCard';
import SettingsIcon from '../../../components/Common/Icons/settings';
import { setActiveTab } from '../../../redux/Reducers/userReducer';
import style from '../style.module.scss';
import SettingsTab from './SettingsTab/SettingsTab';
import { useTranslation } from 'react-i18next';
const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:4000';

const UserTabs = () => {
  const user = useSelector((state) => state.user, shallowEqual);
  const { activeTab } = user;
  const dispatch = useDispatch();
  const { TabPane } = Tabs;
  const { t } = useTranslation();

  return (
    <Tabs defaultActiveKey={activeTab} activeKey={activeTab} onTabClick={(key) => dispatch(setActiveTab(key))}>
      <TabPane tab={t('anns')} key='1'>
        <div className={style.userAnnouncements}>
          {/* take from user redux state */}
          {user.cars.map((car, i) => {
            const firstMedia =
              BASE_URL + car.carMedia[0].carImage.location + '/' + car.carMedia[0].carImage.name + 'small.jpeg';
            return (
              <div key={i}>
                {/* {car.shopStatus === 0 ? 'Under Moderation' : 'In market'} */}
                <CarCard
                  inAnnouncements={true}
                  make={car.carMake}
                  model={car.carModel}
                  image={firstMedia}
                  price={car.price}
                  year={car.year}
                  id={car.id}
                  shopStatus={car.shopStatus}
                  currency={car.currency}
                />
              </div>
            );
          })}
          <AnnouncementCard />
        </div>
      </TabPane>

      <TabPane tab={t('favs')} key='2'>
        <div className={style.userFavourites}>
          {/* take from user redux state */}
          {user.favourites.reverse().map((car, i) => {
            ////console.log(car);
            const firstMedia =
              BASE_URL + car.carMedia[0].carImage.location + '/' + car.carMedia[0].carImage.name + 'small.jpeg';
            return (
              <div key={i}>
                <CarCard
                  inAnnouncements={false}
                  inFavourites={true}
                  make={car.carMake}
                  model={car.carModel}
                  image={firstMedia}
                  price={car.price}
                  year={car.year}
                  id={car.id}
                  shopStatus={car.shopStatus}
                  currency={car.currency}
                />
              </div>
            );
          })}
        </div>
      </TabPane>
      <TabPane tab={<SettingsIcon />} key='3'>
        <SettingsTab />
      </TabPane>
    </Tabs>
  );
};

export default UserTabs;
