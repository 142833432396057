import React, { useState, useEffect } from 'react';
import style from './style.module.scss';
import logo from '../../assets/Images/biglogo.png';
/*import UpIcon from '../../components/Common/Icons/arrowUp';*/
import SigninForm from '../../components/Common/Forms/SigninForm/SigninForm';
import { useForm } from 'react-hook-form';
import { useLocation, useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Alert, Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import {loginOTL} from "../../api/requests";
import {authUser, redirectUserSettings} from "../../redux/Reducers/userReducer";
import {useDispatch} from "react-redux";

const LoginSignup = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  let path = location.pathname.substring(1);
  const { handleSubmit, control } = useForm();
  const { t/*, i18n*/ } = useTranslation();
  ////console.log(path, i18n);
  const [alertState, setAlertState] = useState({ visible: false, description: '', type: '', status: 0 }); //alert state
  let otl = null;
  if(props && props.match) {
    otl = props.match.params.otl;
  }
  useEffect(() => {
    if (otl) {
      setAlertState({visible: true, description: t(`please_wait`), type: 'success'});
      loginOTL(otl).then((res) => {
        if (res && res.data) {
          if (res.status === 200) {
            setAlertState({visible: true, description: t(`login`), type: 'success'});
            dispatch(authUser(res.data));
            dispatch(redirectUserSettings(res.data.userType));

          } else {
            setAlertState({visible: true, description: t(`${res.data.error.message}`)});
          }
        }
      });
    }
  }, [ dispatch, history, otl, path, t]);

  return (
    <div className={style.pageContainer}>
      <div className={style.recsContainer}>
        <div className={style.rec1}></div>
        <div className={style.rec2}></div>
        <div className={style.rec3}></div>
        <div>
          <Link to='/'>
            <img src={logo} alt='' />
          </Link>
          <p>{t('welcome')}</p>
          <h1>{t('access_best_service')}</h1>
        </div>
      </div>
      <div className={style.formContainer}>
        <div className={style.formStyle}>
          {alertState.visible && (
            <Popover
              open={alertState.visible}
              overlayClassName={style.popover}
              content={
                <Alert
                  showIcon
                  closable
                  type={alertState.type}
                  description={alertState.description}
                  className={style.alert}
                  onClose={() => setAlertState({ visible: false, description: '' })}
                />
              }
            ></Popover>
          )}
          <div className={style.titles}>
            <Link to='login' onClick={() => setAlertState({ visible: false, description: '' })}>
              <h1>{t('signin')}</h1>
            </Link>
          </div>
            <SigninForm
                handleSubmit={handleSubmit}
                control={control}
                alertState={alertState}
                setAlertState={setAlertState}
            />
        </div>
      </div>
    </div>
  );
};

export default LoginSignup;
